@font-face {
	font-family: 'elegant';
	src:url('../fonts/elegant.eot?yvo2k6');
	src:url('../fonts/elegant.eot#iefixyvo2k6') format('embedded-opentype'),
		url('../fonts/elegant.ttf?yvo2k6') format('truetype'),
		url('../fonts/elegant.woff?yvo2k6') format('woff'),
		url('../fonts/elegant.svg?yvo2k6#elegant') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="el-"], [class*=" el-"] {
	font-family: 'elegant';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.el-arrow_up:before {content: "\21";}
.el-arrow_down:before {content: "\22";}
.el-arrow_left:before {content: "\23";}
.el-arrow_right:before {content: "\24";}
.el-arrow_left-up:before {content: "\25";}
.el-arrow_right-up:before {content: "\26";}
.el-arrow_right-down:before {content: "\27";}
.el-arrow_left-down:before {content: "\28";}
.el-arrow-up-down:before {content: "\29";}
.el-arrow_up-down_alt:before {content: "\2a";}
.el-arrow_left-right_alt:before {content: "\2b";}
.el-arrow_left-right:before {content: "\2c";}
.el-arrow_expand_alt2:before {content: "\2d";}
.el-arrow_expand_alt:before {content: "\2e";}
.el-arrow_condense:before {content: "\2f";}
.el-arrow_expand:before {content: "\30";}
.el-arrow_move:before {content: "\31";}
.el-arrow_carrot-up:before {content: "\32";}
.el-arrow_carrot-down:before {content: "\33";}
.el-arrow_carrot-left:before {content: "\34";}
.el-arrow_carrot-right:before {content: "\35";}
.el-arrow_carrot-2up:before {content: "\36";}
.el-arrow_carrot-2down:before {content: "\37";}
.el-arrow_carrot-2left:before {content: "\38";}
.el-arrow_carrot-2right:before {content: "\39";}
.el-arrow_carrot-up_alt2:before {content: "\3a";}
.el-arrow_carrot-down_alt2:before {content: "\3b";}
.el-arrow_carrot-left_alt2:before {content: "\3c";}
.el-arrow_carrot-right_alt2:before {content: "\3d";}
.el-arrow_carrot-2up_alt2:before {content: "\3e";}
.el-arrow_carrot-2down_alt2:before {content: "\3f";}
.el-arrow_carrot-2left_alt2:before {content: "\40";}
.el-arrow_carrot-2right_alt2:before {content: "\41";}
.el-arrow_triangle-up:before {content: "\42";}
.el-arrow_triangle-down:before {content: "\43";}
.el-arrow_triangle-left:before {content: "\44";}
.el-arrow_triangle-right:before {content: "\45";}
.el-arrow_triangle-up_alt2:before {content: "\46";}
.el-arrow_triangle-down_alt2:before {content: "\47";}
.el-arrow_triangle-left_alt2:before {content: "\48";}
.el-arrow_triangle-right_alt2:before {content: "\49";}
.el-arrow_back:before {content: "\4a";}
.el-el_minus-06:before {content: "\4b";}
.el-el_plus:before {content: "\4c";}
.el-el_close:before {content: "\4d";}
.el-el_check:before {content: "\4e";}
.el-el_minus_alt2:before {content: "\4f";}
.el-el_plus_alt2:before {content: "\50";}
.el-el_close_alt2:before {content: "\51";}
.el-el_check_alt2:before {content: "\52";}
.el-el_zoom-out_alt:before {content: "\53";}
.el-el_zoom-in_alt:before {content: "\54";}
.el-el_search:before {content: "\55";}
.el-el_box-empty:before {content: "\56";}
.el-el_box-selected:before {content: "\57";}
.el-el_minus-box:before {content: "\58";}
.el-el_plus-box:before {content: "\59";}
.el-el_box-checked:before {content: "\5a";}
.el-el_circle-empty:before {content: "\5b";}
.el-el_circle-slelected:before {content: "\5c";}
.el-el_stop_alt2:before {content: "\5d";}
.el-el_stop:before {content: "\5e";}
.el-el_pause_alt2:before {content: "\5f";}
.el-el_pause:before {content: "\60";}
.el-el_menu:before {content: "\61";}
.el-el_menu-square_alt2:before {content: "\62";}
.el-el_menu-circle_alt2:before {content: "\63";}
.el-el_ul:before {content: "\64";}
.el-el_ol:before {content: "\65";}
.el-el_adjust-horiz:before {content: "\66";}
.el-el_adjust-vert:before {content: "\67";}
.el-el_document_alt:before {content: "\68";}
.el-el_documents_alt:before {content: "\69";}
.el-el_pencil:before {content: "\6a";}
.el-el_pencil-edit_alt:before {content: "\6b";}
.el-el_pencil-edit:before {content: "\6c";}
.el-el_folder-alt:before {content: "\6d";}
.el-el_folder-open_alt:before {content: "\6e";}
.el-el_folder-add_alt:before {content: "\6f";}
.el-el_info_alt:before {content: "\70";}
.el-el_error-oct_alt:before {content: "\71";}
.el-el_error-circle_alt:before {content: "\72";}
.el-el_error-triangle_alt:before {content: "\73";}
.el-el_question_alt2:before {content: "\74";}
.el-el_question:before {content: "\75";}
.el-el_comment_alt:before {content: "\76";}
.el-el_chat_alt:before {content: "\77";}
.el-el_vol-mute_alt:before {content: "\78";}
.el-el_volume-low_alt:before {content: "\79";}
.el-el_volume-high_alt:before {content: "\7a";}
.el-el_quotations:before {content: "\7b";}
.el-el_quotations_alt2:before {content: "\7c";}
.el-el_clock_alt:before {content: "\7d";}
.el-el_lock_alt:before {content: "\7e";}
.el-el_lock-open_alt:before {content: "\e000";}
.el-el_key_alt:before {content: "\e001";}
.el-el_cloud_alt:before {content: "\e002";}
.el-el_cloud-upload_alt:before {content: "\e003";}
.el-el_cloud-download_alt:before {content: "\e004";}
.el-el_image:before {content: "\e005";}
.el-el_images:before {content: "\e006";}
.el-el_lightbulb_alt:before {content: "\e007";}
.el-el_gift_alt:before {content: "\e008";}
.el-el_house_alt:before {content: "\e009";}
.el-el_genius:before {content: "\e00a";}
.el-el_mobile:before {content: "\e00b";}
.el-el_tablet:before {content: "\e00c";}
.el-el_laptop:before {content: "\e00d";}
.el-el_desktop:before {content: "\e00e";}
.el-el_camera_alt:before {content: "\e00f";}
.el-el_mail_alt:before {content: "\e010";}
.el-el_cone_alt:before {content: "\e011";}
.el-el_ribbon_alt:before {content: "\e012";}
.el-el_bag_alt:before {content: "\e013";}
.el-el_creditcard:before {content: "\e014";}
.el-el_cart_alt:before {content: "\e015";}
.el-el_paperclip:before {content: "\e016";}
.el-el_tag_alt:before {content: "\e017";}
.el-el_tags_alt:before {content: "\e018";}
.el-el_trash_alt:before {content: "\e019";}
.el-el_cursor_alt:before {content: "\e01a";}
.el-el_mic_alt:before {content: "\e01b";}
.el-el_compass_alt:before {content: "\e01c";}
.el-el_pin_alt:before {content: "\e01d";}
.el-el_pushpin_alt:before {content: "\e01e";}
.el-el_map_alt:before {content: "\e01f";}
.el-el_drawer_alt:before {content: "\e020";}
.el-el_toolbox_alt:before {content: "\e021";}
.el-el_book_alt:before {content: "\e022";}
.el-el_calendar:before {content: "\e023";}
.el-el_film:before {content: "\e024";}
.el-el_table:before {content: "\e025";}
.el-el_contacts_alt:before {content: "\e026";}
.el-el_headphones:before {content: "\e027";}
.el-el_lifesaver:before {content: "\e028";}
.el-el_piechart:before {content: "\e029";}
.el-el_refresh:before {content: "\e02a";}
.el-el_link_alt:before {content: "\e02b";}
.el-el_link:before {content: "\e02c";}
.el-el_loading:before {content: "\e02d";}
.el-el_blocked:before {content: "\e02e";}
.el-el_archive_alt:before {content: "\e02f";}
.el-el_heart_alt:before {content: "\e030";}
.el-el_star_alt:before {content: "\e031";}
.el-el_star-half_alt:before {content: "\e032";}
.el-el_star:before {content: "\e033";}
.el-el_star-half:before {content: "\e034";}
.el-el_tools:before {content: "\e035";}
.el-el_tool:before {content: "\e036";}
.el-el_cog:before {content: "\e037";}
.el-el_cogs:before {content: "\e038";}
.el-arrow_up_alt:before {content: "\e039";}
.el-arrow_down_alt:before {content: "\e03a";}
.el-arrow_left_alt:before {content: "\e03b";}
.el-arrow_right_alt:before {content: "\e03c";}
.el-arrow_left-up_alt:before {content: "\e03d";}
.el-arrow_right-up_alt:before {content: "\e03e";}
.el-arrow_right-down_alt:before {content: "\e03f";}
.el-arrow_left-down_alt:before {content: "\e040";}
.el-arrow_condense_alt:before {content: "\e041";}
.el-arrow_expand_alt3:before {content: "\e042";}
.el-arrow_carrot_up_alt:before {content: "\e043";}
.el-arrow_carrot-down_alt:before {content: "\e044";}
.el-arrow_carrot-left_alt:before {content: "\e045";}
.el-arrow_carrot-right_alt:before {content: "\e046";}
.el-arrow_carrot-2up_alt:before {content: "\e047";}
.el-arrow_carrot-2dwnn_alt:before {content: "\e048";}
.el-arrow_carrot-2left_alt:before {content: "\e049";}
.el-arrow_carrot-2right_alt:before {content: "\e04a";}
.el-arrow_triangle-up_alt:before {content: "\e04b";}
.el-arrow_triangle-down_alt:before {content: "\e04c";}
.el-arrow_triangle-left_alt:before {content: "\e04d";}
.el-arrow_triangle-right_alt:before {content: "\e04e";}
.el-el_minus_alt:before {content: "\e04f";}
.el-el_plus_alt:before {content: "\e050";}
.el-el_close_alt:before {content: "\e051";}
.el-el_check_alt:before {content: "\e052";}
.el-el_zoom-out:before {content: "\e053";}
.el-el_zoom-in:before {content: "\e054";}
.el-el_stop_alt:before {content: "\e055";}
.el-el_menu-square_alt:before {content: "\e056";}
.el-el_menu-circle_alt:before {content: "\e057";}
.el-el_document:before {content: "\e058";}
.el-el_documents:before {content: "\e059";}
.el-el_pencil_alt:before {content: "\e05a";}
.el-el_folder:before {content: "\e05b";}
.el-el_folder-open:before {content: "\e05c";}
.el-el_folder-add:before {content: "\e05d";}
.el-el_folder_upload:before {content: "\e05e";}
.el-el_folder_download:before {content: "\e05f";}
.el-el_info:before {content: "\e060";}
.el-el_error-circle:before {content: "\e061";}
.el-el_error-oct:before {content: "\e062";}
.el-el_error-triangle:before {content: "\e063";}
.el-el_question_alt:before {content: "\e064";}
.el-el_comment:before {content: "\e065";}
.el-el_chat:before {content: "\e066";}
.el-el_vol-mute:before {content: "\e067";}
.el-el_volume-low:before {content: "\e068";}
.el-el_volume-high:before {content: "\e069";}
.el-el_quotations_alt:before {content: "\e06a";}
.el-el_clock:before {content: "\e06b";}
.el-el_lock:before {content: "\e06c";}
.el-el_lock-open:before {content: "\e06d";}
.el-el_key:before {content: "\e06e";}
.el-el_cloud:before {content: "\e06f";}
.el-el_cloud-upload:before {content: "\e070";}
.el-el_cloud-download:before {content: "\e071";}
.el-el_lightbulb:before {content: "\e072";}
.el-el_gift:before {content: "\e073";}
.el-el_house:before {content: "\e074";}
.el-el_camera:before {content: "\e075";}
.el-el_mail:before {content: "\e076";}
.el-el_cone:before {content: "\e077";}
.el-el_ribbon:before {content: "\e078";}
.el-el_bag:before {content: "\e079";}
.el-el_cart:before {content: "\e07a";}
.el-el_tag:before {content: "\e07b";}
.el-el_tags:before {content: "\e07c";}
.el-el_trash:before {content: "\e07d";}
.el-el_cursor:before {content: "\e07e";}
.el-el_mic:before {content: "\e07f";}
.el-el_compass:before {content: "\e080";}
.el-el_pin:before {content: "\e081";}
.el-el_pushpin:before {content: "\e082";}
.el-el_map:before {content: "\e083";}
.el-el_drawer:before {content: "\e084";}
.el-el_toolbox:before {content: "\e085";}
.el-el_book:before {content: "\e086";}
.el-el_contacts:before {content: "\e087";}
.el-el_archive:before {content: "\e088";}
.el-el_heart:before {content: "\e089";}
.el-el_profile:before {content: "\e08a";}
.el-el_group:before {content: "\e08b";}
.el-el_grid-2x2:before {content: "\e08c";}
.el-el_grid-3x3:before {content: "\e08d";}
.el-el_music:before {content: "\e08e";}
.el-el_pause_alt:before {content: "\e08f";}
.el-el_phone:before {content: "\e090";}
.el-el_upload:before {content: "\e091";}
.el-el_download:before {content: "\e092";}
.el-social_facebook:before {content: "\e093";}
.el-social_twitter:before {content: "\e094";}
.el-social_pinterest:before {content: "\e095";}
.el-social_googleplus:before {content: "\e096";}
.el-social_tumblr:before {content: "\e097";}
.el-social_tumbleupon:before {content: "\e098";}
.el-social_wordpress:before {content: "\e099";}
.el-social_instagram:before {content: "\e09a";}
.el-social_dribbble:before {content: "\e09b";}
.el-social_vimeo:before {content: "\e09c";}
.el-social_linkedin:before {content: "\e09d";}
.el-social_rss:before {content: "\e09e";}
.el-social_deviantart:before {content: "\e09f";}
.el-social_share:before {content: "\e0a0";}
.el-social_myspace:before {content: "\e0a1";}
.el-social_skype:before {content: "\e0a2";}
.el-social_youtube:before {content: "\e0a3";}
.el-social_picassa:before {content: "\e0a4";}
.el-social_googledrive:before {content: "\e0a5";}
.el-social_flickr:before {content: "\e0a6";}
.el-social_blogger:before {content: "\e0a7";}
.el-social_spotify:before {content: "\e0a8";}
.el-social_delicious:before {content: "\e0a9";}
.el-social_facebook_circle:before {content: "\e0aa";}
.el-social_twitter_circle:before {content: "\e0ab";}
.el-social_pinterest_circle:before {content: "\e0ac";}
.el-social_googleplus_circle:before {content: "\e0ad";}
.el-social_tumblr_circle:before {content: "\e0ae";}
.el-social_stumbleupon_circle:before {content: "\e0af";}
.el-social_wordpress_circle:before {content: "\e0b0";}
.el-social_instagram_circle:before {content: "\e0b1";}
.el-social_dribbble_circle:before {content: "\e0b2";}
.el-social_vimeo_circle:before {content: "\e0b3";}
.el-social_linkedin_circle:before {content: "\e0b4";}
.el-social_rss_circle:before {content: "\e0b5";}
.el-social_deviantart_circle:before {content: "\e0b6";}
.el-social_share_circle:before {content: "\e0b7";}
.el-social_myspace_circle:before {content: "\e0b8";}
.el-social_skype_circle:before {content: "\e0b9";}
.el-social_youtube_circle:before {content: "\e0ba";}
.el-social_picassa_circle:before {content: "\e0bb";}
.el-social_googledrive_alt2:before {content: "\e0bc";}
.el-social_flickr_circle:before {content: "\e0bd";}
.el-social_blogger_circle:before {content: "\e0be";}
.el-social_spotify_circle:before {content: "\e0bf";}
.el-social_delicious_circle:before {content: "\e0c0";}
.el-social_facebook_square:before {content: "\e0c1";}
.el-social_twitter_square:before {content: "\e0c2";}
.el-social_pinterest_square:before {content: "\e0c3";}
.el-social_googleplus_square:before {content: "\e0c4";}
.el-social_tumblr_square:before {content: "\e0c5";}
.el-social_stumbleupon_square:before {content: "\e0c6";}
.el-social_wordpress_square:before {content: "\e0c7";}
.el-social_instagram_square:before {content: "\e0c8";}
.el-social_dribbble_square:before {content: "\e0c9";}
.el-social_vimeo_square:before {content: "\e0ca";}
.el-social_linkedin_square:before {content: "\e0cb";}
.el-social_rss_square:before {content: "\e0cc";}
.el-social_deviantart_square:before {content: "\e0cd";}
.el-social_share_square:before {content: "\e0ce";}
.el-social_myspace_square:before {content: "\e0cf";}
.el-social_skype_square:before {content: "\e0d0";}
.el-social_youtube_square:before {content: "\e0d1";}
.el-social_picassa_square:before {content: "\e0d2";}
.el-social_googledrive_square:before {content: "\e0d3";}
.el-social_flickr_square:before {content: "\e0d4";}
.el-social_blogger_square:before {content: "\e0d5";}
.el-social_spotify_square:before {content: "\e0d6";}
.el-social_delicious_square:before {content: "\e0d7";}
.el-el_printer:before {content: "\e103";}
.el-el_calulator:before {content: "\e0ee";}
.el-el_building:before {content: "\e0ef";}
.el-el_floppy:before {content: "\e0e8";}
.el-el_drive:before {content: "\e0ea";}
.el-el_search-2:before {content: "\e101";}
.el-el_id:before {content: "\e107";}
.el-el_id-2:before {content: "\e108";}
.el-el_puzzle:before {content: "\e102";}
.el-el_like:before {content: "\e106";}
.el-el_dislike:before {content: "\e0eb";}
.el-el_mug:before {content: "\e105";}
.el-el_currency:before {content: "\e0ed";}
.el-el_wallet:before {content: "\e100";}
.el-el_pens:before {content: "\e104";}
.el-el_easel:before {content: "\e0e9";}
.el-el_flowchart:before {content: "\e109";}
.el-el_datareport:before {content: "\e0ec";}
.el-el_briefcase:before {content: "\e0fe";}
.el-el_shield:before {content: "\e0f6";}
.el-el_percent:before {content: "\e0fb";}
.el-el_globe:before {content: "\e0e2";}
.el-el_globe-2:before {content: "\e0e3";}
.el-el_target:before {content: "\e0f5";}
.el-el_hourglass:before {content: "\e0e1";}
.el-el_balance:before {content: "\e0ff";}
.el-el_rook:before {content: "\e0f8";}
.el-el_printer-alt:before {content: "\e0fa";}
.el-el_calculator_alt:before {content: "\e0e7";}
.el-el_building_alt:before {content: "\e0fd";}
.el-el_floppy_alt:before {content: "\e0e4";}
.el-el_drive_alt:before {content: "\e0e5";}
.el-el_search_alt:before {content: "\e0f7";}
.el-el_id_alt:before {content: "\e0e0";}
.el-el_id-2_alt:before {content: "\e0fc";}
.el-el_puzzle_alt:before {content: "\e0f9";}
.el-el_like_alt:before {content: "\e0dd";}
.el-el_dislike_alt:before {content: "\e0f1";}
.el-el_mug_alt:before {content: "\e0dc";}
.el-el_currency_alt:before {content: "\e0f3";}
.el-el_wallet_alt:before {content: "\e0d8";}
.el-el_pens_alt:before {content: "\e0db";}
.el-el_easel_alt:before {content: "\e0f0";}
.el-el_flowchart_alt:before {content: "\e0df";}
.el-el_datareport_alt:before {content: "\e0f2";}
.el-el_briefcase_alt:before {content: "\e0f4";}
.el-el_shield_alt:before {content: "\e0d9";}
.el-el_percent_alt:before {content: "\e0da";}
.el-el_globe_alt:before {content: "\e0de";}
.el-el_clipboard:before {content: "\e0e6";}
