@import "includes/colors";
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,400i,700');

/*
Template Name: Inspirado HTML
Version: 1.0.0
Author: ab-themes
Author URI: http://www.ab-themes.com

[Table of contents]

	1. CSS RESET
	2. Global Styles
		2.1. WordPress Core
		2.2. Inner Columns
		2.3. Clearfix
	3. Header
		3.1. Left Logo Header
		3.2. Sidebar Header
		3.3. Shop Header
		3.4. Main Menu
		3.5. Mega Menu
		3.6. Title/Breadcrumbs Bar
	4. Videos
	5. Contact Form 7
		5.1.Transparent Form
	6. Pages
		6.1. About Us Page
		6.2. 404 Page
	7. Slider Place
	8. Blog
		8.1. Blog Posts
		8.2. Pagination
		8.3. Single Post
		8.4. Related Articles
		8.5. Post Comments
	9. Widgets
		9.1. Search
		9.2. Categories
		9.3. Tag Cloud
		9.4. Flickr Stream
		9.5. Recent Posts Extended Widget
		9.6. Footer Widgets
		9.7. Widget Archive
		9.8. Widget Search Results
		9.9. Widget Recent Entries
		9.10. Sidebar Widgets
		9.11. Contact Info Widget
		9.12. Twitter Widget
		9.13. Social Media Buttons Widget
		9.14. Footer RSS Widget
		9.15. Footer Recent Comments Widget
	10. Portfolio
		10.1. Portfolio - 2 Columns
		10.2. Portfolio - 3 Columns
		10.3. Portfolio - 4 Columns
		10.4. Portfolio - List Full Width
		10.5. Portfolio Single Work
		10.6. Portfolio - List Left and Right Sidebar
	11. Other
		11.1. AB Testimonials
		11.1.1. AB Testimonials - Picture Middle
		11.1.2. AB Testimonials - Big
		11.1.3. AB Testimonials - Picture Bottom
		11.1.4. AB Testimonials - Picture Top
		11.1.5. AB Testimonials - Single Testimonial
		11.2. AB Tweet Scroller
		11.3. AB Simple Subscribe
		11.4. Real3D Flipbook
	12. Creator Elements
		12.1. Element: Animation Box
		12.2. Element: Columns & sections
		12.3. Element: Abbreviation
		12.4. jQuery UI helpers
		12.5. Element: Tabs
		12.6. Element: Accordion
		12.7. Element: Toggle
		12.8. Element: Tables
		12.9. Element: Alert boxes
		12.10. Element: Blockquote
		12.11. Element: UL
		12.12. Element: Follow us links
		12.13. Element: Stats Excerpt
		12.14. Element: Knob
		12.15. Element: Team
		12.16. Element: Posts
		12.17. Element: Priceboxes
		12.18. Element: Progress bar
		12.19. Element: Search Field
		12.20. Element: Service Boxes
		12.21. Element: Shop Service boxes
		12.22. Element: Metro Boxes
		12.23. Element: Divider
		12.24. Element: Pullquote
		12.25. Element: Tooltip
		12.26. Element: Source Code
		12.27. Element: RSS Feed
		12.28. Element: Children
		12.29. Element: Sitemap
		12.30. Element: Google Map
		12.31. Element: Dropcap
		12.32. Element: Image
		12.33. Element: Videos
		12.34. Element: Clear
		12.35. Element: Callout Boxes
		12.36. Element: Image Callout Boxes
		12.37. Element: Buttons
		12.38. Element: Countdown
		12.39. Element: Image Carousel
		12.40. Element: Image Gallery
		12.41. Element: Image Switch
		12.42. Element: Timeline
		12.43. Element: Modal
	13. Woocommerce Shop Page
	14. Footer
		14.1. Shop Footer
		14.2. Footer Menu
	15. Responsive


/*-----------------------------------------------------------------*/
/* 1. CSS RESET
/*-----------------------------------------------------------------*/

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video, a {
  margin:                      0;
  padding:                     0;
  border:                      0;
  font-size:                   100%;
  font:                        inherit;
  vertical-align:              baseline;
  outline:                     0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-box-sizing:          border-box;
  -moz-box-sizing:             border-box;
  box-sizing:                  border-box;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

html {
  min-height: 100%;
  margin:     0;
  padding:    0;
}

img {
  max-width:              100%;
  height:                 auto;
  width:                  auto;
  vertical-align:         middle;
  border:                 0;
  -ms-interpolation-mode: bicubic;
}

input {
  &[type="text"], &[type="password"], &[type="email"] {
    -webkit-appearance: none;
    -moz-appearance:    none;
    width:              100%;
    font-family:        inherit;
    -webkit-box-sizing: border-box;
    -moz-box-sizing:    border-box;
    box-sizing:         border-box;
    border:             1px solid $color-grey-d9;
    outline:            none;
  }
}

textarea {
  -webkit-appearance: none;
  -moz-appearance:    none;
  width:              100%;
  font-family:        inherit;
  -webkit-box-sizing: border-box;
  -moz-box-sizing:    border-box;
  box-sizing:         border-box;
  border:             1px solid $color-grey-d9;
  outline:            none;
}

@import "partials/2-00__global";
@import "partials/2-01__forms";
@import "partials/3__header";
@import "partials/4__videos";
@import "partials/5__contact_form";
@import "partials/6__pages";
@import "partials/7__slider_place";
//@import "partials/8__blog";
@import "partials/9__widgets";
@import "partials/10__portfolio";
@import "partials/11__other";
@import "partials/12__creator_elements";
//@import "partials/13__woocommerce";
@import "partials/14__footer";
@import "partials/15__responsive";
@import "../../../../../bower_components/magnific-popup/src/css/settings";
@import "../../../../../bower_components/magnific-popup/src/css/main";

@import "partials/16__overwrites";