@import "../includes/colors";



/*-----------------------------------------------------------------*/
/* 5. Contact Form 7
/*-----------------------------------------------------------------*/

.wpcf7 {
  input {
    padding:       8px 10px;
    margin-top:    0;
    //margin-bottom: 32px;
    width:         100%;
  }
  textarea {
    //margin-bottom: 25px;
    width:         100%;
  }
}

.wpcf7-submit {
  height:             50px !important;
  padding:            7px 24px !important;
  border:             none;
  background:         $color-ci-1 !important;
  color:              $color-white !important;
  margin:             0 !important;
  font-size:          14px;
  font-weight:        400 !important;
  text-transform:     uppercase;
  cursor:             pointer;
  transition:         background 180ms ease-in, color 180ms ease-in, border-color 180ms ease-in;
  -webkit-transition: background 180ms ease-in, color 180ms ease-in, border-color 180ms ease-in;
  &:hover {
    background:         #505050 !important;
    transition:         background 180ms, color 180ms, border-color 180ms;
    -webkit-transition: background 180ms, color 180ms, border-color 180ms;
  }
}

.wpcf7-response-output {
  margin-top: 20px;
}

.wpcf7-not-valid-tip {
  width:      auto !important;
  border:     none !important;
  color:      $color-white !important;
  padding:    4px 8px !important;
  background: #858585 !important;
  position:   relative !important;
  top:        -21px !important;
}

.wpcf7-validation-errors {
  margin-bottom: 40px !important;
  padding:       19px !important;
  background:    #ffe9e9 !important;
  color:         #d04544 !important;
  border:        none !important;
  margin:        20px 0 40px 0 !important;
}

#abdev_contact_form_submit {
  outline:               none;
  background:            #333 !important;
  font-weight:           600;
  font-size:             13px;
  height:                46px;
  margin-top:            0;
  padding:               10px 19px;
  -webkit-border-radius: 0 !important;
  -moz-border-radius:    0 !important;
  border-radius:         0 !important;
  &:hover {
    background: $color-ci-1 !important;
  }
}

div.wpcf7 .span12 {
  margin-left: 0;
}

.contact_section_style {
  padding-top:         169px !important;
  padding-bottom:      173px !important;
  background-image:    url(../images/background-image5.jpg);
  background-repeat:   no-repeat;
  background-size:     cover;
  background-position: center top;
}

.contact_page_info {
  padding:    5px;
  background: $color-white;
}

.contact_page_style {
  color:       #71757f;
  line-height: 18px;
  font-size:   18px;
}

.contact_form_position {
  padding-top:         193px !important;
  padding-bottom:      183px !important;
  background-image:    url(../images/background-image5.jpg);
  background-repeat:   no-repeat;
  background-size:     cover;
  background-position: center bottom;
}

/************ 5.1 Transparent Form ***********/

.wpcf7 .transparent_form {
  input {
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.14);
    border:           1px solid rgb(255, 255, 255);
    border:           1px solid rgba(255, 255, 255, 0.3);
    color:            $color-white;
    border-radius:    0;
    opacity:          1;
    &[type="submit"] {
      border:           1px solid $color-white;
      width:            auto !important;
      background-color: rgb(0, 0, 0) !important;
      background-color: rgba(0, 0, 0, 0.14) !important;
      margin-left:      22px !important;
      &:hover {
        border:             1px solid $color-ci-1;
        color:              $color-ci-1 !important;
        -webkit-transition: all 180ms ease-in;
        -moz-transition:    all 180ms ease-in;
        -ms-transition:     all 180ms ease-in;
        -o-transition:      all 180ms ease-in;
        transition:         all 180ms ease-in;
      }
    }
  }
  textarea {
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.14);
    border:           1px solid rgb(255, 255, 255);
    border:           1px solid rgba(255, 255, 255, 0.3);
    height:           44px;
    color:            $color-white;
    opacity:          1;
  }
  input {
    &::-webkit-input-placeholder, &:-webkit-input-placeholder, &::-moz-placeholder, &:-moz-placeholder {
      color:            $color-white;
      font-size:        14px;
      line-height:      26px;
      opacity:          1;
      background-color: transparent;
    }
  }
  textarea {
    &::-moz-placeholder, &::-webkit-input-placeholder, &:-webkit-input-placeholder, &::-moz-placeholder {
      color:            $color-white;
      font-size:        14px;
      line-height:      26px;
      opacity:          1;
      background-color: transparent;
    }
  }
  input:-moz-placeholder, textarea::-moz-placeholder {
    color:            $color-white;
    font-size:        14px;
    line-height:      26px;
    opacity:          1;
    background-color: transparent;
  }
}

div {
  &.wpcf7 {
    margin:  0;
    padding: 0;
  }
  &.wpcf7-response-output {
    margin:  2em 0.5em 1em;
    padding: 0.2em 1em;
  }
  &.wpcf7 .screen-reader-response {
    position: absolute;
    overflow: hidden;
    clip:     rect(1px, 1px, 1px, 1px);
    height:   1px;
    width:    1px;
    margin:   0;
    padding:  0;
    border:   0;
  }
  &.wpcf7-mail-sent-ok {
    border: 2px solid #398f14;
  }
  &.wpcf7-mail-sent-ng {
    border: 2px solid #f00;
  }
  &.wpcf7-spam-blocked {
    border: 2px solid #ffa500;
  }
  &.wpcf7-validation-errors {
    border: 2px solid #f7e700;
  }
}

.wpcf7-form-control-wrap {
  position: relative;
}

span.wpcf7-not-valid-tip {
  color:     #f00;
  font-size: 1em;
  display:   block;
}

.use-floating-validation-tip span.wpcf7-not-valid-tip {
  position:   absolute;
  top:        20%;
  left:       20%;
  z-index:    100;
  border:     1px solid #f00;
  background: $color-white;
  padding:    .2em .8em;
}

span.wpcf7-list-item {
  margin-left: 0.5em;
}

.wpcf7-display-none {
  display: none;
}

div.wpcf7 {
  img.ajax-loader {
    border:         none;
    vertical-align: middle;
    margin-left:    4px;
  }
  div.ajax-error {
    display: none;
  }
  .placeheld {
    color: #888;
  }
}

.timeline-dates {
  @media (min-width: 768px) {
  }
  dt, dd {
    display: inline-block;
    vertical-align: top;
  }
  dt {
    width: 170px;
  }
  dd {
    margin-left: 160px;
    padding-left: 0;
  }
}