

img.mfp-img {
  padding: 0;
}

.mfp-figure {
  &:after {
    display: none;
  }
}