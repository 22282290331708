@import "../includes/colors";

/*-----------------------------------------------------------------*/
/* 3. Header
/*-----------------------------------------------------------------*/

#ABdev_main_header {
  position:           fixed;
  top:                0;
  left:               0;
  padding:            10px 0 10px 0;
  background:         rgb(0, 0, 0);
  background:         rgba(0, 0, 0, 0.23);
  margin-top:         0;
  text-align:         center;
  width:              100%;
  z-index:            99999;
  -webkit-transition: background 200ms ease-in;
  -moz-transition:    background 200ms ease-in;
  -ms-transition:     background 200ms ease-in;
  -o-transition:      background 200ms ease-in;
  transition:         background 200ms ease-in;

  &.static_header {
    position: static;
  }

  &.full_background {
    background: rgba($color-beige-1, 1);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  }
  #main_menu_container {
    display: inline-block;
  }

  .row {
    &:before,
    &:after {
      display: none;
    }
    &--header {
      width:           90%;
      max-width:       1040px;
      margin:          0 auto;
      display:         flex;
      justify-content: space-between;
      align-items:     flex-start;
      @media (min-width: 1024px) {
        width: 100%;
      }
    }
    [class*="span"] {
      float:  none;
      margin: 0;
    }
  }

  .first_menu,
  .second_menu {
    @media (min-width: 1024px) {
      width: 40%;
    }

    #main_menu_left {
      position: relative;
      @media (min-width: 980px) {
        //position: absolute;
        //left:    0;
      }
    }
    #main_menu_right {
      position: relative;
      @media (min-width: 980px) {
        //position: absolute;
        //right:    0;
      }
    }
  }
  .first_menu {
    nav {
      justify-content: flex-start;
    }
  }
  .second_menu {
    nav {
      justify-content: flex-end;
    }
  }
}

.admin-bar #ABdev_main_header {
  &.static_header {
    margin-top: 0;
  }
  margin-top: 32px;
  text-align: center;
}

#logo {
  a {
    display: block;
    img {
      margin: 0 auto;
    }
  }
}

#main_logo {
  display: block !important;
}

#inverted_logo {
  display: none !important;
}

.full_background {
  #main_logo {
    display: none !important;
  }
  #inverted_logo {
    display: block !important;
  }
}

#main_logo_textual {
  font-size:   24px;
  line-height: 24px;
  margin:      14px 0 0 0;
}

#main_logo_tagline {
  font-size: 12px;
  margin:    0;
}

#ABdev_main_header {
  #main_logo_textual,
  #main_logo_tagline {
    color: $color-white;
  }
  &.full_background {
    #main_logo_textual,
    #main_logo_tagline {
      color: #000;
    }
  }
}

/*-------- 3.1. Left Logo Header  --------*/

.admin-bar #ABdev_main_header.regular_header {
  text-align: right;
}

#ABdev_main_header {
  &.regular_header {
    text-align: right;
    #logo {
      visibility: visible;
    }
  }
  &.header_layout_right {
    width:              270px;
    height:             100%;
    right:              0;
    left:               auto;
    top:                0;
    bottom:             0;
    margin:             0;
    background:         $color-white;
    padding:            50px 30px 0 30px;
    box-shadow:         0 0 6px 0 rgba(0, 0, 0, 0.2);
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition:    all 300ms ease-in-out;
    -ms-transition:     all 300ms ease-in-out;
    -o-transition:      all 300ms ease-in-out;
    transition:         all 300ms ease-in-out;
    -webkit-transform:  translateX(300px);
    -moz-transform:     translateX(300px);
    -ms-transform:      translateX(300px);
    -o-transform:       translateX(300px);
    transform:          translateX(300px);
  }
}

/*-------- 3.2. Sidebar Header  --------*/

body.admin-bar #ABdev_main_header.header_layout_right {
  top: 32px;
}

#ABdev_main_header.header_layout_right.visible {
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition:    all 300ms ease-in-out;
  -ms-transition:     all 300ms ease-in-out;
  -o-transition:      all 300ms ease-in-out;
  transition:         all 300ms ease-in-out;
  -webkit-transform:  translateX(0);
  -moz-transform:     translateX(0);
  -ms-transform:      translateX(0);
  -o-transform:       translateX(0);
  transform:          translateX(0);
}

#header_right_wrapper {
  width:  100%;
  height: 100%;
}

#ABdev_main_header.header_layout_right #logo {
  visibility:    visible;
  margin-bottom: 60px;
  a img {
    position: relative;
    display:  block;
  }
  &:after {
    display:    block;
    content:    '';
    width:      83px;
    height:     1px;
    background: $color-ci-1;
    position:   absolute;
    bottom:     -15px;
    left:       0;
  }
}

.header_sidebar_toggle {
  cursor:     pointer;
  position:   fixed;
  right:      40px;
  top:        20px;
  height:     40px;
  width:      40px;
  padding:    5px;
  text-align: center;
  z-index:    9999999;
  display:    none;
}

.sidebar_boxed .header_sidebar_toggle {
  display: block !important;
}

#ABdev_main_header.regular_header .header_sidebar_toggle {
  top: 15px;
}

body.admin-bar .header_sidebar_toggle {
  top: 50px;
}

#ABdev_main_header.header_layout_right .header_sidebar_toggle {
  display: block;
}

.header_sidebar_toggle {
  span {
    cursor:                      pointer;
    position:                    absolute;
    display:                     block;
    left:                        50%;
    top:                         50%;
    margin-top:                  -1px;
    margin-left:                 -10px;
    width:                       20px;
    height:                      3px;
    background-color:            $color-ci-1;
    content:                     "";
    -webkit-transition-duration: 0.3s, 0.3s;
    -moz-transition-duration:    0.3s, 0.3s;
    -ms-transition-duration:     0.3s, 0.3s;
    -o-transition-duration:      0.3s, 0.3s;
    transition-duration:         0.3s, 0.3s;
    -webkit-transition-delay:    0.3s, 0s;
    -moz-transition-delay:       0.3s, 0s;
    -ms-transition-delay:        0.3s, 0s;
    -o-transition-delay:         0.3s, 0s;
    transition-delay:            0.3s, 0s;
    &:before,
    &:after {
      cursor:                      pointer;
      position:                    absolute;
      display:                     block;
      left:                        50%;
      top:                         50%;
      margin-top:                  -1px;
      margin-left:                 -10px;
      width:                       20px;
      height:                      3px;
      background-color:            $color-ci-1;
      content:                     "";
      -webkit-transition-duration: 0.3s, 0.3s;
      -moz-transition-duration:    0.3s, 0.3s;
      -ms-transition-duration:     0.3s, 0.3s;
      -o-transition-duration:      0.3s, 0.3s;
      transition-duration:         0.3s, 0.3s;
      -webkit-transition-delay:    0.3s, 0s;
      -moz-transition-delay:       0.3s, 0s;
      -ms-transition-delay:        0.3s, 0s;
      -o-transition-delay:         0.3s, 0s;
      transition-delay:            0.3s, 0s;
    }
    &:before {
      top:                 -5px;
      transition-property: top, transform;
    }
    &:after {
      bottom:              -6px;
      top:                 auto;
      transition-property: bottom, transform;
    }
  }
  &.active span {
    background-color: transparent;
    &:before,
    &:after {
      -webkit-transition-delay: 0s, 0.3s;
      -moz-transition-delay:    0s, 0.3s;
      -ms-transition-delay:     0s, 0.3s;
      -o-transition-delay:      0s, 0.3s;
      transition-delay:         0s, 0.3s;
    }
    &:before {
      top:               0;
      background:        #4c4c4c;
      -webkit-transform: rotate(45deg);
      -moz-transform:    rotate(45deg);
      -ms-transform:     rotate(45deg);
      -o-transform:      rotate(45deg);
      transform:         rotate(45deg);
    }
    &:after {
      bottom:            1px;
      left:              10px;
      background:        #4c4c4c;
      -webkit-transform: rotate(-45deg);
      -moz-transform:    rotate(-45deg);
      -ms-transform:     rotate(-45deg);
      -o-transform:      rotate(-45deg);
      transform:         rotate(-45deg);
    }
  }
}

#ABdev_main_header {
  &.header_layout_right nav > ul {
    float:        left;
    width:        100%;
    padding-left: 0;
    > li {
      padding:       0 0 10px 0;
      margin:        0 0 10px 0;
      float:         none;
      line-height:   17px;
      border-bottom: 1px solid #e6e8eb;
      &:last-child {
        border-bottom: none;
      }
      &:before {
        display: none;
      }
      a {
        text-align:         left;
        display:            block;
        margin:             0;
        padding:            0 0 0 25px;
        position:           relative;
        width:              auto;
        line-height:        27px;
        -webkit-transition: all 180ms ease-in-out;
        -moz-transition:    all 180ms ease-in-out;
        -o-transition:      all 180ms ease-in-out;
        transition:         all 180ms ease-in-out;
        span {
          display:     inline-block;
          margin-left: 10px;
        }
        i.ci_icon-angle-up {
          font-size:          20px;
          right:              0;
          top:                5px;
          left:               auto;
          -webkit-transform:  rotate(180deg);
          -moz-transform:     rotate(180deg);
          -ms-transform:      rotate(180deg);
          -o-transform:       rotate(180deg);
          transform:          rotate(180deg);
          -webkit-transition: all .5s ease-out;
          -moz-transition:    all .5s ease-out;
          -ms-transition:     all .5s ease-out;
          -o-transition:      all .5s ease-out;
          transition:         all .5s ease-out;
        }
      }
      > a i {
        font-size: 20px;
        position:  absolute;
        top:       2px;
        left:      0;
      }
      > a:after {
        display: none;
      }
    }
    ul {
      display:          none;
      position:         static;
      border:           0;
      background-color: transparent;
      margin-top:       0;
      padding:          0 0 0 10px;
    }
  }
  .header_right_info {
    position:      absolute;
    bottom:        80px;
    left:          0;
    margin:        0;
    padding-left:  30px;
    padding-right: 30px;
    width:         100%;
    text-align:    left;
  }
  .header_right_social {
    margin-bottom:  20px;
    padding-bottom: 20px;
    border-bottom:  1px solid #e6e8eb;
    a {
      padding:       8px 10px;
      border:        1px solid #e6e8eb;
      margin-right:  10px;
      margin-bottom: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .header_right_phone, .header_right_email {
    display:       block;
    margin-bottom: 5px;
  }
  .header_right_phone i, .header_right_email i {
    margin-right: 5px;
  }
}

/*-------- 3.3. Shop Header  --------*/

.shop_nav_menu #main_menu ul.navi.first.menu-depth-1 {
  position: absolute;
  top:      57px;
  left:     -1px;
  position: absolute;
  top:      57px;
  left:     -1px;
}

#ABdev_main_header.header_layout_shop {
  background: $color-white;
  padding:    0 0;
  #top_bar {
    background: #06080a;
    a {
      font-size: 12px;
      color:     $color-white;
    }
  }
}

.header_layout_shop #top_bar .login_link {
  border-right: 1px solid #292929;
}

#ABdev_main_header.header_layout_shop {
  #top_bar {
    a:hover {
      color: $color-white;
    }
    .logout_link {
      margin-left: 3px;
    }
  }
  #nav_menu_bar {
    background:    #fafafa;
    border-top:    1px solid #e6e8eb;
    border-bottom: 1px solid #e6e8eb;
    nav > ul {
      padding-top: 8px;
      > li {
        > a {
          padding-bottom: 0;
        }
        span {
          margin-top:    -19px;
          margin-bottom: -18px;
        }
        > a:after {
          content:       "";
          position:      absolute;
          top:           -12px;
          right:         -35px;
          width:         4px;
          height:        4px;
          border-radius: 50%;
          border:        1px solid $color-ci-1;
        }
      }
    }
  }
  #middle_container {
    background: $color-white;
    padding:    46px 0 27px;
  }
  .widget_search {
    input {
      margin-top: -20px;
    }
    i {
      margin-top: -10px;
    }
  }
  .my_account_button {
    display:       inline-block;
    padding-right: 20px;
    padding-left:  10px;
  }
  .wishlist_page_button {
    display:            inline-block;
    padding-right:      20px;
    -webkit-transition: all 180ms ease-in;
    -moz-transition:    all 180ms ease-in;
    -ms-transition:     all 180ms ease-in;
    -o-transition:      all 180ms ease-in;
    transition:         all 180ms ease-in;
    i {
      margin-right:       7px;
      top:                1px;
      position:           relative;
      -webkit-transition: all 180ms ease-in;
      -moz-transition:    all 180ms ease-in;
      -ms-transition:     all 180ms ease-in;
      -o-transition:      all 180ms ease-in;
      transition:         all 180ms ease-in;
    }
  }
  .my_account_button i, .login_link i {
    margin-right: 7px;
    top:          1px;
    position:     relative;
  }
  .login {
    display: inline-block;
  }
  .ci_icon-group {
    padding-right: 9px;
  }
  #top_bar {
    .my_account_button, .wishlist_page_button, .login_link {
      border-left: 1px solid #292929;
      padding:     10px 14px;
    }
    .my_account_button:hover, .wishlist_page_button:hover, .login_link:hover {
      background: $color-ci-1;
    }
    .login {
      border-right: 1px solid #292929;
      border-left:  1px solid #292929;
      padding:      10px 14px;
      i {
        padding-right: 7px;
      }
    }
  }
}

#shop_links {
  color:          #414447;
  font-size:      15px;
  font-weight:    400;
  line-height:    20px;
  display:        inline-block;
  text-transform: uppercase;
  vertical-align: top;
}

#ABdev_main_header.header_layout_shop .widget_search form {
  margin-bottom: 0;
}

/*-------- 3.4. Main Menu --------*/

#ABdev_menu_toggle {
  display: none;
  cursor:  pointer;
}

.menu_container {
  position: relative;
}

nav {
  position: relative;
  display:  flex;
  > ul {
    list-style: none;
    margin:     0;
    display:    inline-block;
    > li {
      float:       left;
      line-height: 40px;
      margin:      0 10px;
      padding-top: 4px;
      position:    relative;
      @media (min-width: 1024px) {
        margin: 0 15px;
      }
      span {
        display: block;
      }
      &:first-child {
        margin-left: 0px;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        color: $color-ci-1;
        > a {
          color: $color-ci-1;
        }
      }

      &.with_icon > li {
        line-height: 0px;
        padding-top: 0;
        > a:after {
          display: none;
        }
      }
      > a {
        font-weight:     700;
        line-height:     20px;
        text-align:      center;
        display:         inline-block;
        width:           100%;
        margin:          0;
        text-transform:  uppercase;
        position:        relative;
        color:           $color-white;
        font-size:       14px;
        font-style:      normal;
        padding-right:   0;
        text-decoration: none;
        box-sizing:      border-box;
      }
      > li {
        &:last-child > a:after,
        &.no_circle > a:after {
          display: none;
        }
        &:hover a {
          -webkit-transition: all 180ms ease-in;
          -moz-transition:    all 180ms ease-in;
          -ms-transition:     all 180ms ease-in;
          -o-transition:      all 180ms ease-in;
          transition:         all 180ms ease-in;
        }
      }
    }
    ul {
      display:    none;
      list-style: none;
      position:   absolute;
      background: #1a1a1a;
      margin:     0;
      left:       -10px;
      top:        82px;
      min-width:  228px;
      z-index:    2001;
      overflow:   visible;
      padding:    5px 0;
    }
  }
}

li.main_menu_item_logo {
  list-style-type: none;
}

.full_background {
  nav > ul > li {
    &:hover {
      color: $color-ci-1;
    }
    a {
      color: #3d4042;
      &:hover {
        color: $color-ci-1;
      }
    }
  }
}

.full_background nav > ul > li:hover > a {
  color: $color-ci-1;
}

.full_background nav > ul > li:hover a {
  -webkit-transition: all 180ms ease-in;
  -moz-transition:    all 180ms ease-in;
  -ms-transition:     all 180ms ease-in;
  -o-transition:      all 180ms ease-in;
  transition:         all 180ms ease-in;
}

.full_background nav > ul ul {
  background: $color-white;
  border:     1px solid #eee;
}

nav > ul li:hover > ul,
.full_background nav > ul li:hover > ul {
  display: block;
  z-index: 2003;
}

nav > ul {
  li.sfHover > ul {
    display: block;
    z-index: 2003;
  }
  ul li {
    position: relative;
    &.menu-item-has-children > a:before {
      content:            "\e637";
      position:           absolute;
      top:                7px;
      right:              15px;
      font-family:        'core_icomoon';
      font-size:          21px;
      color:              #6b6b6b;
      -webkit-transition: all 180ms ease-in;
      -moz-transition:    all 180ms ease-in;
      -ms-transition:     all 180ms ease-in;
      -o-transition:      all 180ms ease-in;
      transition:         all 180ms ease-in;
    }
  }
}

.full_background nav > ul ul li.menu-item-has-children > a:before {
  color: #3d4042;
}

nav > ul ul li.menu-item-has-children > a:hover:before,
.full_background nav > ul ul li.menu-item-has-children > a:hover:before {
  color: $color-ci-1;
}

nav > ul ul li a {
  width:       100%;
  text-align:  left;
  padding:     6px 13px 7px 13px;
  color:       #6b6b6b;
  margin-left: 0;
  font-size:   12px;
}

.sf-js-enabled nav > ul ul li:hover {
  background: none !important;
}

nav > ul {
  ul ul {
    position:               absolute;
    left:                   100%;
    top:                    0;
    margin-left:            0;
    margin-top:             0;
    z-index:                2002;
    border-top-left-radius: 0;
    li > a {
      color: #6b6b6b;
    }
    &:after {
      display: none;
    }
  }
  > {
    .current-menu-item > a, .current-post-ancestor > a, .current-menu-ancestor > a {
      color: $color-ci-1;
    }
  }
}

.full_background nav > ul > {
  .current-menu-item > a, .current-post-ancestor > a, .current-menu-ancestor > a {
    color: $color-ci-1;
  }
}

nav > ul ul > .current-menu-item > a, .full_background nav > ul ul > .current-menu-item > a {
  color: $color-ci-1;
}

/*-------- 3.5. Mega Menu  --------*/

.sf-mega {
  width:    1170px;
  position: absolute;
  right:    -261px;
  display:  none;
  top:      83px;
  z-index:  9999;
}

.full_background .sf-mega {
  border: 1px solid #eee;
}

.sf-mega-inner {
  background: #1a1a1a;
}

nav > ul li {
  &:hover > .sf-mega, &.sfHover > .sf-mega {
    display: block;
  }
  &:hover > .sf-mega:before {
    content:  "";
    display:  block;
    position: absolute;
    width:    100%;
    height:   36px;
    top:      -36px;
    left:     0;
    z-index:  2004;
  }
}

.sf-mega {
  li, ul {
    position:   static !important;
    display:    block !important;
    padding:    0;
    margin:     0;
    border:     none;
    height:     auto !important;
    background: none;
    box-shadow: none;
  }
}

.sf-mega-inner ul li ul ul > li:hover {
  background: #f5f6f8;
}

nav > ul .sf-mega-inner {
  display: table !important;
  width:   100%;
  > ul {
    display: table-row !important;
    > li {
      border-right:   1px solid #222;
      display:        table-cell !important;
      vertical-align: top;
      background:     #1a1a1a;
    }
  }
}

.full_background nav > ul .sf-mega-inner > ul > li {
  border-right: 1px solid #eee;
  background:   $color-white;
}

nav > ul .sf-mega-inner > ul > li:last-child {
  border-right: none;
}

.sf-mega li {
  border-bottom:  none;
  padding-bottom: 5px;
}

nav > ul .sf-mega-inner > ul > li > a {
  font-family:    'Open Sans', sans-serif;
  font-weight:    700;
  text-transform: uppercase;
  color:          $color-white;
  font-size:      14px;
  line-height:    28px;
  margin-top:     25px;
  margin-bottom:  8px;
  &:before {
    display: none;
  }
}

.full_background nav > ul {
  .sf-mega-inner > ul > li > a {
    color: #3b4550;
  }
  .sf-mega ul {
    border: none;
  }
}

nav > ul {
  .sf-mega-inner {
    > ul > li > ul > li a {
      font-size:      12px;
      line-height:    28px;
      text-transform: uppercase;
    }
    a {
      text-transform: none;
      padding:        0px 10px 0px 15px;
      margin-bottom:  1px;
    }
    .description_menu_item {
      padding:     20px;
      line-height: 22px;
      color:       #333 !important;
      font-family: 'Open Sans';
      font-weight: 400;
      font-size:   13px;
      a {
        display:     inline;
        padding:     0;
        margin:      0;
        line-height: inherit;
        font-size:   inherit;
        color:       #e42382;
        &:hover {
          background: none;
          color:      #b2b2b2;
        }
      }
    }
  }
  > {
    .mega1 .sf-mega-inner > ul > li {
      width: 100%;
    }
    .mega2 .sf-mega-inner > ul > li {
      width: 50%;
    }
    .mega3 .sf-mega-inner > ul > li {
      width: 33.33333333333333%;
    }
    .mega4 .sf-mega-inner > ul > li {
      width: 25%;
    }
    .mega5 .sf-mega-inner > ul > li {
      width: 20%;
    }
    .mega6 .sf-mega-inner > ul > li {
      width: 16.66666666666667%;
    }
    .mega7 .sf-mega-inner > ul > li {
      width: 14.28571428571429%;
    }
    .mega1 ul, .mega2 ul, .mega3 ul, .mega4 ul, .mega5 ul, .mega6 ul, .mega7 ul {
      min-width: 0;
    }
  }
}

.menu_header {
  &:hover {
    background: $color-white;
  }
  a span {
    font-family:    'Open Sans', sans-serif;
    font-weight:    700;
    text-transform: uppercase;
    color:          #3b4550;
    font-size:      14px;
    line-height:    28px;
    margin-top:     25px;
  }
}

/*-------- 3.6. Title/Breadcrumbs Bar --------*/

#headline_breadcrumbs_bar {
  height:          100px;
  padding:         0;
  font-weight:     400;
  font-size:       18px;
  background:      #303030 no-repeat center top;
  background-size: contain;
  @media (min-width: 480px) {
    height: 150px;
  }
  @media (min-width: 768px) {
    height: 200px;
  }
  .image {
    width:      100%;
    height:     100%;
    position:   absolute;
    text-align: center;
    z-index:    0;
    picture {
      display: inline-block;
    }
    img {
      width:  auto;
      height: 100%;
    }
  }
  .container {
    position: relative;
    top:      30px;
    z-index:  1;
    @media (min-width: 560px) {
      top: 60px;
    }
  }
  .span12 {
    margin-left: 0;
  }
  h2 {
    color:          $color-white;
    font-size:      38px;
    font-weight:    400;
    line-height:    43px;
    text-align:     center;
    text-transform: uppercase;
    margin-bottom:  0;
    text-shadow:    1px 1px 5px rgba(0, 0, 0, 0.8);
  }
  .breadcrumbs {
    margin-bottom: 0;
    font-size:     22px;
    line-height:   35px;
    position:      relative;
  }
}

.breadcrumbs {
  a, .current {
    color:         $color-white;
    font-size:     16px;
    font-style:    italic;
    font-weight:   100;
    margin-bottom: 0;
  }
  a:hover {
    color: $color-ci-1;
  }
}

.circle_delimiter {
  border:        1px solid $color-ci-1;
  border-radius: 50%;
  width:         4px;
  height:        4px;
  display:       inline-block;
  margin:        0 5px 3px;
}

.hide {
  display: none;
}