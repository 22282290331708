@import "../includes/colors";

/*-----------------------------------------------------------------*/
/* 6. Pages
/*-----------------------------------------------------------------*/

#default_page_row {
  padding: 100px 0;
  .tcvpb_section_tc {
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
}

/*-------- 6.1. About Us Page --------*/

.section_stats {
  padding: 37px 0 0 0;
}

/*-------- 6.2. 404 Page --------*/

#page404 {
  text-align: center;
}

.big_404 {
  text-align:    center;
  color:         #18191a;
  font-size:     203px;
  font-weight:   300;
  line-height:   20px;
  margin-bottom: 132px;
}

.big_404_text {
  font-family:   'Georgia', serif;
  color:         #99a1ae;
  font-size:     28px;
  font-weight:   400;
  line-height:   23px;
  text-align:    center;
  margin-top:    0;
  margin-bottom: 127px;
}

#page404 {
  a {
    color:       $color-ci-1;
    font-size:   15px;
    font-weight: 400;
    line-height: 20px;
    &:hover {
      color: #18191a;
    }
  }
  .search_404 {
    width:      30%;
    text-align: center;
    display:    inline-block;
  }
}

#fbwall {
  padding: 0 20%;
  .post-item {
    margin-bottom:  30px;
    padding-bottom: 30px;
    border-bottom:  1px solid $color-grey-d9;
    list-style:     none;

    &__link {
      font-weight: 700;
    }
    &__title {
      margin-bottom: 10px;
    }
    &__main {

    }
    &__image {
      width: 130px;
      float: left;
      img {
        width:  100%;
        height: auto;
      }
    }
    &__details {
      width:        80%;
      padding-left: 15px;
      position:     relative;
      top:          -3px;
      float:        left;
      overflow:     hidden;
    }
    &__status-info {
      li {
        display:        inline-block;
        vertical-align: top;
        margin-right:   10px;
      }
    }
  }
}