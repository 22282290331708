@import "../includes/colors";
@import "../../modules/forms/forms";

$theme_GRID__gap--half: 15px;

$color-input-border: #d9d9d9;

$font-family--main: Arial;

input,
textarea {
  &:-webkit-autofill {
    background-color: $color-white;
  }
  &::-webkit-input-placeholder {
    color: $color-input-border;
  }
  &::-moz-placeholder {
    color: $color-input-border;
  }
  &:-moz-placeholder {
    color: $color-input-border;
  }
  &:-ms-input-placeholder {
    color: $color-input-border;
  }
}

.form {
  fieldset {
    .gap {
      margin-bottom: ($theme_GRID__gap--half / 2);
      &:last-child,
      &:only-child,
      &:only-of-type {
        margin-bottom: 0;
      }
    }
  }
  select,
  textarea,
  input:not([type='radio']),
  input:not([type='checkbox']),
  .form-control,
  .form-input {
    width:         100%;
    //height:        34px;
    margin-bottom: 0;
    padding:       ($theme_GRID__gap--half / 2) $theme_GRID__gap--half;
    font-size:     14px;
    //color:         $color-steel-grey;
    //font-family:   $font-family--main;
    border-radius: 0;
    outline:       none;
  }

  input[type='radio'],
  input[type='checkbox'] {
    width:  40px;
    height: 36px;
    margin: 0 ($theme_GRID__gap--half / 2) 0 0;
    & + .control-label {
      width:       calc(100% - 48px);
      padding-top: ($theme_GRID__gap--half / 3 * 2);
      line-height: 16px;
    }
    &:focus {
      box-shadow: none;
    }
    & + .control-label {
      padding-top: 5px;
    }
  }

  .input-group {
    width:           100%;
    display:         flex;
    justify-content: flex-start;
    b {
      font-weight: 700;
    }
  }

  .radio-group {
    width:   50%;
    display: flex;
  }

  .form-group {
    margin-bottom: $theme_GRID__gap--half;
    padding:       0;
    display:       flex;
    font-size:     0;
    transition:    all 0.5s ease-in-out;
    max-height:    500px;
    flex-wrap:     wrap;
    &:only-child {
      margin-bottom: 0;
    }

    &.has-error {
      .control-label, .hint {
        color: $color-error;
      }

      select,
      textarea,
      input:not([type='radio']),
      input:not([type='checkbox']) {
        border-color: $color-error;
      }
    }

    &--disabled {
      max-height:    0;
      margin-bottom: 0;
      overflow:      hidden;
    }

    &--submit {
      justify-content: space-between;
      flex-wrap:       wrap;
    }

    &--buttons {
      margin-bottom:   0;
      justify-content: flex-start;
      flex-wrap:       wrap;
      .btn {
        margin-bottom: $theme_GRID__gap--half;
      }
    }
  }

  .control-label {
    width:         100%;
    margin-bottom: 0;
    font-size:     12px;
    //font-family:   $font-family--main;
    //color:         $color-input-border;
  }

  .response {
    margin-bottom: 10px;
    padding:       $theme_GRID__gap--half;
    font-weight:   700;
    &:before {
      font-size: 24px;
    }

    &.error {
      color: $color-error;
      &:before {
        // .icon(@fa-var-exclamation-circle, 10px, 0);
        color: $color-error;
      }
    }
    &.success {
      color: $color-success;
      &:before {
        // .icon(@fa-var-check-circle, 10px, 0);
        color: $color-success;
      }
    }

    .hint {
      display: inline-block;
    }
  }

  .hint {
    display:     block;
    font-size:   11px;
    font-weight: 400;
  }

  &__wrapper {
    position: relative;
  }

  &__cover {
    width:      100%;
    height:     100%;
    position:   absolute;
    left:       0;
    top:        0;
    background: $color-grey-dark;
    opacity:    0;
    z-index:    -1;
    transition: all 0.25s;
    &.processing {
      opacity: 0.9;
      z-index: 1;
    }
  }
}

.form-fieldset {
  .hidden {
    display: none;
  }
  &--type {
    display: flex;

    &-single {
      > .twin {
        display: none;
      }
    }
    &-twin {
      > .single {
        padding-right: 15px;
        flex:          1;
      }
      > .twin {
        padding-left: 15px;
        display:      block;
        flex:         1;
      }
    }
  }
  &__col {
    width: 100%;
  }
}

.form.registration {
  h6 {
    margin-bottom: 5px;
  }
  .textarea {
    height: 190px;
  }
  .form__cover {
    background-color: $color-white;
  }
}

.registration-counter {
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.9);
  &__count {
    padding:     2px 5px;
    font-weight: 700;
    color:       #fff;
    background:  $color-ci-2;
  }
}

.sidebar-widget {
  margin-bottom: 40px;
  text-shadow: none;
  &.registration-counter {
    &__count {
      font-size:   24px;
    }
  }
}