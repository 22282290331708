@import "../includes/colors";

/*-----------------------------------------------------------------*/
/* 7. Slider Place
/*-----------------------------------------------------------------*/

.rev_slider_wrapper {
  z-index: 2;
}

.rev_slider {
  &_item {
    h1, h2, h3 {
      text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.9);
    }
    h3 {
      line-height: 1.4 !important;
    }
  }
}

#ABdev_main_slider {
  padding:         0;
  background-size: cover;
  position:        relative;
  background:      #f2f3f4;
  overflow:        hidden;
}

#ABdev_main_slider_content {
  text-align: center;
}

#ABdev_main_slider_logo {
  position: absolute;
  top:      10%;
  width:    100%;
}

#ABdev_main_slider_text {
  font-size:      70px;
  font-weight:    300;
  text-transform: uppercase;
  line-height:    84px;
  position:       absolute;
  top:            30%;
  width:          100%;
  .bold {
    font-size: 84px;
    display:   block;
  }
}

#ABdev_main_slider_scroll {
  position: absolute;
  bottom:   70px;
  width:    100%;
}

.ABdev_main_slider_scroll_icon {
  display:               inline-block;
  background:            rgb(0, 0, 0);
  background:            rgba(0, 0, 0, 0.5);
  padding:               9px 25px;
  -webkit-border-radius: 2px;
  -moz-border-radius:    2px;
  border-radius:         2px;
}

.ABdev_main_slider_scroll_info {
  text-transform: uppercase;
}

.ABdev_main_slider .tp-dottedoverlay.twoxtwo {
  background: #050b0f;
  opacity:    0.8;
  z-index:    1;
}

.pattern_overlayed #ABdev_main_slider_content {
  z-index: 1;
}

#frontpage_slider {
  padding: 0;
  margin:  0;
}

.tp-leftarrow.default, .tp-rightarrow.default {
  background:        none !important;
  -webkit-transform: rotate(45deg);
  -moz-transform:    rotate(45deg);
  -o-transform:      rotate(45deg);
  -ms-transform:     rotate(45deg);
  transform:         rotate(45deg);
  width:             48px;
  height:            48px;
}

.tp-leftarrow.default {
  border-left:   2px solid rgb(115, 115, 115);
  border-left:   2px solid rgba(115, 115, 115, 0.5);
  border-bottom: 2px solid rgb(115, 115, 115);
  border-bottom: 2px solid rgba(115, 115, 115, 0.5);
  margin-left:   40px;
}

.tp-rightarrow.default {
  border-top:   2px solid rgb(115, 115, 115);
  border-top:   2px solid rgba(115, 115, 115, 0.5);
  border-right: 2px solid rgb(115, 115, 115);
  border-right: 2px solid rgba(115, 115, 115, 0.5);
  margin-right: 40px;
}

.tp-leftarrow.default:hover, .tp-rightarrow.default:hover {
  background:   none !important;
  border-color: rgb(255, 255, 255);
  border-color: rgba(255, 255, 255, 0.7);
}

.about_us_slider {
  .tp-leftarrow.default, .tp-rightarrow.default {
    -webkit-transform: rotate(0deg);
    -moz-transform:    rotate(0deg);
    -o-transform:      rotate(0deg);
    -ms-transform:     rotate(0deg);
    transform:         rotate(0deg);
    background:        $color-ci-1 !important;
    width:             61px;
    height:            55px;
    border:            none;
  }
  .tp-leftarrow.default {
    position: relative;
    bottom:   200px;
    &:after {
      content:           '';
      width:             2px;
      height:            13px;
      background:        $color-white;
      position:          absolute;
      top:               24px;
      right:             29px;
      -webkit-transform: rotate(-45deg);
      -moz-transform:    rotate(-45deg);
      -o-transform:      rotate(-45deg);
      -ms-transform:     rotate(-45deg);
      transform:         rotate(-45deg);
    }
    &:before {
      content:           '';
      position:          absolute;
      top:               16px;
      right:             29px;
      width:             2px;
      height:            13px;
      background:        $color-white;
      -webkit-transform: rotate(45deg);
      -moz-transform:    rotate(45deg);
      -o-transform:      rotate(45deg);
      -ms-transform:     rotate(45deg);
      transform:         rotate(45deg);
    }
  }
  .tp-rightarrow.default {
    margin-right: 0;
    &:after {
      content:           '';
      width:             13px;
      height:            2px;
      background:        $color-white;
      position:          absolute;
      top:               31px;
      right:             24px;
      -webkit-transform: rotate(-45deg);
      -moz-transform:    rotate(-45deg);
      -o-transform:      rotate(-45deg);
      -ms-transform:     rotate(-45deg);
      transform:         rotate(-45deg);
    }
    &:before {
      content:           '';
      position:          absolute;
      top:               22px;
      right:             24px;
      width:             13px;
      height:            2px;
      background:        $color-white;
      -webkit-transform: rotate(45deg);
      -moz-transform:    rotate(45deg);
      -o-transform:      rotate(45deg);
      -ms-transform:     rotate(45deg);
      transform:         rotate(45deg);
    }
  }
}

.tp-bullets.simplebullets.round .bullet {
  width:                 8px;
  height:                8px;
  -webkit-border-radius: 8px;
  -moz-border-radius:    8px;
  border-radius:         8px;
  margin-right:          7px;
  margin-bottom:         5px;
  &.selected {
    background:            rgb(0, 0, 0);
    background:            rgba(0, 0, 0, 0.28);
    width:                 14px;
    height:                14px;
    -webkit-border-radius: 8px;
    -moz-border-radius:    8px;
    border-radius:         8px;
    margin-right:          7px;
    border:                1px solid $color-white;
  }
  background:            transparent;
  width:                 14px;
  height:                14px;
  -webkit-border-radius: 8px;
  -moz-border-radius:    8px;
  border-radius:         8px;
  margin-right:          7px;
  border:                1px solid $color-white;
  display:               inline-block;
  &:hover, &.selected {
    background-color: $color-white;
  }
}

.tp-caption {
  a.inspirado_transparent_button {
    padding:        18px 20px;
    background:     transparent;
    text-transform: uppercase;
    border:         1px solid $color-white;
    color:          #f0f0f0;
    font-size:      14px;
    font-weight:    700;
    line-height:    27px;
    font-style:     normal;
    &:hover {
      background-color: $color-ci-1;
    }
  }
  .slider_button_small {
    color:            #f0f0f0;
    font-size:        14px;
    font-weight:      700;
    line-height:      27px;
    text-align:       center;
    text-transform:   uppercase;
    background-color: transparent;
    border-width:     0;
    border-color:     #f0f0f0;
    border-style:     none;
    text-decoration:  none;
    border:           1px solid #e4d9d7;
    padding:          17px 49px;
  }
  a.header_subtitle_white {
    font-family: 'Georgia', serif;
    color:       $color-white;
    font-size:   20px;
    font-style:  italic;
    font-weight: 300;
    line-height: 54px;
    text-align:  center;
  }
}

.header_subtitle_white {
  &:before {
    content:     '';
    width:       55px;
    height:      1px;
    background:  #d5c1bf;
    position:    absolute;
    left:        0;
    margin-left: -72px;
    top:         27px;
  }
  &:after {
    content:     '';
    width:       55px;
    height:      1px;
    background:  #d5c1bf;
    position:    absolute;
    right:       -70px;
    margin-left: 0;
    top:         27px;
  }
}

.tp-caption {
  &.small_light_white:before {
    content:     '';
    width:       55px;
    height:      1px;
    background:  $color-white;
    position:    absolute;
    left:        0;
    margin-left: -76px;
    top:         28px;
  }
  &.small_text_subtitle:after {
    content:     '';
    width:       56px;
    height:      1px;
    background:  #b8b9ba;
    position:    absolute;
    left:        0;
    top:         40px;
    margin-left: 119px;
  }
  &.small_light_white {
    &.sfl {
      &:before, &:after {
        display: none;
      }
    }
    &:after {
      content:      '';
      width:        55px;
      height:       1px;
      background:   $color-white;
      position:     absolute;
      right:        0;
      margin-right: -76px;
      top:          28px;
    }
    font-family:      Georgia;
    color:            $color-white;
    font-size:        20px;
    font-weight:      400;
    font-style:       italic;
    line-height:      54px;
    background-color: transparent;
    text-decoration:  none;
    border-width:     0px;
    border-color:     rgb(255, 255, 255);
    border-style:     none;
    text-align:       center;
  }
}

/*REVOLUTION LAYERS STYLES*/
/*HOME*/

.small_light_white {
  font-family:      Georgia;
  color:            $color-white;
  font-size:        20px;
  font-weight:      400;
  font-style:       italic;
                    //line-height:      54px;
  background-color: transparent;
  text-decoration:  none;
  border-width:     0px;
  border-color:     rgb(255, 255, 255);
  border-style:     none;
  text-align:       center;
}

.tp-caption.mediumlightwhite2,
.mediumlightwhite2 {
  font-size:        30px;
  line-height:      76px;
  font-weight:      700;
  font-family:      "Open Sans";
  color:            rgb(255, 255, 255);
  text-decoration:  none;
  background-color: transparent;
  border-width:     0px;
  border-color:     rgb(255, 255, 255);
  border-style:     none;
  text-align:       center;
}

.tp-caption.largeboldwhite2, .largeboldwhite2 {
  font-size:        80px;
  line-height:      86px;
  font-weight:      700;
  font-family:      "Open Sans";
  color:            rgb(255, 255, 255);
  text-decoration:  none;
  background-color: transparent;
  border-width:     0px;
  border-color:     rgb(255, 255, 255);
  border-style:     none;
  text-align:       center;
}

.tp-caption.small_light_white_text, .small_light_white_text {
  padding:          18px 20px 18px 20px;
  font-family:      Lato;
  color:            #f0f0f0;
  font-size:        14px;
  font-weight:      700;
  line-height:      27px;
  text-decoration:  none;
  background-color: transparent;
  border-width:     0px;
  border-color:     rgb(240, 240, 240);
  border-style:     none;
  text-transform:   uppercase;
  background:       transparent;
}

.tp-caption.medium_white_hom_2, .medium_white_hom_2 {
  background-color: transparent;
  font-family:      Lato;
  color:            $color-white;
  font-size:        29px;
  font-weight:      700;
  line-height:      42px;
  text-decoration:  none;
  border-width:     0px;
  border-color:     rgb(255, 214, 88);
  border-style:     none;
  z-index:          3 !important;
}

.tp-caption.smalllightwhitetextsl2, .smalllightwhitetextsl2 {
  font-size:       18px;
  line-height:     30px;
  font-weight:     400;
  font-family:     Lato;
  color:           rgb(240, 240, 240);
  text-decoration: none;
  padding:         18px 20px;
  border-width:    0px;
  border-color:    rgb(240, 240, 240);
  border-style:    none;
  text-transform:  uppercase;
  background:      transparent;
}

.tp-caption.smallsubtitlehom3, .smallsubtitlehom3 {
  text-decoration:  none;
  font-family:      Lato;
  color:            $color-white;
  font-size:        18px;
  font-weight:      400;
  line-height:      28px;
  background-color: transparent;
  border:           0 #fff none;
  text-align:       center;
}

/*#rev_slider_1_1_wrapper .tp-loader.spinner4{
	background-color: #FFFFFF !important;
}*/

.custom.tparrows {
  cursor:     pointer;
  background: #000;
  background: rgba(0, 0, 0, 0.5);
  width:      40px;
  height:     40px;
  position:   absolute;
  display:    none;
  z-index:    100;
  &:hover {
    background: #000;
  }
  &:before {
    font-family: "revicons";
    font-size:   15px;
    color:       $color-white;
    display:     block;
    line-height: 40px;
    text-align:  center;
  }
  &.tp-leftarrow:before {
    content: "";
  }
  &.tp-rightarrow:before {
    content: "";
  }
}

/*ABOUT US*/

#rev_slider_4_1_wrapper .tp-loader.spinner4 {
  background-color: $color-white !important;
}

.inspirado.tparrows {
  background:         $color-ci-1;
  width:              61px;
  height:             55px;
  border:             1px solid $color-ci-1;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition:    all 300ms ease-in-out;
  -o-transition:      all 300ms ease-in-out;
  -ms-transition:     all 300ms ease-in-out;
  transition:         all 300ms ease-in-out;
  &:hover {
    background: transparent;
    border:     1px solid $color-ci-1;
  }
  &:before {
    line-height: 55px;
    font-size:   23px;
  }
}