$color-white: #fff;
$color-grey-d9: #d9d9d9;

$color-steel-grey: #9fa6af;
$color-steel-grey-darker: #5f6163;

$color-blue-grey: #182129;
$color-blue-grey-darker: #13191f;

$color-grey-68: #686d73;

$color-ci-1-light: #748500;
$color-ci-1: #4e5e00;
$color-ci-1-dark: #3b4b00;
$color-ci-1-darker: #2e3e00;

$color-ci-2-light: #e4312f;
$color-ci-2: #ca302e;
$color-ci-2-dark: #aa2e2e;
$color-ci-2-darker: #7a2020;

$color-beige-1: #ece7d3;
$color-beige-dark: #b5b494;
$color-beige-darker: #919071;

$color-grey-dark: #2d2d2d;
$color-grey-darker: #252525;


$color-error: #ff0000;
$color-success: #799f07;