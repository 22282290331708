@import "../includes/colors";

/*-----------------------------------------------------------------*/
/* 10. Portfolio
/*-----------------------------------------------------------------*/

.mfp-content {
  width:      80%;
  max-width:  600px;
  padding:    10px;
  background: #fff;
  .lightbox-content {
    text-align: center;
  }
}

.portfolio_item {
  color:              #929292;
  -webkit-box-shadow: 0 0 0 2px $color-white inset;
  -moz-box-shadow:    0 0 0 2px $color-white inset;
  box-shadow:         0 0 0 2px $color-white inset;
  display:            inline-block;
  position:           relative;
  text-align:         center;
  .overlayed {
    position:   relative;
    text-align: center;
  }
  h4 {
    margin:   0 0 27px 0;
    position: relative;
    z-index:  1;
  }
  span {
    z-index:  1;
    position: relative;
  }
  h4 a {
    color:          $color-white;
    font-size:      30px;
    font-weight:    300;
    text-transform: uppercase;
    &:hover {
      color: #e4664d;
    }
  }
  .overlayed .item {
    width:              100%;
    height:             100%;
    position:           absolute;
    overflow:           hidden;
    top:                0;
    left:               0;
    opacity:            0;
    background:         none;
    color:              $color-white;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition:    all 300ms ease-in-out;
    -o-transition:      all 300ms ease-in-out;
    -ms-transition:     all 300ms ease-in-out;
    transition:         all 300ms ease-in-out;
    z-index:            1;
  }
  img {
    width:          100%;
    height:         auto;
    vertical-align: top;
  }
  .overlayed .overlay .overlay_title {
    font-size:          19px;
    line-height:        24px;
    color:              $color-white;
    text-transform:     uppercase;
    font-weight:        400;
    margin:             20% 0 7px;
    -ms-filter:         "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity:            0;
    background:         transparent;
    -webkit-transform:  scale(10);
    -moz-transform:     scale(10);
    -o-transform:       scale(10);
    -ms-transform:      scale(10);
    transform:          scale(10);
    -webkit-transition: all 0.3s ease-in-out 0.1s;
    -moz-transition:    all 0.3s ease-in-out 0.1s;
    -o-transition:      all 0.3s ease-in-out 0.1s;
    -ms-transition:     all 0.3s ease-in-out 0.1s;
    transition:         all 0.3s ease-in-out 0.1s;
  }
  &:hover .overlayed .overlay .overlay_title {
    -ms-filter:               "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity:                  1;
    -webkit-transform:        scale(1);
    -moz-transform:           scale(1);
    -o-transform:             scale(1);
    -ms-transform:            scale(1);
    transform:                scale(1);
    -webkit-transition-delay: 0.1s;
    -moz-transition-delay:    0.1s;
    -o-transition-delay:      0.1s;
    -ms-transition-delay:     0.1s;
    transition-delay:         0.1s;
  }
  .overlayed .overlay .portfolio_item_tags {
    font-style:         italic;
    margin-bottom:      0;
    font-weight:        300;
    color:              $color-white;
    font-size:          16px;
    text-align:         center;
    -ms-filter:         "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity:            0;
    -webkit-transform:  scale(10);
    -moz-transform:     scale(10);
    -o-transform:       scale(10);
    -ms-transform:      scale(10);
    transform:          scale(10);
    -webkit-transition: all 0.3s ease-in-out 0.2s;
    -moz-transition:    all 0.3s ease-in-out 0.2s;
    -o-transition:      all 0.3s ease-in-out 0.2s;
    -ms-transition:     all 0.3s ease-in-out 0.2s;
    transition:         all 0.3s ease-in-out 0.2s;
  }
  &:hover .overlayed .overlay .portfolio_item_tags {
    -ms-filter:               "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity:                  1;
    -webkit-transform:        scale(1);
    -moz-transform:           scale(1);
    -o-transform:             scale(1);
    -ms-transform:            scale(1);
    transform:                scale(1);
    -webkit-transition-delay: 0.2s;
    -moz-transition-delay:    0.2s;
    -o-transition-delay:      0.2s;
    -ms-transition-delay:     0.2s;
    transition-delay:         0.2s;
  }
}

.portfolio_icon {
  width:          60px;
  height:         60px;
  border-radius:  50%;
  border:         1px solid $color-white;
  text-align:     center;
  display:        inline-block;
  vertical-align: middle;
  margin-top:     35px;
  &:first-of-type {
    margin-right: 20px;
  }
  &.lightbox {
    position: relative;
  }
  &:hover {
    background: $color-white;
    border:     1px solid $color-white;
  }
  i {
    line-height: 60px;
    color:       $color-white;
    font-size:   24px;
    display:     inline;
  }
  &:hover i {
    color: $color-ci-1;
  }
}

.portfolio_icons_container {
  display:            inline-block;
  z-index:            9999;
  -ms-filter:         "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  opacity:            0;
  -webkit-transform:  scale(10);
  -moz-transform:     scale(10);
  -o-transform:       scale(10);
  -ms-transform:      scale(10);
  transform:          scale(10);
  -webkit-transition: all 0.3s ease-in-out 0.1s;
  -moz-transition:    all 0.3s ease-in-out 0.1s;
  -o-transition:      all 0.3s ease-in-out 0.1s;
  -ms-transition:     all 0.3s ease-in-out 0.1s;
  transition:         all 0.3s ease-in-out 0.1s;
}

.portfolio_item:hover {
  .portfolio_icons_container {
    -ms-filter:               "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity:                  1;
    -webkit-transform:        scale(1);
    -moz-transform:           scale(1);
    -o-transform:             scale(1);
    -ms-transform:            scale(1);
    transform:                scale(1);
    -webkit-transition-delay: 0.3s;
    -moz-transition-delay:    0.3s;
    -o-transition-delay:      0.3s;
    -ms-transition-delay:     0.3s;
    transition-delay:         0.3s;
  }
  .overlayed .item {
    opacity:    1;
    background: rgba(0, 208, 245, 0.85);
  }
}

.portfolio_item_3:hover .overlayed .item,
.portfolio_item_4:hover .overlayed .item,
.portfolio_item_5:hover .overlayed .item {
  border-radius: 0;
}

.media_container {
  text-align: center;
  width:      1170px;
  margin:     0 auto;
}

.portfolio_item_meta {
  h5 {
    margin-bottom:  15px;
    font-size:      14px;
    padding-bottom: 0;
    line-height:    13px;
    a {
      font-weight: 700;
    }
  }
  .portfolio_item_meta_label {
    color:       #18191a;
    font-size:   15px;
    font-weight: 700;
    line-height: 20px;
    text-align:  left;
  }
  .portfolio_item_meta_data {
    color:       #9098a3;
    font-size:   14px;
    font-style:  italic;
    line-height: 20px;
  }
}

.portfolio_single_detail {
  margin-bottom: 0;
  margin-top:    8px;
}

.portfolio_item_meta p:last-child {
  border-bottom: none;
}

.portfolio_item_image {
  margin-bottom: 35px;
}

.portfolio_item_view_link {
  margin-top:    30px;
  margin-bottom: 0;
  a {
    display:        inline-block;
    background:     transparent;
    color:          $color-ci-1;
    border:         1px solid #e1e3e6;
    padding:        12px 35px;
    font-weight:    700;
    font-size:      14px;
    text-transform: uppercase;
    &:hover {
      background: $color-ci-1;
      color:      $color-white;
      border:     1px solid $color-ci-1;
    }
  }
}

.portfolio_share_social {
  color:          #3b4550;
  font-size:      14px;
  font-weight:    700;
  text-transform: uppercase;
  a:first-child {
    margin-left: 20px;
  }
  i {
    font-size:   16px;
    line-height: 30px;
  }
}

.portfolio_filter {
  text-align:    center;
  margin-bottom: 40px;
  padding-left:  0;
  width:         100%;
  display:       inline-block;
  li {
    list-style:    none;
    display:       inline-block;
    position:      relative;
    border:        1px solid #e8e8e8;
    border-right:  0;
    border-radius: 0;
    &:last-of-type {
      border-right: 1px solid #e8e8e8;
    }
    a {
      padding:        8px 15px;
      font-weight:    400;
      background:     $color-white;
      display:        inline-block;
      text-transform: uppercase;
    }
  }
}

.tcvpb-inversed_text .portfolio_filter li a {
  color: $color-white;
}

.portfolio_filter {
  li {
    &:hover {
      color:      $color-ci-1;
      background: #eaeaea;
      a {
        color:      $color-ci-1;
        background: #eaeaea;
      }
    }
    a.selected {
      color:      $color-ci-1;
      background: $color-white;
    }
  }
  i {
    margin-right: 10px;
    margin-left:  15px;
    font-size:    9px;
    top:          0;
  }
}

.load_more_button {
  text-align: center;
  display:    block;
  .more_portfolio_link {
    display:     inline-block;
    background:  transparent;
    border:      1px solid #dee0e3;
    color:       $color-ci-1;
    font-size:   14px;
    line-height: 22px;
    font-weight: 700;
    padding:     14px 27px 15px 27px;
    cursor:      pointer;
  }
}

.portfolio_front {
  background-color: $color-white;
}

.portfolio_item_5 {
  width: 19.98%;
}

.portfolio_item_4 {
  width: 25%;
}

.portfolio_item_3 {
  width: 33.31%;
}

.portfolio_item_2 {
  width: 47%;
  img {
    margin-bottom: 9px;
  }
  h6 {
    margin-bottom: -10px;
  }
  .column_title_center a {
    color:       #18191a;
    font-size:   16px;
    font-weight: 700;
    line-height: 20px;
    text-align:  center;
    &:hover {
      color: $color-ci-1;
    }
  }
  .portfolio_2_column_info span {
    color:       #99a1ae;
    font-size:   14px;
    font-style:  italic;
    line-height: 20px;
    text-align:  center;
  }
  &:nth-child(2n+2) {
    margin-right: 0;
  }
}

#related_portfolio {
  padding:       0;
  margin-bottom: 94px;
  display:       inline-block;
  width:         100%;
  text-align:    center;
  .container, .tcvpb_column_tc_span12 {
    display: inline-block;
  }
  h3 {
    color:          #181819;
    font-size:      28px;
    font-weight:    700;
    line-height:    76px;
    text-align:     center;
    position:       relative;
    margin-bottom:  50px;
    text-transform: uppercase;
    &:before {
      content:     "";
      position:    absolute;
      bottom:      0;
      left:        50%;
      margin-left: -41px;
      width:       83px;
      height:      1px;
      background:  $color-ci-1;
    }
  }
  .portfolio_item .overlayed .item {
    .overlay_title, .portfolio_item_tags {
      right: 20px;
    }
  }
  .portfolio_3_column {
    margin-right: 30px;
    &:nth-of-type(3) {
      margin-right: 0;
    }
  }
  img {
    height:         auto;
    width:          100%;
    vertical-align: top;
  }
}

.tcvpb_section_tc.portfolio_home .more_portfolio_link a {
  background:     transparent;
  border:         1px solid #e1e3e6;
  color:          $color-ci-1;
  font-size:      14px;
  line-height:    22px;
  font-weight:    700;
  padding:        22px 44px 23px 44px;
  text-transform: uppercase;
  font-style:     normal;
  &:hover {
    background: $color-ci-1;
    border:     1px solid #e1e3e6;
    color:      $color-white;
  }
}

.more_portfolio_link {
  margin:     60px 0 0 0;
  text-align: center;
  a {
    background:  transparent;
    border:      1px solid #dee0e3;
    color:       $color-ci-1;
    font-size:   14px;
    line-height: 22px;
    font-weight: 700;
    padding:     14px 27px 15px 27px;
    display:     inline-block;
    text-align:  center;
    &:hover {
      background: $color-ci-1;
      border:     1px solid #e1e3e6;
      color:      $color-white;
    }
  }
}

.portfolio-readmore {
  margin-top: 30px;
  .more-link {
    margin-top: 30px;
  }
}

.container_fullwidth {
  width:  100%;
  margin: 0 auto;
}

.ABp_latest_portfolio .portfolio_icon_container {
  position:    absolute;
  top:         25%;
  margin-top:  -20px;
  margin-left: -25%;
  display:     inline-block;
}

.caroufredsel_wrapper {
  margin: 0 !important;
}

.ABp_latest_portfolio {
  padding:    0;
  list-style: none;
  display:    block;
  li {
    float:          left;
    display:        block;
    margin:         0 12px 0 0;
    padding:        0;
    border-radius:  0;
    overflow:       hidden;
    border:         1px solid #eee;
    padding-bottom: 20px;
    background:     $color-white;
    &.portfolio_item_4 {
      width: 280px;
    }
    &.portfolio_item_3 {
      width: 377px;
    }
  }
}

.content_with_right_sidebar .ABp_latest_portfolio li.portfolio_item_3, .content_with_left_sidebar .ABp_latest_portfolio li.portfolio_item_3 {
  width: 278px;
}

.ABp_latest_portfolio {
  .overlay_title {
    display:        block;
    margin-top:     10px;
    margin-bottom:  0;
    font-weight:    700;
    text-transform: uppercase;
    font-size:      14px;
    line-height:    28px;
    color:          #3b4550;
    text-align:     center;
    &:hover {
      color: $color-ci-1;
    }
  }
  .portfolio_item_tags {
    margin-bottom: 0;
    font-weight:   400;
    font-size:     14px;
    line-height:   28px;
    color:         #9fa4af;
    text-align:    center;
  }
}

.portfolio_navigation {
  margin-top: 20px;
  text-align: center;
  position:   relative;
  a {
    color:      #9fa4af;
    width:      48px;
    height:     48px;
    border:     1px solid #eee;
    display:    inline-block;
    text-align: center;
    margin:     0 5px;
    background: -webkit-linear-gradient($color-white, #f5f6f8);
    background: -o-linear-gradient($color-white, #f5f6f8);
    background: linear-gradient($color-white, #f5f6f8);
    &:hover {
      color:  $color-ci-1;
      border: 1px solid $color-ci-1;
    }
    i {
      line-height: 47px;
      font-size:   35px;
    }
  }
}

.portfolio_prev {
  position: absolute;
  top:      -165px;
  left:     -20px;
  z-index:  10;
}

.portfolio_next {
  position: absolute;
  top:      -165px;
  right:    -8px;
  z-index:  10;
}

.section_body_fullwidth {
  .portfolio_prev {
    left: 0;
  }
  .portfolio_next {
    right: 0;
  }
}

.portfolio_container {
  font-size: 0;
}

.portfolio_inner_content .item {
  position: relative;
  display:  inline-block;
  &:before {
    content:            "";
    position:           absolute;
    top:                0;
    left:               0;
    width:              100%;
    height:             100%;
    background-color:   transparent;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition:    all 300ms ease-in-out;
    -o-transition:      all 300ms ease-in-out;
    -ms-transition:     all 300ms ease-in-out;
    transition:         all 300ms ease-in-out;
  }
  &:hover {
    :before {
      background: rgba($color-ci-1, 0.85);
    }
  }
}

.portfolio_item.portfolio_title_under {
  background: $color-white;
  .portfolio_icon_container {
    display:    inline-block;
    width:      100%;
    height:     100%;
    text-align: center;
    .portfolio_icon {
      top:      30%;
      position: relative;
    }
  }
  .overlay_title {
    color:          #181818;
    text-transform: uppercase;
    display:        block;
    margin:         20px 0 0;
    font-weight:    700;
    &:hover {
      color: $color-ci-1;
    }
  }
  .portfolio_item_tags {
    font-style:    italic;
    display:       block;
    margin-bottom: 20px;
  }
}

/*----------- 10.1. Portfolio - 2 Columns --------------*/

.portfolio_2_column {
  width:         48.71794871794871%;
  margin-bottom: 30px;
  display:       inline-block;
  &:nth-of-type(2n+2) {
    margin-right: 0;
  }
  .portfolio_inner_content {
    overflow: hidden;
    border:   1px solid #e6e8eb;
    .portfolio_item_meta {
      padding:    14px 0 0;
      text-align: center;
      h6 {
        margin: 0;
      }
      .column_title_center {
        line-height: 28px;
        margin-top:  0;
      }
      a {
        color:          #18191a;
        font-size:      16px;
        font-weight:    700;
        line-height:    20px;
        text-transform: uppercase;
        &:hover {
          color: $color-ci-1;
        }
      }
    }
  }
}

/*----------- 10.2. Portfolio - 3 Columns --------------*/

.portfolio_3_column {
  width:         31.62393162393162%;
  margin-bottom: 30px;
  display:       inline-block;
  &:nth-of-type(3n+3) {
    margin-right: 0;
  }
  .portfolio_inner_content {
    overflow: hidden;
    border:   1px solid #e6e8eb;
    .column_title_center a {
      color:       #18191a;
      font-size:   16px;
      font-weight: 700;
      line-height: 20px;
      text-align:  center;
      margin-top:  0;
    }
  }
  .portfolio_full_width h6 {
    margin-bottom: -10px;
  }
  .portfolio_3_column_info span {
    color:       #99a1ae;
    font-size:   14px;
    font-style:  italic;
    line-height: 20px;
    text-align:  left;
  }
  .portfolio_inner_content .portfolio_item_meta {
    padding:    9px 0 0;
    text-align: center;
    h6 {
      margin: 0;
    }
  }
  .portfolio_3_column_detail {
    margin-bottom: 10px;
  }
  .portfolio_3_column_info {
    margin-bottom: 0;
  }
}

/*----------- 10.3. Portfolio - 4 Columns --------------*/

.portfolio_4_column {
  width:         23.07692307692308%;
  margin-bottom: 30px;
  display:       inline-block;
  &:nth-of-type(4n+4) {
    margin-right: 0;
  }
  .portfolio_inner_content {
    border: 1px solid #e6e8eb;
    .column_title_center {
      margin-top: 0;
      a {
        color:       #18191a;
        font-size:   16px;
        font-weight: 700;
        line-height: 20px;
        text-align:  center;
      }
    }
    .portfolio_4_column_info span {
      color:       #99a1ae;
      font-size:   14px;
      font-style:  italic;
      line-height: 20px;
      text-align:  center;
    }
    .portfolio_item_meta {
      padding:    9px 0 0;
      text-align: center;
      h6 {
        margin: 0;
      }
    }
  }
  .portfolio_full_width h6 {
    margin-bottom: -10px;
  }
  .portfolio_4_column_detail {
    margin-bottom: 10px;
  }
  .portfolio_4_column_info {
    margin-bottom: 0;
  }
  .portfolio_item .overlayed .item .overlay_title {
    bottom: 30px;
  }
}

.portfolio_4_column_description .portfolio_item .overlayed .item .overlay_title {
  bottom: 30px;
}

/*----------- 10.4. Portfolio - List Full Width --------------*/

.portfolio_list_fullwidth {
  .portfolio_single_column_item {
    margin-bottom: 60px;
    overflow:      hidden;
    border:        1px solid #e6e8eb;
    text-align:    left;
    .portfolio_inner_content {
      overflow: hidden;
    }
    .portfolio_thumb {
      float:       left;
      margin-left: 0;
      width:       60%;
    }
    &:nth-child(2n) .portfolio_thumb {
      float:        right;
      margin-right: 0;
      width:        60%;
    }
    .portfolio_item_meta {
      padding-left: 65.9%;
    }
    &:nth-child(2n) .portfolio_item_meta {
      padding-right: 65.9%;
      padding-left:  60px;
    }
  }
  .portfolio_single_container {
    position: relative;
    overflow: hidden;
  }
  .portfolio_thumb .portfolio_item {
    height: 100%;
  }
  .portfolio_item_meta {
    position: relative;
    padding:  60px;
  }
  h2.portfolio_title {
    margin-bottom: 0;
  }
}

.portfolio_full_width .portfolio_title a {
  font-size:          22px;
  line-height:        28px;
  font-weight:        700;
  text-transform:     uppercase;
  color:              #18191a;
  font-style:         normal;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition:    all 300ms ease-in-out;
  -o-transition:      all 300ms ease-in-out;
  -ms-transition:     all 300ms ease-in-out;
  transition:         all 300ms ease-in-out;
  &:hover {
    color: $color-ci-1;
  }
}

.portfolio_list_fullwidth {
  .portfolio_item_meta_category {
    font-size:      14px;
    line-height:    28px;
    color:          #9fa4af;
    font-weight:    400;
    margin-bottom:  21px;
    padding-bottom: 21px;
    font-style:     italic;
    position:       relative;
    &:after {
      display:     block;
      content:     '';
      width:       83px;
      height:      1px;
      background:  $color-ci-1;
      position:    absolute;
      bottom:      0;
      margin-left: 0;
    }
  }
  .post-readmore.portfolio-readmore {
    margin:  20px 0;
    padding: 0;
    a.more-link {
      background:     $color-white;
      color:          $color-ci-1;
      border:         1px solid #e1e3e6;
      padding:        12px 35px 11px 37px;
      margin-top:     13px;
      font-size:      11px;
      text-transform: uppercase;
      font-weight:    700;
      letter-spacing: 0.1px;
      &:hover {
        background: $color-ci-1;
        color:      $color-white;
      }
    }
  }
  .portfolio_item .overlayed .item .overlay_title {
    bottom: 30px;
  }
}

.portfolio_info {
  margin-bottom: 10px;
}

.page-template-page-portfolio .portfolio_inner_content .item .portfolio_icon, #related_portfolio .portfolio_inner_content .item .portfolio_icon {
  position:           absolute;
  top:                50%;
  left:               50%;
  margin-left:        -30px;
  margin-top:         -30px;
  opacity:            0;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition:    all 300ms ease-in-out;
  -o-transition:      all 300ms ease-in-out;
  -ms-transition:     all 300ms ease-in-out;
  transition:         all 300ms ease-in-out;
}

.page-template-page-portfolio .portfolio_inner_content .item:hover .portfolio_icon, #related_portfolio .portfolio_inner_content .item:hover .portfolio_icon {
  opacity: 1;
}

/*----------- 10.5. Portfolio Single Work --------------*/

#simple_item_portfolio {
  padding-top: 0;
  .portfolio_item_image {
    margin-bottom:  0;
    vertical-align: top;
  }
}

#single_title_navigation {
  background: #f5f7f7;
  border-top: 1px solid #edefee;
  padding:    20px 0;
}

.portfolio_single_title {
  text-align:     center;
  text-transform: uppercase;
  color:          #18191a;
  font-size:      26px;
  font-weight:    700;
  line-height:    29px;
}

#single_title_navigation .list i {
  font-size: 30px;
  position:  relative;
  top:       2px;
}

.portfolio_content {
  padding: 81px 0 0;
}

.single_portfolio_heading {
  color:          #18191a;
  font-size:      22px;
  font-weight:    700;
  padding-bottom: 20px;
  margin-bottom:  20px;
  line-height:    20px;
  text-align:     left;
  text-transform: uppercase;
  position:       relative;
  &:before {
    content:    "";
    position:   absolute;
    bottom:     0;
    left:       0;
    width:      83px;
    height:     1px;
    background: $color-ci-1;
  }
}

.single_portfolio_pagination {
  text-align:  right;
  line-height: 0;
  a {
    position: relative;
    width:    17px;
    height:   35px;
    display:  inline-block;
  }
  .prev {
    margin-right: 10px;
  }
  a {
    &:before {
      content:            "";
      background:         #9fa4af;
      position:           absolute;
      top:                10px;
      left:               0;
      width:              16px;
      height:             2px;
      -webkit-transition: all 300ms ease-in-out;
      -moz-transition:    all 300ms ease-in-out;
      -o-transition:      all 300ms ease-in-out;
      -ms-transition:     all 300ms ease-in-out;
      transition:         all 300ms ease-in-out;
    }
    &:after {
      content:            "";
      background:         #9fa4af;
      position:           absolute;
      top:                10px;
      left:               0;
      width:              16px;
      height:             2px;
      -webkit-transition: all 300ms ease-in-out;
      -moz-transition:    all 300ms ease-in-out;
      -o-transition:      all 300ms ease-in-out;
      -ms-transition:     all 300ms ease-in-out;
      transition:         all 300ms ease-in-out;
      top:                22px;
    }
  }
  .next a {
    &:before {
      -webkit-transform: rotate(50deg);
      -moz-transform:    rotate(50deg);
      -ms-transform:     rotate(50deg);
      -o-transform:      rotate(50deg);
      transform:         rotate(50deg);
    }
    &:after {
      -webkit-transform: rotate(130deg);
      -moz-transform:    rotate(130deg);
      -ms-transform:     rotate(130deg);
      -o-transform:      rotate(130deg);
      transform:         rotate(130deg);
    }
  }
  .prev a {
    &:before {
      -webkit-transform: rotate(-50deg);
      -moz-transform:    rotate(-50deg);
      -ms-transform:     rotate(-50deg);
      -o-transform:      rotate(-50deg);
      transform:         rotate(-50deg);
    }
    &:after {
      -webkit-transform: rotate(-130deg);
      -moz-transform:    rotate(-130deg);
      -ms-transform:     rotate(-130deg);
      -o-transform:      rotate(-130deg);
      transform:         rotate(-130deg);
    }
  }
  a:hover {
    &:before, &:after {
      background: $color-ci-1;
    }
  }
}

/*----------- 10.6. Portfolio - List Left and Right Sidebar --------------*/

.content_with_left_sidebar .portfolio_list_fullwidth {
  .portfolio_item_meta {
    position: relative;
    padding:  60px 60px 60px 540px;
  }
  .portfolio_single_column_item .portfolio_inner_content {
    overflow: hidden;
  }
}

.content_with_right_sidebar .portfolio_list_fullwidth .portfolio_single_column_item .portfolio_inner_content {
  overflow: hidden;
}

.content_with_left_sidebar .portfolio_list_fullwidth .portfolio_single_column_item:nth-child(2n) .portfolio_item_meta {
  position: relative;
  padding:  60px 540px 60px 60px;
}

.content_with_right_sidebar .portfolio_list_fullwidth {
  .portfolio_item_meta {
    position: relative;
    padding:  60px 50px 60px 540px;
  }
  .portfolio_single_column_item:nth-child(2n) .portfolio_item_meta {
    position: relative;
    padding:  60px 540px 60px 60px;
  }
}

.more_portfolio_link {
  margin:             67px 0 30px 0;
  text-align:         center;
  display:            block;
  -webkit-transition: all 180ms ease-in;
  -moz-transition:    all 180ms ease-in;
  -o-transition:      all 180ms ease-in;
  transition:         all 180ms ease-in;
}

.load_more_portfolio, .more_portfolio_link a {
  background:          transparent;
  border:              1px solid #dee0e3;
  color:               $color-ci-1;
  text-transform:      uppercase;
  font-size:           14px;
  line-height:         22px;
  font-weight:         700;
  padding:             14px 27px 15px 27px;
  display:             inline-block;
  text-align:          center;
  cursor:              pointer;
  -webkit-user-select: none;
  -moz-user-select:    none;
  -ms-user-select:     none;
  user-select:         none;
  -webkit-transition:  all 180ms ease-in;
  -moz-transition:     all 180ms ease-in;
  -o-transition:       all 180ms ease-in;
  transition:          all 180ms ease-in;
}

.load_more_portfolio {
  &:hover {
    background:   $color-ci-1;
    color:        $color-white;
    border-color: $color-ci-1;
  }
  &.no_more_portfolio_posts {
    cursor: default !important;
  }
}

.loader {
  &:before, &:after {
    border-radius:               50%;
    width:                       8px;
    height:                      8px;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode:    both;
    animation-fill-mode:         both;
    -webkit-animation:           load7 1.8s infinite ease-in-out;
    -moz-animation:              load7 1.8s infinite ease-in-out;
    animation:                   load7 1.8s infinite ease-in-out;
  }
  border-radius:               50%;
  width:                       8px;
  height:                      8px;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode:    both;
  animation-fill-mode:         both;
  -webkit-animation:           load7 1.8s infinite ease-in-out;
  -moz-animation:              load7 1.8s infinite ease-in-out;
  animation:                   load7 1.8s infinite ease-in-out;
  font-size:                   10px;
  margin:                      0 0 0 20px;
  position:                    relative;
  text-indent:                 -9999em;
  top:                         -10px;
  display:                     inline-block;
  -webkit-transform:           translateZ(0);
  -moz-transform:              translateZ(0);
  -ms-transform:               translateZ(0);
  -o-transform:                translateZ(0);
  transform:                   translateZ(0);
  -webkit-animation-delay:     -0.16s;
  -moz-animation-delay:        -0.16s;
  animation-delay:             -0.16s;
  &:before {
    left:                    -10px;
    -webkit-animation-delay: -0.32s;
    -moz-animation-delay:    -0.32s;
    animation-delay:         -0.32s;
  }
  &:after {
    left: 10px;
  }
  &:before, &:after {
    content:  '';
    position: absolute;
    top:      0;
  }
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 10px 0 -5px $color-ci-1;
  }

  40% {
    box-shadow: 0 10px 0 0 $color-ci-1;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 10px 0 -5px $color-ci-1;
  }

  40% {
    box-shadow: 0 10px 0 0 $color-ci-1;
  }
}

/*Portfolio gallery*/

/*** Gallery ***/

.gallery_images {
  .gallery-item {
    margin:     3.333333% 3.3333333% 0 0;
    width:      30% !important;
    background: #f7f7f7;
    position:   relative;
    display:    inline-block;
    &:nth-child(3n+3) {
      margin-right: 0;
    }
  }
  img {
    width:  80px;
    height: 80px;
    border: 1px solid #d3d7da !important;
  }
}

#manage_gallery {
  margin-top: 10px;
  cursor:     pointer;
}

#empty_gallery {
  cursor:      pointer;
  margin-left: 10px;
}

#icon-edit.icon32-posts-listings {
  display: none;
}

.gallery-item a {
  display: inline-block;
}
