@import "../includes/colors";

/*-----------------------------------------------------------------*/
/* 14. Footer
/*-----------------------------------------------------------------*/

#ABdev_main_footer {
  color:    #939fa6;
  position: relative;
  h3 {
    color:       $color-white;
    font-size:   16px;
    font-weight: 400;
    line-height: 22px;
    text-align:  left;
  }
  a {
    color:       #83888f;
    font-size:   16px;
    font-weight: 400;
    line-height: 22px;
    text-align:  left;
    &:hover {
      color: $color-ci-1;
    }
  }
  .contact-info a {
    color: #83888f;
    &:hover {
      color: $color-ci-1;
    }
  }
}

#footer_columns {
  padding:    73px 0 38px 0;
  background: $color-grey-dark;
  color:      $color-white;
}

#footer_copyright {
  background:  $color-grey-darker;
  color:       #5d6166;
  font-size:   13px;
  line-height: 30px;
  position:    relative;
}

.footer_social {
  text-align: right;
  a {
    display:               inline-block;
    background:            #242e35;
    width:                 32px;
    height:                32px;
    line-height:           32px;
    text-align:            center;
    -webkit-border-radius: 50%;
    -moz-border-radius:    50%;
    border-radius:         50%;
  }
  i {
    color:     #939fa6;
    font-size: 16px;
  }
  a:hover i {
    color: $color-white;
  }
}

#footer_menu_inner {
  position: absolute;
  right:    0;
}

#footer_onepage_container {
  background: #181a1d;
  min-height: 210px;
  position:   relative;
  .footer_onepage_copyright {
    text-align:  center;
    padding-top: 62px;
  }
  #back_to_top {
    width:              48px;
    height:             48px;
    border-radius:      50%;
    background:         #26292e;
    position:           absolute;
    left:               50%;
    top:                -24px;
    margin-left:        -24px;
    font-size:          20px;
    line-height:        48px;
    z-index:            1;
    text-align:         center;
    transition:         all 180ms ease-in;
    -webkit-transition: all 180ms ease-in;
    -moz-transition:    all 180ms ease-in;
    -ms-transition:     all 180ms ease-in;
    -o-transition:      all 180ms ease-in;
    i {
      display: inline-block;
    }
    &:hover i {
      color:              $color-ci-1;
      transition:         all 180ms ease-in;
      -webkit-transition: all 180ms ease-in;
      -moz-transition:    all 180ms ease-in;
      -ms-transition:     all 180ms ease-in;
      -o-transition:      all 180ms ease-in;
    }
  }
  #footer_onepage_social {
    text-align: center;
    margin-top: 20px;
    a {
      width:              36px;
      height:             36px;
      border-radius:      2px;
      margin-right:       10px;
      background:         #26292e;
      text-align:         center;
      display:            inline-block;
      font-size:          16px;
      line-height:        38px;
      -webkit-transition: all 180ms ease-in;
      -moz-transition:    all 180ms ease-in;
      -ms-transition:     all 180ms ease-in;
      -o-transition:      all 180ms ease-in;
      transition:         all 180ms ease-in;
      margin-bottom:      10px;
      i {
        color:              #9fa4af;
        display:            inline-block;
        -webkit-transition: all 180ms ease-in;
        -moz-transition:    all 180ms ease-in;
        -ms-transition:     all 180ms ease-in;
        -o-transition:      all 180ms ease-in;
        transition:         all 180ms ease-in;
      }
      &:hover i {
        -webkit-transition: all 180ms ease-in;
        -moz-transition:    all 180ms ease-in;
        -ms-transition:     all 180ms ease-in;
        -o-transition:      all 180ms ease-in;
        transition:         all 180ms ease-in;
      }
    }
  }
}

#footer_landing_container {
  background: #181a1d;
  min-height: 150px;
  position:   relative;
  .footer_landing_copyright {
    text-align:  center;
    padding-top: 62px;
  }
  .container {
    position: relative;
  }
  #back_to_top {
    width:         48px;
    height:        48px;
    border-radius: 50%;
    background:    #26292e;
    position:      absolute;
    right:         0;
    top:           -23px;
    margin-left:   -24px;
    font-size:     20px;
    line-height:   48px;
    z-index:       1;
    text-align:    center;
    i {
      display:            inline-block;
      transition:         all 180ms ease-in;
      -webkit-transition: all 180ms ease-in;
      -moz-transition:    all 180ms ease-in;
      -ms-transition:     all 180ms ease-in;
      -o-transition:      all 180ms ease-in;
    }
    &:hover i {
      display:            inline-block;
      color:              $color-ci-1;
      transition:         all 180ms ease-in;
      -webkit-transition: all 180ms ease-in;
      -moz-transition:    all 180ms ease-in;
      -ms-transition:     all 180ms ease-in;
      -o-transition:      all 180ms ease-in;
    }
  }
}

#back_to_top {
  width:              25px;
  height:             25px;
  position:           absolute;
  top:                12px;
  right:              28px;
  margin-left:        -12px;
  z-index:            1;
  text-align:         center;
  transition:         all 180ms ease-in;
  -webkit-transition: all 180ms ease-in;
  -moz-transition:    all 180ms ease-in;
  -ms-transition:     all 180ms ease-in;
  -o-transition:      all 180ms ease-in;
}

.shop_footer #back_to_top:before {
  display:           block;
  content:           "";
  width:             15px;
  height:            15px;
  border-left:       1px solid $color-ci-1;
  border-top:        1px solid $color-ci-1;
  -ms-transform:     rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform:         rotate(45deg);
  position:          absolute;
  left:              50%;
  top:               62%;
  margin-left:       -9px;
}

#back_to_top {
  &:before {
    display:            block;
    content:            "";
    width:              15px;
    height:             15px;
    border-left:        1px solid $color-white;
    border-top:         1px solid $color-white;
    -webkit-transform:  rotate(45deg);
    -moz-transform:     rotate(45deg);
    -ms-transform:      rotate(45deg);
    -o-transform:       rotate(45deg);
    transform:          rotate(45deg);
    -webkit-transition: all 180ms ease-in;
    -moz-transition:    all 180ms ease-in;
    -ms-transition:     all 180ms ease-in;
    -o-transition:      all 180ms ease-in;
    position:           absolute;
    left:               50%;
    top:                13px;
    margin-left:        -9px;
  }
  &:hover:before {
    border-color: $color-ci-1;
  }
}

#footer_container {
  padding:  10px 0;
  position: relative;
}

#footer_social {
  text-align: right;
  a {
    display:            inline-block;
    margin:             0 20px 0 0;
    text-align:         center;
    transition:         all 180ms ease-in;
    -webkit-transition: all 180ms ease-in;
    -moz-transition:    all 180ms ease-in;
    -ms-transition:     all 180ms ease-in;
    -o-transition:      all 180ms ease-in;
    &:last-of-type {
      margin-right: 0;
    }
    i {
      font-size:          16px;
      line-height:        30px;
      font-weight:        400;
      color:              #5d6166;
      transition:         all 180ms ease-in;
      -webkit-transition: all 180ms ease-in;
      -moz-transition:    all 180ms ease-in;
      -ms-transition:     all 180ms ease-in;
      -o-transition:      all 180ms ease-in;
    }
  }
}

.footer_social_span_1 {
  width: 100%;
}

.footer_social_span_2 {
  width: 50%;
}

.footer_social_span_3 {
  width: 33.333333%;
}

.footer_social_span_4 {
  width: 25%;
}

.footer_social_span_5 {
  width: 20%;
}

.footer_social_span_6 {
  width: 16.666667%;
}

.footer_social_span_7 {
  width: 14.285714%;
}

.footer_social_span_8 {
  width: 12.5%;
}

.footer_social_span_9 {
  width: 11.111111%;
}

.footer_social_span_10 {
  width: 10%;
}

#footer_social {
  &.footer_layout_landing a {
    display:            inline-block;
    margin:             0;
    padding:            27px 0;
    text-align:         center;
    transition:         all 180ms ease-in;
    -webkit-transition: all 180ms ease-in;
    -moz-transition:    all 180ms ease-in;
    -ms-transition:     all 180ms ease-in;
    -o-transition:      all 180ms ease-in;
    i {
      color:     #878e99;
      font-size: 21px;
      display:   inline-block;
    }
    &:hover {
      background: $color-ci-1;
    }
  }
  a:hover i {
    color: #878e99;
  }
  .tcvpb_socialicon_facebook:hover {
    background: #3b5998;
  }
  .tcvpb_socialicon_envelope:hover {
    background: $color-ci-1;
  }
  .tcvpb_socialicon_twitter:hover {
    background: #4099ff;
  }
  .tcvpb_socialicon_googleplus:hover {
    background: #d34836;
  }
  .tcvpb_socialicon_linkedin:hover {
    background: #0e76a8;
  }
  .tcvpb_socialicon_youtube:hover {
    background: #c4302b;
  }
  .tcvpb_socialicon_pinterest:hover {
    background: #c8232c;
  }
  .tcvpb_socialicon_github:hover {
    background: #171515;
  }
  .tcvpb_socialicon_rss:hover {
    background: #ee802f;
  }
  .tcvpb_socialicon_behance:hover {
    background: #1769ff;
  }
  .tcvpb_socialicon_blogger:hover {
    background: #f57d00;
  }
  .tcvpb_socialicon_delicious:hover {
    background: #39f;
  }
  .tcvpb_socialicon_designcontest:hover {
    background: #5faad2;
  }
  .tcvpb_socialicon_deviantart:hover {
    background: #4e6252;
  }
  .tcvpb_socialicon_digg:hover {
    background: #000;
  }
  .tcvpb_socialicon_dribbble:hover {
    background: #ea4c89;
  }
  .tcvpb_socialicon_dropbox:hover {
    background: #007ee5;
  }
  .tcvpb_socialicon_email:hover {
    background: #ed1c16;
  }
  .tcvpb_socialicon_flickr:hover {
    background: #ff0084;
  }
  .tcvpb_socialicon_forrst:hover {
    background: #5b9a68;
  }
  .tcvpb_socialicon_instagram:hover {
    background: #3f729b;
  }
  .tcvpb_socialicon_lastfm:hover {
    background: #c3000d;
  }
  .tcvpb_socialicon_myspace:hover {
    background: #313131;
  }
  .tcvpb_socialicon_picasa:hover {
    background: #9a45a0;
  }
  .tcvpb_socialicon_skype:hover {
    background: #00aff0;
  }
  .tcvpb_socialicon_stumbleupon:hover {
    background: #f74425;
  }
  .tcvpb_socialicon_vimeo:hover {
    background: #4bf;
  }
  .tcvpb_socialicon_zerply:hover {
    background: #9dcc7a;
  }
  &.footer_layout_landing a:hover {
    .ci_icon-facebook, .ci_icon-twitter, .ci_icon-googleplus, .ci_icon-envelope, .ci_icon-linkedin, .ci_icon-youtube, .ci_icon-pinterest, .ci_icon-github, .ci_icon-rss, .ci_icon-behance, .ci_icon-blogger, .ci_icon-delicious, .ci_icon-designcontest, .ci_icon-deviantart, .ci_icon-digg, .ci_icon-dribbble, .ci_icon-dropbox, .ci_icon-flickr, .ci_icon-forrst, .ci_icon-instagram, .ci_icon-lastfm, .ci_icon-myspace, .ci_icon-picassa, .ci_icon-skype, .ci_icon-stumbleupon, .ci_icon-vimeo, .ci_icon-zerply {
      color: $color-white;
    }
  }
  a:hover {
    .ci_icon-facebook {
      color: #3b5998;
    }
    .ci_icon-twitter {
      color: #4099ff;
    }
    .ci_icon-googleplus {
      color: #d34836;
    }
    .ci_icon-envelope {
      color: $color-white;
    }
    .ci_icon-linkedin {
      color: #0e76a8;
    }
    .ci_icon-youtube {
      color: #c4302b;
    }
    .ci_icon-pinterest {
      color: #c8232c;
    }
    .ci_icon-github {
      color: #171515;
    }
    .ci_icon-rss {
      color: #ee802f;
    }
    .ci_icon-behance {
      color: #1769ff;
    }
    .ci_icon-blogger {
      color: #f57d00;
    }
    .ci_icon-delicious {
      color: #39f;
    }
    .ci_icon-designcontest {
      color: #5faad2;
    }
    .ci_icon-deviantart {
      color: #4e6252;
    }
    .ci_icon-digg {
      color: #000;
    }
    .ci_icon-dribbble {
      color: #ea4c89;
    }
    .ci_icon-dropbox {
      color: #007ee5;
    }
    .ci_icon-envelope {
      color: #ed1c16;
    }
    .ci_icon-flickr {
      color: #ff0084;
    }
    .ci_icon-forrst {
      color: #5b9a68;
    }
    .ci_icon-instagram {
      color: #3f729b;
    }
    .ci_icon-lastfm {
      color: #c3000d;
    }
    .ci_icon-myspace {
      color: #313131;
    }
    .ci_icon-picassa {
      color: #9a45a0;
    }
    .ci_icon-skype {
      color: #00aff0;
    }
    .ci_icon-stumbleupon {
      color: #f74425;
    }
    .ci_icon-vimeo {
      color: #4bf;
    }
    .ci_icon-zerply {
      color: #9dcc7a;
    }
  }
}

/*-------- 14.1. Shop Footer --------*/

#ABdev_main_footer.shop_footer {
  #footer_columns {
    background: #f5f7f7;
  }
  h3 {
    color:         #18191a;
    font-size:     16px;
    font-weight:   700;
    line-height:   20px;
    text-align:    left;
    margin-bottom: 20px;
  }
  .shop-widget-heading {
    border-bottom:  1px solid #e1e3e6;
    padding-bottom: 14px;
  }
  .textwidget {
    color:       #9ca3ae;
    font-size:   13px;
    font-weight: 400;
    line-height: 22px;
    text-align:  left;
  }
  .tcvpb_follow_us {
    a {
      border:         1px solid #e1e3e6;
      background:     #f5f7f7;
      font-size:      16px;
      line-height:    30px;
      color:          #aaa;
      margin:         0 9px 9px 0;
      text-align:     center;
      padding-bottom: 0;
    }
    margin-top: 27px;
  }
  .widget {
    a {
      color:       #9ca3ae;
      font-size:   13px;
      font-weight: 400;
      line-height: 31px;
      text-align:  center;
    }
    ul {
      text-transform: none;
      li {
        border: none;
        &:after, &:before {
          display: none;
        }
      }
    }
  }
}

.shop_footer .ABss_form_wrapper input {
  width:         136px;
  background:    $color-white;
  border:        1px solid #e6e8eb;
  padding:       7px 57px 9px 11px;
  color:         #99a1ae;
  font-size:     14px;
  font-style:    italic;
  line-height:   22px;
  text-align:    left;
  display:       inline-block;
  margin-top:    6px;
  margin-bottom: -10px;
  &[type="submit"] {
    margin:             0;
    padding:            9px 0 11px;
    width:              100%;
    cursor:             pointer;
    -webkit-transition: background 180ms ease-in, color 180ms ease-in, border-color 180ms ease-in;
    -moz-transition:    background 180ms ease-in, color 180ms ease-in, border-color 180ms ease-in;
    -ms-transition:     background 180ms ease-in, color 180ms ease-in, border-color 180ms ease-in;
    -o-transition:      background 180ms ease-in, color 180ms ease-in, border-color 180ms ease-in;
    transition:         background 180ms ease-in, color 180ms ease-in, border-color 180ms ease-in;
    display:            inline-block;
    background:         $color-ci-1;
    color:              $color-white;
  }
}

#ABdev_main_footer.shop_footer {
  .textwidget p {
    margin-bottom: 18px;
  }
  h4 {
    color:         #18191a;
    font-size:     18px;
    font-weight:   400;
    line-height:   20px;
    text-align:    left;
    margin-bottom: 9px;
  }
  .contact_info_widget {
    color:       #959ca6;
    font-size:   16px;
    font-weight: 400;
    line-height: 20px;
    text-align:  left;
    p {
      margin-bottom: 3px;
    }
  }
}

.widget_text .ABss_subscriber_widget_submit {
  background:  $color-ci-1;
  display:     inline-block;
  width:       44px;
  height:      40px;
  line-height: 42px;
  text-align:  center;
  position:    absolute;
  top:         97px;
  right:       386px;
  i {
    color:    $color-white;
    position: relative;
    top:      6px;
  }
}

.shop_footer {
  #footer_columns {
    margin-bottom: 0;
  }
  .footer_copyright {
    color:       #aab2bd;
    font-size:   14px;
    font-weight: 400;
    margin-left: -10px;
    padding:     17px 0 14px 0;
  }
  #footer_copyright {
    background: $color-white;
    position:   relative;
  }
}

.footer_payment {
  margin-right:  0;
  text-align:    right;
  margin-top:    10px;
  margin-bottom: 0;
  line-height:   40px;
}

.shop_footer #back_to_top i {
  display:            inline-block;
  font-size:          26px;
  transition:         all 180ms ease-in;
  -webkit-transition: all 180ms ease-in;
  -moz-transition:    all 180ms ease-in;
  -ms-transition:     all 180ms ease-in;
  -o-transition:      all 180ms ease-in;
  color:              $color-ci-1 !important;
  position:           absolute;
  right:              0;
}

/*-------- 14.2. Footer Menu --------*/

#footer_copyright #footer_menu ul li {
  display:     inline-block;
  margin-left: 10px;
}

#footer_container .copyright {
  color:       #5d6166;
  font-size:   14px;
  font-weight: 400;
  line-height: 20px;
  text-align:  left;
  margin:      8px 5px 8px 0;
}

#footer_copyright #footer_menu ul li {
  a {
    color:         #5d6166;
    font-size:     14px;
    font-weight:   400;
    line-height:   20px;
    text-align:    right;
    margin-top:    18px;
    margin-bottom: 19px;
    &:hover {
      color: $color-ci-1;
    }
  }
  &.current-menu-item a {
    color: $color-white;
  }
}

.boxed {
  padding:       27px 30px 30px 30px;
  border:        1px solid #eee;
  border-radius: 2px;
  text-align:    center;
}

.boxed_title {
  margin-bottom: 16px;
}

.boxed_title_2 {
  margin-top:    41px;
  margin-bottom: 28px;
}

.boxed_button {
  margin-top: 16px;
}

/*-----------------------------------------------------------------*/
/* 15. Responsive
/*-----------------------------------------------------------------*/

.hidden-desktab, .hidden-desktop {
  display: none !important;
}

.hidden-tablet, .hidden-phablet, .hidden-phone {
  display: inherit !important;
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
  .hidden-desktop {
    display: inherit !important;
  }
  .hidden-desktab, .hidden-tablet {
    display: none !important;
  }
  .hidden-phablet, .hidden-phone {
    display: inherit !important;
  }
  .tcvpb-callout_box {
    margin: 0;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-desktop, .hidden-desktab, .hidden-tablet {
    display: inherit !important;
  }
  .hidden-phablet {
    display: none !important;
  }
  .hidden-phone {
    display: inherit !important;
  }
  .tcvpb-callout_box {
    margin: 0;
  }
}

@media only screen and (max-width: 479px) {
  .hidden-desktop, .hidden-desktab, .hidden-tablet, .hidden-phablet {
    display: inherit !important;
  }
  .hidden-phone {
    display: none !important;
  }
  .tcvpb-callout_box {
    margin: 0;
  }
}

@media only screen and (min-width: 980px) and (max-width: 1190px) {
  .container, .tcvpb_container, .media_container, .boxed_body_wrapper {
    width: 960px;
  }
  .sf-mega {
    width: 960px;
    right: -195px;
  }
  .spacer_responsive_hide_tablet {
    display: none !important;
  }
  .tcvpb_metro_box .back h3 {
    padding-top: 15px;
  }
  .portfolio_item .overlayed .overlay .overlay_title {
    margin: 5% 0 7px;
  }
  .tcvpb-callout_box_style_3 {
    padding-left:  50px;
    padding-right: 50px;
  }
  .portfolio_2_column {
    width: 48.31794871794871%;
  }
  .portfolio_3_column {
    width: 31.22393162393162%;
  }
  .portfolio_4_column {
    width: 22.57692307692308%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 979px) {
  .container, .tcvpb_container, .media_container, .boxed_body_wrapper {
    width: 90%;
  }
  .tcvpb_section_tc header p {
    padding-left:  50px;
    padding-right: 50px;
  }
  .spacer_responsive_hide_tablet, #ABdev_header_spacer {
    display: none !important;
  }
  #ABdev_main_header.header_layout_default {
    .first_menu, .second_menu {
      min-height:    0 !important;
      margin-bottom: 0;
    }
    .span2 {
      width:       100%;
      margin:      0;
      line-height: 0;
    }
  }
  #logo, #ABdev_main_header.header_layout_right #logo {
    display:     inline-block;
    padding-top: 0;
  }
  #inversed_logo {
    display: none;
  }
  .admin-bar #ABdev_main_header nav {
    top: 46px;
  }
  .header_layout_default .header_sidebar_toggle, .regular_header .header_sidebar_toggle {
    display: block !important;
    padding: 10px;
    top:     16px;
  }
  .admin-bar {
    .header_layout_default .header_sidebar_toggle, .regular_header .header_sidebar_toggle {
      top: 48px;
    }
  }
  #headline_breadcrumbs_bar {
    margin-top: 57px;
  }
  #ABdev_main_header {
    min-height: 57px;
    nav {
      position:   fixed;
      top:        0;
      left:       0;
      height:     100%;
      width:      40%;
      background: $color-white;
      z-index:    100000;
      overflow-y: scroll;
      overflow-x: hidden;
      display:    none;
    }
  }
  nav {
    float:      none !important;
    position:   fixed;
    top:        0;
    left:       0;
    height:     100%;
    background: #232425;
    z-index:    100000;
    overflow-x: hidden;
    display:    none;
    box-shadow: none;
    > ul {
      padding-left: 30px;
      padding-top:  10px;
      width:        100%;
      float:        left;
      position:     relative;
      border-right: 1px solid #e8eaec;
      &#main_menu_right {
        padding-top: 0;
      }
      > li {
        display:    block;
        float:      none;
        text-align: left;
        margin:     0;
        &:first-child {
          margin-left: 0;
        }
        > a {
          display:        block;
          width:          100%;
          text-align:     left;
          margin-left:    0;
          padding-bottom: 0;
          font-size:      12px;
        }
      }
    }
  }
  #ABdev_main_header.transparent nav > ul > li a {
    display:        block;
    width:          100%;
    text-align:     left;
    margin-left:    0;
    padding-bottom: 0;
    font-size:      12px;
  }
  nav > ul > li > a > i {
    display: none;
  }
  #ABdev_main_header {
    nav > ul ul, &.transparent nav > ul ul {
      display:    block;
      background: $color-white;
      position:   static;
      border:     none;
      margin:     0;
      padding:    0 0 0 15px;
      box-shadow: none;
    }
  }
  nav > ul {
    > li > ul:before {
      display: none;
    }
    ul li {
      position:      static;
      padding:       0;
      border-bottom: 0;
      line-height:   28px;
      &.menu-item-has-children > a:before {
        display: none;
      }
      a {
        width:       100%;
        text-align:  left;
        padding:     inherit;
        padding-top: 5px;
      }
    }
  }
  #ABdev_main_header.transparent nav > ul > li a {
    width:       100%;
    text-align:  left;
    padding:     inherit;
    padding-top: 5px;
  }
  nav > ul ul li:hover, #ABdev_main_header.transparent nav > ul ul li:hover {
    background: none;
  }
  nav > ul ul {
    li:hover > a {
      color: $color-ci-1 !important;
    }
    ul {
      position:    static;
      margin-top:  0;
      z-index:     auto;
      margin-left: 20px;
      &:before {
        display: none;
      }
    }
  }
  .sf-arrows .sf-with-ul:after {
    display: none;
  }
  nav > ul > li {
    &.menu-item-has-children {
      &:hover > a:before, > a:after {
        display: none;
      }
    }
    &:hover {
      color: #3d4042;
    }
  }
  .full_background nav > ul > li:hover {
    color: #3d4042;
  }
  /*-------- Mega Menu --------*/
  .sf-mega, #ABdev_main_header.transparent .sf-mega {
    width:       100%;
    position:    static;
    display:     block;
    z-index:     inherit;
    padding-top: 0;
    box-shadow:  none;
    border:      none !important;
  }
  .megamenu_header a span {
    margin-top: 0;
    color:      #848998;
    font-size:  13px;
  }
  .sf-mega-inner, #ABdev_main_header.transparent .sf-mega-inner {
    border:     none;
    background: none;
  }
  .full_background nav > ul .sf-mega-inner > ul > li > a {
    color: #3d4042;
  }
  nav > ul .sf-mega-inner {
    display: block !important;
  }
  #ABdev_main_header.transparent {
    .sf-mega-inner, nav > ul .sf-mega-inner > ul > li {
      display: block !important;
    }
  }
  nav > ul .sf-mega-inner > ul {
    display: block !important;
    > li {
      border-right: none;
      padding:      0 0 0 0;
      display:      block !important;
      > {
        ul > li {
          border-right: none;
          padding:      0 0 0 0;
          display:      block !important;
        }
        a {
          font-size:      12px;
          text-transform: none;
          margin-bottom:  0;
          padding:        5px 0 0 0;
          margin-top:     0;
          color:          #6b6b6b;
        }
      }
    }
  }
  #ABdev_main_header.transparent nav > ul > li a {
    font-size:      12px;
    text-transform: none;
    margin-bottom:  0;
    padding:        5px 0 0 0;
    margin-top:     0;
    color:          #6b6b6b;
  }
  nav > ul {
    .sf-mega-inner {
      > ul > li > ul > li a {
        font-size: 12px;
        padding:   5px 0 0 0;
      }
      a {
        text-transform: none;
        padding:        inherit;
        margin-bottom:  0;
        &:hover {
          background: none;
        }
      }
      .description_menu_item {
        display: none !important;
      }
    }
    > {
      .mega1 .sf-mega-inner > ul > li, .mega2 .sf-mega-inner > ul > li, .mega3 .sf-mega-inner > ul > li, .mega4 .sf-mega-inner > ul > li, .mega5 .sf-mega-inner > ul > li, .mega6 .sf-mega-inner > ul > li, .mega7 .sf-mega-inner > ul > li {
        width:      100%;
        background: none;
      }
    }
    .use_desc {
      display: none !important;
    }
  }
  .tcvpb-callout_box_style_4 {
    .tcvpb-callout_box_title {
      margin-bottom: 25px;
    }
    .tcvpb-button1 {
      float: none;
    }
  }
  .flickr_stream {
    img {
      width:  52px;
      height: 52px;
    }
    a:nth-child(3n+0) {
      margin-right: 9px;
    }
  }
  #ABdev_main_footer .contact-info a {
    word-break: break-all;
  }
  .portfolio_item .overlayed .overlay .overlay_title {
    margin: 2% 0 7px;
  }
  .portfolio_icon {
    margin-top: 5px;
  }
  .tcvpb-callout_box_style_3 {
    .tcvpb-button1 {
      float: none;
    }
    .tcvpb-callout_box_title {
      margin-bottom: 50px;
    }
  }
  .portfolio_item_2 {
    width: 47%;
  }
  .portfolio_item_3 {
    width: 30%;
  }
  .portfolio_item_4 {
    width: 21.5%;
  }
  .portfolio_list_fullwidth .portfolio_item_meta {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .container, .tcvpb_container, .boxed_body_wrapper {
    width: 95%;
  }
  .tcvpb_container [class*="column_tc_span"] {
    margin-left: 0;
    width:       100%;
  }
  .section_no_column_margin.section_equalize_5 {
    .tcvpb_column_tc_span3, .tcvpb_column_tc_span2 {
      margin-left: 0;
      width:       100%;
    }
  }
  .title_with_after, p {
    padding: 0 8px;
  }
  .row > [class*="span"] {
    width:         100%;
    margin-bottom: 20px;
    margin-left:   0;
  }
  .tcvpb_section_tc header p {
    padding-left:  10px;
    padding-right: 10px;
  }
  .header_layout_default .header_sidebar_toggle, .regular_header .header_sidebar_toggle {
    display: block !important;
    padding: 10px;
    top:     16px;
    right:   20px;
  }
  .admin-bar {
    .header_layout_default .header_sidebar_toggle, .regular_header .header_sidebar_toggle {
      top:   56px;
      right: 20px;
    }
  }
  #ABdev_header_spacer {
    display: none !important;
  }
  #ABdev_main_header.header_layout_default {
    .first_menu, .second_menu {
      min-height:    0 !important;
      margin-bottom: 0;
    }
    .span2 {
      width:       100%;
      margin:      0;
      line-height: 0;
    }
  }
  #logo, #ABdev_main_header.header_layout_right #logo {
    display:     inline-block;
    padding-top: 0;
  }
  #inversed_logo {
    display: none;
  }
  .admin-bar #ABdev_main_header nav {
    top: 46px;
  }
  #headline_breadcrumbs_bar {
    margin-top: 57px;
  }
  #ABdev_main_header {
    min-height: 57px;
    nav {
      position:   fixed;
      top:        0;
      left:       0;
      height:     100%;
      width:      80%;
      background: $color-white;
      z-index:    100000;
      overflow-y: scroll;
      overflow-x: hidden;
      display:    none;
    }
    &.header_layout_right nav {
      position:   inherit;
      height:     100%;
      width:      100%;
      background: $color-white;
      z-index:    100000;
      overflow:   hidden;
      display:    none;
      > ul {
        > li > a > i {
          display: block;
        }
        border-right: 0;
      }
    }
  }
  nav {
    float:      none !important;
    position:   fixed;
    top:        0;
    left:       0;
    height:     100%;
    background: #232425;
    z-index:    100000;
    overflow-x: hidden;
    display:    none;
    box-shadow: none;
    > ul {
      padding-left: 30px;
      padding-top:  10px;
      width:        100%;
      float:        left;
      position:     relative;
      border-right: 1px solid #e8eaec;
      &#main_menu_right {
        padding-top: 0;
      }
      > li {
        display:    block;
        float:      none;
        text-align: left;
        margin:     0;
        &:first-child {
          margin-left: 0;
        }
        > a {
          display:        block;
          width:          100%;
          text-align:     left;
          margin-left:    0;
          padding-bottom: 0;
          font-size:      12px;
        }
      }
    }
  }
  #ABdev_main_header.transparent nav > ul > li a {
    display:        block;
    width:          100%;
    text-align:     left;
    margin-left:    0;
    padding-bottom: 0;
    font-size:      12px;
  }
  nav > ul > li > a > i {
    display: none;
  }
  #ABdev_main_header {
    nav > ul ul, &.transparent nav > ul ul {
      display:    block;
      background: $color-white;
      position:   static;
      border:     none;
      margin:     0;
      padding:    0 0 0 15px;
      box-shadow: none;
    }
  }
  nav > ul {
    > li > ul:before {
      display: none;
    }
    ul li {
      position:      static;
      padding:       0;
      border-bottom: 0;
      line-height:   28px;
      &.menu-item-has-children > a:before {
        display: none;
      }
      a {
        width:       100%;
        text-align:  left;
        padding:     inherit;
        padding-top: 5px;
      }
    }
  }
  #ABdev_main_header.transparent nav > ul > li a {
    width:       100%;
    text-align:  left;
    padding:     inherit;
    padding-top: 5px;
  }
  nav > ul ul li:hover, #ABdev_main_header.transparent nav > ul ul li:hover {
    background: none;
  }
  nav > ul ul {
    li:hover > a {
      color: $color-ci-1 !important;
    }
    ul {
      position:    static;
      margin-top:  0;
      z-index:     auto;
      margin-left: 20px;
      &:before {
        display: none;
      }
    }
  }
  .sf-arrows .sf-with-ul:after {
    display: none;
  }
  nav > ul > li {
    &.menu-item-has-children {
      &:hover > a:before, > a:after {
        display: none;
      }
    }
    &:hover {
      color: #3d4042;
    }
  }
  .full_background nav > ul > li:hover {
    color: #3d4042;
  }
  /*-------- Mega Menu --------*/
  .sf-mega, #ABdev_main_header.transparent .sf-mega {
    width:       100%;
    position:    static;
    display:     block;
    z-index:     inherit;
    padding-top: 0;
    box-shadow:  none;
    border:      none !important;
  }
  .megamenu_header a span {
    margin-top: 0;
    color:      #848998;
    font-size:  13px;
  }
  .sf-mega-inner, #ABdev_main_header.transparent .sf-mega-inner {
    border:     none;
    background: none;
  }
  .full_background nav > ul .sf-mega-inner > ul > li > a {
    color: #3d4042;
  }
  nav > ul .sf-mega-inner {
    display: block !important;
  }
  #ABdev_main_header.transparent {
    .sf-mega-inner, nav > ul .sf-mega-inner > ul > li {
      display: block !important;
    }
  }
  nav > ul .sf-mega-inner > ul {
    display: block !important;
    > li {
      border-right: none;
      padding:      0 0 0 0;
      display:      block !important;
      > {
        ul > li {
          border-right: none;
          padding:      0 0 0 0;
          display:      block !important;
        }
        a {
          font-size:      12px;
          text-transform: none;
          margin-bottom:  0;
          padding:        5px 0 0 0;
          margin-top:     0;
          color:          $color-grey-68;
        }
      }
    }
  }
  #ABdev_main_header.transparent nav > ul > li a {
    font-size:      12px;
    text-transform: none;
    margin-bottom:  0;
    padding:        5px 0 0 0;
    margin-top:     0;
    color:          $color-grey-68;
  }
  nav > ul {
    .sf-mega-inner {
      > ul > li > ul > li a {
        font-size: 12px;
        padding:   5px 0 0 0;
      }
      a {
        text-transform: none;
        padding:        inherit;
        margin-bottom:  0;
        &:hover {
          background: none;
        }
      }
      .description_menu_item {
        display: none !important;
      }
    }
    > {
      .mega1 .sf-mega-inner > ul > li, .mega2 .sf-mega-inner > ul > li, .mega3 .sf-mega-inner > ul > li, .mega4 .sf-mega-inner > ul > li, .mega5 .sf-mega-inner > ul > li, .mega6 .sf-mega-inner > ul > li, .mega7 .sf-mega-inner > ul > li {
        width:      100%;
        background: none;
      }
    }
    .use_desc {
      display: none !important;
    }
  }
  .ABt_testimonials_wrapper.testimonials_big, .ABt_testimonials_wrapper_static.testimonials_big {
    padding-left:  0;
    padding-right: 0;
  }
  .ABt_testimonials_wrapper.picture_bottom .testimonial_small img, .ABt_testimonials_wrapper_static.picture_bottom .testimonial_small img {
    left:        50%;
    margin-left: -130px;
  }
  .ABt_testimonials_wrapper.picture_bottom .testimonial_small .source, .ABt_testimonials_wrapper_static.picture_bottom .testimonial_small .source {
    left:        50%;
    margin-left: -45px;
  }
  .ABt_testimonials_wrapper.picture_bottom .ABt_pagination, .ABt_testimonials_wrapper_static.picture_bottom .ABt_pagination {
    right:        50%;
    margin-right: -55px;
    bottom:       -40px;
  }
  .ABt_testimonials_wrapper.picture_bottom, .ABt_testimonials_wrapper_static.picture_bottom {
    margin-bottom: 60px;
  }
  .ABt_testimonials_wrapper.picture_middle .testimonial_small, .ABt_testimonials_wrapper_static.picture_middle .testimonial_small {
    padding-left:  10px;
    padding-right: 10px;
  }
  .dnd-callout_box_style_1, .tcvpb-callout_box_style_1, .dnd-callout_box_style_3, .tcvpb-callout_box_style_3 {
    text-align:   center;
    margin-left:  30px;
    margin-right: 30px;
  }
  .tcvpb-callout_box_style_1 .tcvpb-callout_box_title, .dnd-callout_box_style_1 .dnd-callout_box_title, .tcvpb-callout_box_style_3 .tcvpb-callout_box_title, .dnd-callout_box_style_3 .dnd-callout_box_title {
    margin-bottom: 20px;
  }
  .tcvpb-callout_box_style_1 .tcvpb-button1, .dnd-callout_box_style_1 .dnd-button1, .tcvpb-callout_box_style_3 .tcvpb-button1, .dnd-callout_box_style_3 .dnd-button1 {
    display:    inline-block;
    text-align: center;
    float:      none;
  }
  .tcvpb-callout_box_style_1, .dnd-callout_box_style_1 {
    margin-bottom: 20px;
  }
  .tcvpb-callout_box_style_2, .dnd-callout_box_style_2, .tcvpb-callout_box_style_5, .dnd-callout_box_style_5 {
    margin-left:  0;
    margin-right: 0;
  }
  .tcvpb-callout_box_style_3, .dnd-callout_box_style_3 {
    padding-bottom: 64px;
    padding-left:   30px;
    padding-right:  30px;
    margin-right:   0;
    margin-left:    0;
  }
  .tcvpb-callout_box_style_4 .tcvpb-button1, .dnd-callout_box_style_4 .dnd-button1 {
    float:         left;
    margin-top:    0;
    margin-bottom: 64px;
  }
  .tcvpb-callout_box_style_4 p, .dnd-callout_box_style_4 p, .tcvpb-callout_box_style_2 .tcvpb-button1, .dnd-callout_box_style_2 .dnd-button1 {
    margin-bottom: 20px;
  }
  .tcvpb-callout_box_style_5 .tcvpb-icon-button, .dnd-callout_box_style_5 .dnd-icon-button {
    float:         left !important;
    margin-top:    15px;
    margin-left:   0;
    margin-bottom: 60px;
  }
  .tcvpb_service_box.tcvpb_service_box_icon_aside_right {
    h3, p {
      text-align:   left !important;
      padding-left: 80px;
    }
    .tcvpb_icon_boxed {
      left:  0;
      right: auto;
    }
  }
  .transparent {
    .carousel_next {
      right: 20px;
    }
    .carousel_prev {
      left: 20px;
    }
  }
  .margin_right_20 {
    margin-bottom: 50px;
  }
  .tcvpb_team_member {
    margin-bottom: 20px;
  }
  .tcvpb-image {
    margin-bottom: 10px;
  }
  .flickr_stream a:nth-child(3n+0) {
    margin-right: 9px;
  }
  #footer_social, #footer_container .copyright {
    text-align: center;
  }
  .post_info {
    display: none;
  }
  .post_content .post_main {
    margin-left: 0;
  }
  #comments_section {
    padding-left: 0;
  }
  .post-navigation {
    margin-left: 0;
  }
  aside.sidebar_right {
    padding-left: 0;
  }
  /*.portfolio_item .overlayed .overlay .overlay_title{
	    margin: 2% 0 7px;
	}

	.portfolio_icon{
		margin-top: 5px;
	}*/
  .portfolio_item_2, .portfolio_item_3, .portfolio_item_4, .media_container {
    width: 100%;
  }
  .portfolio_list_fullwidth .portfolio_single_column_item {
    .portfolio_thumb, &:nth-child(2n) .portfolio_thumb {
      width: 100%;
    }
    .portfolio_item_meta, &:nth-child(2n) .portfolio_item_meta {
      padding-left:   10px;
      padding-right:  10px;
      padding-bottom: 10px;
    }
    margin-bottom: 20px;
  }
  /*Slider*/
  .tp-caption.small_light_white, .small_light_white, .tp-caption.mediumlightwhite2, .mediumlightwhite2 {
    display: none !important;
  }
  .tp-caption.largeboldwhite2, .largeboldwhite2 {
    font-size:   60px !important;
    line-height: 60px !important;
    white-space: inherit !important;
  }
  .tp-caption.small_light_white_text, .small_light_white_text {
    display: none !important;
  }
  .tp-caption {
    a.inspirado_transparent_button, &.medium_white_hom_2 {
      display: none !important;
    }
  }
  .medium_white_hom_2, .tp-caption.smalllightwhitetextsl2, .smalllightwhitetextsl2, .tp-caption.smallsubtitlehom3, .smallsubtitlehom3 {
    display: none !important;
  }
}
