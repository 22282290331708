@import "../includes/colors";

/*-----------------------------------------------------------------*/
/* 4. Videos
/*-----------------------------------------------------------------*/

.videoWrapper-youtube {
  position:       relative;
  padding-bottom: 56.25%;
  padding-top:    0;
  height:         0;
  overflow:       hidden;
  margin-bottom:  30px;
  iframe, object, embed {
    position: absolute;
    top:      0;
    left:     0;
    width:    100%;
    height:   100%;
  }
}

.videoWrapper-vimeo {
  position:       relative;
  padding-bottom: 53%;
  padding-top:    30px;
  height:         0;
  overflow:       hidden;
  iframe, object, embed {
    position: absolute;
    top:      0;
    left:     0;
    width:    100%;
    height:   100%;
  }
}

.wp-video {
  margin: 0 auto;
}

.video_width {
  width: 640px;
}
