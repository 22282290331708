@import "../includes/colors";

/*
12. Creator Elements
		12.1. Element: Animation Box
		12.2. Element: Columns & sections
		12.3. Element: Abbreviation
		12.4. jQuery UI helpers
		12.5. Element: Tabs
		12.6. Element: Accordion
		12.7. Element: Toggle
		12.8. Element: Tables
		12.9. Element: Alert boxes
		12.10. Element: Blockquote
		12.11. Element: UL
		12.12. Element: Follow us links
		12.13. Element: Stats Excerpt
		12.14. Element: Knob
		12.15. Element: Team
		12.16. Element: Posts
		12.17. Element: Priceboxes
		12.18. Element: Progress bar
		12.19. Element: Search Field
		12.20. Element: Service Boxes
		12.21. Element: Shop Service boxes
		12.22. Element: Metro Boxes
		12.23. Element: Divider
		12.24. Element: Pullquote
		12.25. Element: Tooltip
		12.26. Element: Source Code
		12.27. Element: RSS Feed
		12.28. Element: Children
		12.29. Element: Sitemap
		12.30. Element: Google Map
		12.31. Element: Dropcap
		12.32. Element: Image
		12.33. Element: Videos
		12.34. Element: Clear
		12.35. Element: Callout Boxes
		12.36. Element: Image Callout Boxes
		12.37. Element: Buttons
		12.38. Element: Countdown
		12.39. Element: Image Carousel
		12.40. Element: Image Gallery
		12.41. Element: Image Switch
		12.42. Element: Timeline
		12.43. Element: Modal

*/

/*-----------------------------------------------------------------*/
/* 12. Creator Elements
/*-----------------------------------------------------------------*/
/********** 12.1. Element: Animation Box /**********/

.tcvpb-animo, .tcvpb-animo-children > * {
  visibility: hidden;
}

.tcvpb-animo {
  &.animated, &.animation_completed {
    visibility: visible;
  }
}

.tcvpb-animo-children .animation_completed {
  visibility: visible;
}

/********** 12.2. Element: Columns & sections /**********/

.tcvpb_container {
  &:before, &:after {
    display:     table;
    line-height: 0;
    content:     "";
  }
}

.tcvpb_section_tc.section_with_header {
  .tcvpb_section_content {
    margin-bottom: 0;
  }
  header .tcvpb_container {
    margin-bottom: 45px;
    margin-top:    0;
  }
}

.section_body_fullwidth .tcvpb_section_content .tcvpb_container {
  width: 100%;
}

.tcvpb_section_tc {
  position: relative;
  overflow: hidden;
  padding:  100px 0;
  > * {
    z-index:  1;
    position: relative;
  }
}

.tcvpb_video_background {
  position:   absolute;
  top:        0;
  left:       0;
  width:      100%;
  z-index:    0;
  visibility: hidden;
  .mejs-container {
    background: none !important;
  }
  .mejs-overlay-loading {
    display: none !important;
  }
}

.tcvpb-parallax {
  background-position:     50% top;
  background-attachment:   fixed;
  -webkit-background-size: cover;
  background-size:         cover;
}

.tcvpb_container [class*="column_tc_span"] {
  display:            block;
  float:              left;
  min-height:         20px;
  margin-left:        2.564102564102564%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing:    border-box;
  box-sizing:         border-box;
}

.section_no_column_margin .tcvpb_container [class*="column_tc_span"], .tcvpb_container [class*="column_tc_span"]:first-child {
  margin-left: 0;
}

.tcvpb_column_tc_span12 {
  width: 100%;
}

.tcvpb_column_tc_span11 {
  width: 91.45299145299145%;
}

.tcvpb_column_tc_span10 {
  width: 82.90598290598291%;
}

.tcvpb_column_tc_span9 {
  width: 74.35897435897436%;
}

.tcvpb_column_tc_span8 {
  width: 65.81196581196581%;
}

.tcvpb_column_tc_span7 {
  width: 57.26495726495726%;
}

.tcvpb_column_tc_span6 {
  width: 48.71794871794872%;
}

.tcvpb_column_tc_span5 {
  width: 40.17094017094017%;
}

.tcvpb_column_tc_span4 {
  width: 31.62393162393162%;
}

.tcvpb_column_tc_span3 {
  width: 23.07692307692308%;
}

.tcvpb_column_tc_span2 {
  width: 14.52991452991453%;
}

.tcvpb_column_tc_span1 {
  width: 5.982905982905983%;
}

.section_no_column_margin {
  .tcvpb_column_tc_span12 {
    width: 100%;
  }
  .tcvpb_column_tc_span11 {
    width: 91.6666666666665%;
  }
  .tcvpb_column_tc_span10 {
    width: 83.3333333333334%;
  }
  .tcvpb_column_tc_span9 {
    width: 74.9999999999999%;
  }
  .tcvpb_column_tc_span8 {
    width: 66.6666666666665%;
  }
  .tcvpb_column_tc_span7 {
    width: 58.3333333333332%;
  }
  .tcvpb_column_tc_span6 {
    width: 49.9999999999999%;
  }
  .tcvpb_column_tc_span5 {
    width: 41.6666666666665%;
  }
  .tcvpb_column_tc_span4 {
    width: 33.3333333333332%;
  }
  .tcvpb_column_tc_span3 {
    width: 24.9999999999999%;
  }
  .tcvpb_column_tc_span2 {
    width: 16.6666666666665%;
  }
  .tcvpb_column_tc_span1 {
    width: 8.3333333333332%;
  }
}

.section_equalize_5 {
  .tcvpb_column_tc_span3, .tcvpb_column_tc_span2 {
    width: 17.95084476%;
  }
  .tcvpb_container [class*="column_tc_span"] {
    margin-left: 2.564102564102564%;
  }
}

.section_no_column_margin.section_equalize_5 .tcvpb_container [class*="column_tc_span"], .section_equalize_5 .tcvpb_container [class*="column_tc_span"]:first-child {
  margin-left: 0;
}

.section_no_column_margin.section_equalize_5 {
  .tcvpb_column_tc_span3, .tcvpb_column_tc_span2 {
    width: 20%;
  }
}

.tcvpb_section_tc {
  header, footer {
    text-align: center;
    position:   relative;
  }
  header h3 {
    font-weight:    700;
    display:        inline-block;
    color:          #222;
    font-size:      28px;
    line-height:    40px;
    position:       relative;
    padding:        0;
    text-transform: uppercase;
  }
  &.title_text_after header h3 {
    margin-bottom: 0;
    &:after {
      content:          "";
      width:            84px;
      height:           1px;
      position:         absolute;
      bottom:           -23px;
      left:             50%;
      margin-left:      -42px;
      background-color: $color-ci-1;
    }
  }
  &.tcvpb-inversed_text.title_text_after header h3:after {
    background-color: $color-white;
  }
  .tcvpb_section_header_icon i {
    font-size: 30px;
  }
}

.column_title_left {
  font-weight:   400;
  display:       inline-block;
  color:         #222;
  line-height:   40px;
  margin-bottom: 26px;
  position:      relative;
  padding:       0 20px 0 0;
}

.tcvpb_section_tc.section_title_left header h3 {
  display:        block;
  text-align:     left;
  padding-bottom: 10px;
}

.column_title_left {
  display:        block;
  text-align:     left;
  padding-bottom: 10px;
  margin-bottom:  20px;
}

.tcvpb_section_tc {
  header p {
    font-family:   Georgia, serif;
    font-style:    italic;
    margin-bottom: 30px;
    padding:       0 320px 1px;
    padding-top:   10px;
    font-weight:   300;
    font-size:     16px;
    line-height:   23px;
    color:         #99a1ad;
  }
  &.intro_text_after header p:after {
    display:     block;
    content:     '';
    width:       84px;
    height:      1px;
    background:  $color-ci-1;
    position:    absolute;
    bottom:      5px;
    left:        50%;
    margin-left: -42px;
  }
  &.tcvpb-inversed_text.intro_text_after header p:after {
    background: $color-white;
  }
}

.tcvpb-inversed_text.tcvpb_section_tc header {
  p, h3 {
    color: $color-white;
  }
}

/********** 12.3. Element: Abbreviation /**********/

.tcvpb-abbr {
  border-bottom: 1px dotted #99a1ad;
  cursor:        help;
}

/********** 12.4. jQuery UI helpers /**********/

.ui-helper-reset {
  margin:    0;
  padding:   0;
  border:    0;
  outline:   0;
  font-size: 100%;
}

.ui-helper-clearfix {
  &:before {
    content: "";
    display: table;
  }
  &:after {
    content: "";
    display: table;
    clear:   both;
  }
}

.ui-helper-zfix {
  width:    100%;
  height:   100%;
  top:      0;
  left:     0;
  position: absolute;
  opacity:  0;
}

/********** 12.5. Element: Tabs /**********/

.tcvpb-tabs {
  position:      relative;
  margin-bottom: 30px;
  clear:         both;
  .nav-tabs {
    margin:        0;
    border-bottom: none;
    display:       inline-block;
    li {
      list-style:  none;
      float:       left;
      display:     block;
      white-space: nowrap;
      margin:      0;
      padding:     0;
      border-top:  1px solid #e6ebed;
      border-left: 1px solid #e6ebed;
      background:  $color-white;
      font-size:   14px;
      line-height: 16px;
      position:    relative;
      a {
        text-decoration: none;
        text-transform:  uppercase;
        text-align:      center;
        clear:           both;
        box-sizing:      border-box;
        display:         block;
        padding:         15px 25px;
        width:           100%;
        height:          100%;
        cursor:          pointer;
        outline:         none;
        color:           #18191a;
      }
      &:hover a {
        color:      $color-white;
        background: $color-ci-1;
      }
      &.active {
        &:hover a {
          background: $color-white;
        }
        position:   relative;
        border-top: 1px solid $color-ci-1;
        &:before {
          content:    "";
          display:    block;
          width:      100%;
          height:     1px;
          background: $color-white;
          position:   absolute;
          bottom:     1px;
          left:       0;
          z-index:    9999;
        }
        a {
          color:  $color-ci-1;
          cursor: default;
        }
        &:hover a {
          cursor: default;
        }
      }
      &:last-child {
        border-right: 1px solid #e6ebed;
      }
    }
  }
  .tab-content {
    border:     1px solid #e6ebed;
    margin-top: -8px;
    position:   relative;
    background: $color-white;
    p:last-child {
      margin-bottom: 0;
    }
  }
  .tab-pane {
    display:      none;
    border-width: 0;
    padding:      25px 30px;
    position:     absolute;
    top:          0;
    left:         0;
    width:        100%;
    &.active_pane {
      display:  block;
      position: relative;
    }
    .tab_text_alternative {
      padding-bottom: 0;
    }
  }
}

/* Bottom Positioned Tabs */

.tcvpb-tabs-position-bottom .nav-tabs {
  border-bottom: none;
  position:      absolute;
  bottom:        -58px;
}

.tcvpb-tabs.tcvpb-tabs-position-bottom .nav-tabs li.active {
  border-bottom: 1px solid $color-ci-1;
  border-top:    0;
}

.tcvpb-tabs-position-bottom {
  .nav-tabs li {
    border-top:    none;
    border-bottom: 1px solid #e6ebed;
  }
  .tab-pane {
    padding: 30px 25px;
  }
  .nav-tabs li.active:before {
    top:    -2px;
    bottom: auto;
  }
}

/* Left Or Right Positioned Tabs */

.tcvpb-tabs-vertical .nav-tabs li a {
  padding:    15px;
  text-align: right;
}

/* Left Positioned Tabs */

.tcvpb-tabs-position-left {
  .tab-content {
    margin-left: 200px;
  }
  .nav-tabs {
    width:         200px;
    float:         left;
    margin-bottom: 30px;
    li {
      float:        none;
      line-height:  14px;
      border-right: none;
      &:last-child {
        border-bottom: 1px solid #e6ebed;
        border-right:  none;
      }
      &.active {
        background:  $color-white;
        border-top:  1px solid #e6ebed;
        border-left: 1px solid $color-ci-1;
        &:before {
          content:    "";
          display:    block;
          width:      1px;
          height:     100%;
          background: $color-white;
          position:   absolute;
          bottom:     auto;
          left:       auto;
          right:      -1px;
          top:        0;
        }
      }
    }
  }
}

/* Right Positioned Tabs */

.tcvpb-tabs-position-right {
  .tab-content {
    margin-right: 200px;
  }
  .nav-tabs {
    width:         200px;
    float:         right;
    margin-bottom: 30px;
    li {
      float:        none;
      line-height:  14px;
      border-left:  none;
      border-right: 1px solid #e6ebed;
      a {
        text-align: left;
      }
      &:last-child {
        border-bottom: 1px solid #e6ebed;
        border-left:   none;
      }
      &.active {
        background:   $color-white;
        border-top:   1px solid #e6ebed;
        border-right: 1px solid $color-ci-1;
        &:before {
          content:    "";
          display:    block;
          width:      1px;
          height:     100%;
          background: $color-white;
          position:   absolute;
          bottom:     auto;
          right:      auto;
          left:       -1px;
          top:        0;
        }
      }
    }
  }
}

/* Bellow Width Break Point */

.tcvpb-tabs.tcvpb-tabs-fullwidthtabs .nav-tabs {
  width: 100%;
  li {
    margin-right:  0;
    width:         100%;
    margin-left:   0;
    float:         none;
    text-align:    left;
    margin-bottom: 0;
    border-right:  1px solid #e6ebed;
    &:last-child {
      margin-bottom: 8px;
    }
    &.active:after {
      display: none;
    }
  }
}

.tcvpb-tabs-position-bottom.tcvpb-tabs-fullwidthtabs .nav-tabs {
  position:   relative;
  bottom:     0;
  border-top: 1px solid #e6ebed;
  li.active:after {
    display: none;
  }
}

.tcvpb-tabs-position-left.tcvpb-tabs-fullwidthtabs .nav-tabs, .tcvpb-tabs-position-right.tcvpb-tabs-fullwidthtabs .nav-tabs {
  margin:        0;
  border-bottom: none;
  width:         100%;
  position:      relative;
  background:    $color-white;
  z-index:       1;
}

.tcvpb-tabs-position-left.tcvpb-tabs-fullwidthtabs .nav-tabs li, .tcvpb-tabs-position-right.tcvpb-tabs-fullwidthtabs .nav-tabs li {
  float:        none;
  text-align:   left;
  line-height:  36px;
  padding:      0;
  margin-right: 0;
  margin-left:  0;
  border-left:  1px solid #e6ebed;
}

.tcvpb-tabs-position-left.tcvpb-tabs-fullwidthtabs .nav-tabs li a, .tcvpb-tabs-position-right.tcvpb-tabs-fullwidthtabs .nav-tabs li a {
  text-align:     center;
  padding-top:    10px;
  padding-bottom: 10px;
}

.tcvpb-tabs-position-left.tcvpb-tabs-fullwidthtabs .nav-tabs li.active:before, .tcvpb-tabs-position-right.tcvpb-tabs-fullwidthtabs .nav-tabs li.active:before {
  background: #e6ebed;
}

.tcvpb-tabs-position-left.tcvpb-tabs-fullwidthtabs .nav-tabs li:last-child, .tcvpb-tabs-position-right.tcvpb-tabs-fullwidthtabs .nav-tabs li:last-child {
  margin-bottom: 0;
}

.tcvpb-tabs-position-left.tcvpb-tabs-fullwidthtabs .nav-tabs li.active:after, .tcvpb-tabs-position-right.tcvpb-tabs-fullwidthtabs .nav-tabs li.active:after {
  display: none;
}

.tcvpb-tabs-position-left.tcvpb-tabs-fullwidthtabs .tab-pane, .tcvpb-tabs-position-right.tcvpb-tabs-fullwidthtabs .tab-pane, .tcvpb-tabs-position-left.tcvpb-tabs-fullwidthtabs .tab-content, .tcvpb-tabs-position-right.tcvpb-tabs-fullwidthtabs .tab-content {
  margin-left:  0;
  margin-right: 0;
  padding-top:  0;
}

.tcvpb-tabs-position-left .nav-tabs li {
  margin-bottom: 7px;
  margin-right:  10px;
}

.tcvpb-tabs-position-bottom .nav-tabs li {
  margin-bottom: 5px;
  margin-right:  7px;
}

.tcvpb-tabs-position-right .nav-tabs li {
  margin-bottom: 7px;
  margin-left:   10px;
}

.tcvpb-tabs-position-top .nav-tabs li a {
  padding-top:    20px;
  padding-bottom: 20px;
}

.tcvpb-tabs-position-left .nav-tabs li a {
  padding-top:    22px;
  padding-bottom: 22px;
  text-align:     center;
}

.tcvpb-tabs-position-bottom .nav-tabs li a {
  padding-top:    20px;
  padding-bottom: 20px;
}

.tcvpb-tabs-position-right .nav-tabs li a {
  padding-top:    22px;
  padding-bottom: 22px;
  text-align:     center;
}

/* Timeline */

.tcvpb-tabs {
  .tcvpb-tabs-timeline {
    list-style-type: none;
  }
  &.tcvpb-tabs-timeline ul li {
    list-style:  none;
    font-family: Lato, sans-serif;
    float:       left;
    white-space: nowrap;
    background:  transparent;
    margin:      0;
    padding:     0;
    border:      0;
    font-size:   17px;
    line-height: 18px;
    position:    relative;
    font-weight: 400;
    color:       #18191a;
    a {
      padding: 15px 0;
    }
    &:hover a {
      color:      $color-ci-1;
      background: transparent;
    }
  }
  .nav-tabs li.active:hover a {
    color: $color-ci-1;
  }
  &.tcvpb-tabs-timeline {
    .nav-tabs {
      li.active {
        border-top: none;
        background: none;
        &:hover a {
          background: none;
        }
        &:before {
          content:       "";
          display:       block;
          width:         12px;
          height:        12px;
          border-radius: 50%;
          border:        1px solid $color-ci-1;
          background:    $color-white;
          position:      absolute;
          bottom:        -9px;
          left:          50%;
          margin-left:   -6px;
          z-index:       1;
        }
        &:after {
          content:           " ";
          width:             15px;
          height:            15px;
          border-top:        1px solid #e9eaec;
          border-right:      1px solid #e9eaec;
          -webkit-transform: rotate(-45deg);
          -moz-transform:    rotate(-45deg);
          -ms-transform:     rotate(-45deg);
          -o-transform:      rotate(-45deg);
          transform:         rotate(-45deg);
          background:        $color-white;
          position:          absolute;
          bottom:            -43px;
          left:              50%;
          margin-left:       -7px;
          z-index:           1;
        }
      }
      margin-bottom: 37px !important;
      width:         100%;
      position:      relative;
    }
    ul {
      li:last-child {
        border-right: 0;
      }
      &.nav-tabs:after {
        content:            " ";
        width:              100%;
        height:             4px;
        background:         #e4e7e8;
        -moz-box-shadow:    inset 0 0 3px #b9bfca;
        -webkit-box-shadow: inset 0 0 3px #b9bfca;
        box-shadow:         inset 0 0 3px #b9bfca;
        position:           absolute;
        bottom:             -4px;
        left:               0;
      }
    }
    .tab-content {
      border:        1px solid #e9eaec;
      border-radius: 5px;
      position:      relative;
      text-align:    center;
      p {
        text-align: left;
      }
    }
    .timeline_title {
      font-size:   14px;
      color:       #3b4550;
      font-weight: 700;
    }
    .ui-tabs-anchor {
      margin-bottom: 8px;
    }
    .tab-pane {
      background: $color-white;
    }
  }
}

.tab_par_1 {
  width: 100%;
}

.tab_par_2 {
  width: 50%;
}

.tab_par_3 {
  width: 33.33%;
}

.tab_par_4 {
  width: 25%;
}

.tab_par_5 {
  width: 20%;
}

.tab_par_6 {
  width: 16.66%;
}

.tab_par_7 {
  width: 14.28%;
}

.tab_par_8 {
  width: 12.5%;
}

.tab_par_9 {
  width: 11.11%;
}

.tab_par_10 {
  width: 10%;
}

.tab_par_11 {
  width: 9.09%;
}

.tab_par_12 {
  width: 8.33%;
}

.tab_par_13 {
  width: 7.69%;
}

.tab_par_14 {
  width: 7.14%;
}

.tab_par_15 {
  width: 6.66%;
}

.tcvpb-tabs.tcvpb-tabs-timeline.tcvpb-tabs-fullwidthtabs {
  ul li {
    border:        1px solid #e6ebed;
    border-bottom: none;
  }
  .nav-tabs li {
    &.active {
      &:after {
        display: none;
      }
      border-top: 1px solid #e6ebed;
    }
    &:last-child {
      margin-bottom: 5px;
    }
  }
  ul.nav-tabs:after {
    display: none;
  }
  .nav-tabs {
    margin-bottom: 0 !important;
  }
}

/********** Shop Tabs **********************/

.tcvpb-tabs-shop {
  text-align: center;
  h3 {
    display:    inline-block;
    text-align: center;
  }
}

.tcvpb-tabs.tcvpb-tabs-shop .nav-tabs li {
  a {
    text-align:      center;
    clear:           both;
    box-sizing:      border-box;
    display:         inline-block;
    padding:         12px 18px;
    width:           100%;
    height:          100%;
    cursor:          pointer;
    outline:         none;
    color:           #8d9299;
    text-decoration: none;
    font-style:      normal;
    font-size:       19px;
    font-weight:     700;
    line-height:     22px;
  }
  display:      inline-block;
  text-align:   center;
  float:        none;
  margin-right: 20px;
  &:last-of-type {
    margin-right: 0;
    border:       0;
  }
  a {
    &:hover {
      color:  #18191a;
      cursor: pointer;
    }
    padding:  13px 0;
    position: relative;
  }
  &.active a:after {
    content:    "";
    display:    block;
    width:      100%;
    height:     2px;
    background: #18191a;
    position:   absolute;
    bottom:     3px;
    left:       0;
  }
}

/********** 12.6. Element: Accordion /**********/

.tcvpb-accordion {
  margin-bottom: 60px;
  .ui-accordion-header {
    display:       block;
    cursor:        pointer;
    position:      relative;
    border:        1px solid #e1e3e6;
    color:         #18191a;
    padding:       8px 0 7px 17px;
    margin-top:    10px;
    margin-bottom: 0;
    font-size:     14px;
    font-weight:   700;
    background:    $color-white;
  }
}

.ui-accordion-header:hover {
  color:              $color-white !important;
  background:         $color-ci-1 !important;
  -webkit-box-shadow: inset 0 2px 0 0 $color-ci-1;
  -moz-box-shadow:    inset 0 2px 0 0 $color-ci-1;
  box-shadow:         inset 0 2px 0 0 $color-ci-1;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition:    all 300ms ease-in-out;
  -ms-transition:     all 300ms ease-in-out;
  -o-transition:      all 300ms ease-in-out;
  transition:         all 300ms ease-in-out;
}

.tcvpb-accordion {
  .ui-accordion-header-active {
    color:                      $color-white;
    background:                 $color-ci-1 !important;
    -webkit-box-shadow:         inset 0 2px 0 0 $color-ci-1;
    -moz-box-shadow:            inset 0 2px 0 0 $color-ci-1;
    box-shadow:                 inset 0 2px 0 0 $color-ci-1;
    border-bottom-left-radius:  0;
    border-bottom-right-radius: 0;
  }
  .ui-accordion-header:first-child {
    margin-top: 0;
  }
  .ui-accordion-content {
    overflow:    auto;
    border:      1px solid #e1e3e6;
    background:  $color-white;
    border-top:  0;
    padding:     22px 22px 25px;
    color:       #9098a3;
    font-weight: 400;
  }
  .ui-accordion-header-icon {
    display:  block;
    width:    13px;
    height:   13px;
    position: absolute;
    right:    20px;
    top:      25px;
  }
  .ui-icon-triangle-1-s {
    height:     2px;
    width:      14px;
    background: $color-white;
  }
  .ui-icon-triangle-1-e {
    height:     2px;
    width:      14px;
    background: $color-white;
    background: #666;
    &:before {
      content:    "";
      display:    block;
      height:     14px;
      width:      2px;
      background: #666;
      position:   relative;
      top:        -6px;
      left:       6px;
    }
  }
  &.tcvpb_accordion_circled_icons .ui-icon-triangle-1-e:before {
    display: none;
  }
}

.ui-state-hover {
  .ui-icon-triangle-1-e {
    &:before {
      background: $color-white;
      content:    "";
    }
    background: $color-white;
    content:    "";
  }
  .ui-icon-triangle-1-s {
    content: "";
  }
}

.tcvpb-accordion.tcvpb_accordion_circled_icons {
  .ui-accordion-header {
    &:before {
      content:       "";
      width:         25px;
      height:        25px;
      position:      absolute;
      border:        1px solid #99a1ad;
      border-radius: 50%;
      right:         15px;
      top:           12px;
    }
    &:hover:before {
      border: 1px solid $color-white;
    }
  }
  .ui-accordion-header-active:before {
    border: 1px solid $color-white;
  }
  .ui-icon-triangle-1-e {
    width: 0;
    right: 37px;
    &:after {
      content:     "\e635";
      /* ci_icon-angle-up */
      display:     block;
      font-family: 'core_icomoon';
      position:    absolute;
      top:         -18px;
      left:        -2px;
      font-size:   22px;
    }
  }
  .ui-icon-triangle-1-s {
    content:          "\e63a";
    display:          block;
    font-family:      'core_icomoon';
    position:         absolute;
    top:              25px;
    right:            21px;
    font-size:        22px;
    color:            $color-white;
    background-color: transparent;
  }
}

.tcvpb_accordion_circled_icons {
  &:hover {
    .ui-accordion-header.ui-state-hover:before, .ui-accordion-header-active.ui-state-hover:before {
      color:      $color-white !important;
      border:     1px solid $color-white !important;
      background: transparent;
    }
    .ui-icon-triangle-1-e:before {
      background:  transparent;
      content:     "\e63a";
      /* ci_icon-chevrondown-thin */
      display:     block;
      font-family: 'core_icomoon';
      position:    absolute;
      top:         -18px;
      left:        -2px;
      font-size:   22px;
      color:       $color-white;
    }
  }
  .ui-accordion-header-active .ui-icon-triangle-1-s:before {
    background:  transparent;
    content:     "\e63a";
    /* ci_icon-chevrondown-thin */
    display:     block;
    font-family: 'core_icomoon';
    position:    absolute;
    top:         -18px;
    right:       -4px;
    font-size:   22px;
    color:       $color-white;
  }
  &:hover {
    .ui-state-hover .ui-icon-triangle-1-e:after, .ui-icon-triangle-1-s {
      background: $color-white;
      content:    "";
    }
    .ui-accordion-header-active .ui-icon-triangle-1-s {
      background: transparent;
    }
  }
}

.tcvpb-accordion {
  &.tcvpb_accordion_icons_left {
    .ui-accordion-header {
      padding-left: 50px;
      &:before {
        left: 14px;
        top:  15px;
      }
    }
    &:hover .ui-accordion-header:before {
      border: 1px solid $color-white;
    }
    .ui-accordion-header-active:before {
      left:  14px;
      top:   15px;
      right: 0;
    }
    .ui-icon-triangle-1-e {
      width: 0;
      right: 0;
      left:  21px;
      &:before {
        content:  "";
        width:    14px;
        height:   2px;
        position: absolute;
        left:     0;
        top:      2px;
        right:    0;
      }
      &:after {
        content:    "";
        width:      2px;
        height:     14px;
        position:   absolute;
        background: #99a1ad;
        right:      -8px;
        left:       auto;
        top:        -4px;
      }
    }
    &:hover .ui-icon-triangle-1-e:after {
      background: $color-white;
      content:    "";
    }
    .ui-icon-triangle-1-s {
      width:    14px;
      height:   2px;
      position: absolute;
      right:    0;
      left:     20px;
      bottom:   22px;
      top:      auto;
    }
  }
  &.tcvpb_accordion_borderless {
    .ui-accordion-header {
      border-left:        0;
      border-right:       0;
      border-radius:      0;
      border-bottom:      0;
      background:         #f5f5f5;
      -webkit-transition: all 300ms ease-in-out;
      -moz-transition:    all 300ms ease-in-out;
      -ms-transition:     all 300ms ease-in-out;
      -o-transition:      all 300ms ease-in-out;
      transition:         all 300ms ease-in-out;
    }
    .tcvpb-accordion-body {
      border:  0;
      padding: 18px 5px 23px;
    }
  }
}

/********** 12.7. Element: Toggle /**********/

.tcvpb-toggle {
  margin-bottom: 20px;
}

/********** 12.8. Element: Tables /**********/

.tcvpb-table {
  table {
    margin-bottom:  40px;
    border-spacing: 0;
    border-bottom:  1px solid #e1e3e6;
  }
  th {
    font-weight: 700;
    font-size:   14px;
    color:       #3b4550;
    text-align:  left;
    padding:     4px 20px;
    border-top:  1px solid #e1e3e6;
    border-left: 1px solid #e1e3e6;
    &:last-child {
      border-right: 1px solid #e1e3e6;
    }
  }
  tr {
    &:first-child {
      background-color: #f7f9fa;
    }
    &:last-child {
      border-bottom: 1px solid #e1e3e6;
    }
  }
  td {
    padding:     4px 20px;
    border-left: 1px solid #e1e3e6;
    border-top:  1px solid #e1e3e6;
    &:first-child {
      padding:     4px 20px;
      border-left: 1px solid #e1e3e6;
      border-top:  1px solid #e1e3e6;
    }
    &:last-child {
      border-right: 1px solid #e1e3e6;
    }
  }
  &.tcvpb-table-alternative {
    th {
      color:        $color-white;
      background:   $color-ci-1;
      padding:      11px 20px 11px 20px;
      border-top:   0;
      border-left:  1px solid #80ecff;
      border-right: 1px solid transparent;
      &:first-child {
        border-left: 1px solid $color-ci-1;
      }
    }
    td {
      padding: 9px 20px 11px 20px;
    }
  }
}

.table_info {
  padding: 5px;
  display: inline-block;
}

.table_info_style {
  color:       #71757f;
  line-height: 20px;
  font-size:   20px;
}

/********** 12.9. Element: Alert boxes /**********/

.tcvpb_alert_success, .tcvpb_alert_info, .tcvpb_alert_warning, .tcvpb_alert_error {
  margin-bottom: 20px;
  padding:       7px 15px;
  position:      relative;
  font-weight:   400;
}

.tcvpb_alert_success i, .tcvpb_alert_info i, .tcvpb_alert_warning i, .tcvpb_alert_error i {
  margin-right: 15px;
}

.tcvpb_alert_success p, .tcvpb_alert_info p, .tcvpb_alert_warning p, .tcvpb_alert_error p {
  display:       inline-block;
  margin-bottom: 0;
}

.tcvpb_alert_success {
  background: #eefbee;
  color:      #73b96a;
  border:     1px solid #c8e9c8;
  .tcvpb_alert_box_close {
    background: #eefbee;
    color:      #73b96a;
    border:     1px solid #c8e9c8;
  }
}

.tcvpb_alert_info {
  background: #eff5fa;
  color:      #50a2de;
  border:     1px solid #cddeec;
  .tcvpb_alert_box_close {
    background: #eff5fa;
    color:      #50a2de;
    border:     1px solid #cddeec;
  }
}

.tcvpb_alert_warning {
  background: #faf6e9;
  color:      #a88558;
  border:     1px solid #e9dfbd;
  .tcvpb_alert_box_close {
    background: #faf6e9;
    color:      #a88558;
    border:     1px solid #e9dfbd;
  }
}

.tcvpb_alert_error {
  background: #fef0ef;
  color:      #f05347;
  border:     1px solid #f1c0bc;
  .tcvpb_alert_box_close {
    background: #fef0ef;
    color:      #f05347;
    border:     1px solid #f1c0bc;
  }
}

.tcvpb_alert_success .tcvpb_alert_box_close, .tcvpb_alert_info .tcvpb_alert_box_close, .tcvpb_alert_warning .tcvpb_alert_box_close, .tcvpb_alert_error .tcvpb_alert_box_close {
  border:     none;
  background: none;
  cursor:     pointer;
  position:   absolute;
  top:        7px;
  right:      15px;
}

/********** 12.10. Element: Blockquote /**********/

.tcvpb_blockquote_style1 {
  font-size:   15px;
  font-style:  italic;
  line-height: 24px;
  padding:     25px 0 17px 24px;
  border-left: 2px solid $color-ci-1;
  margin:      0;
  &:before, &:after {
    display: none;
  }
  p {
    margin-bottom: 2px;
    > small {
      margin-top:    1px;
      margin-bottom: 24px;
    }
  }
}

.tcvpb_blockquote small::before {
  content:       "";
  width:         12px;
  height:        1px;
  background:    $color-ci-1;
  display:       inline-block;
  margin-bottom: 4px;
  margin-right:  5px;
}

.tcvpb_blockquote_style1 small {
  font-size:   13px;
  line-height: 32px;
  font-style:  italic;
  display:     block;
  color:       $color-ci-1;
  a {
    color: $color-ci-1;
    &:hover {
      color: #93959d;
    }
  }
}

.tcvpb_blockquote_style2 {
  font-size:   15px;
  font-style:  italic;
  line-height: 24px;
  padding:     25px 0 17px 24px;
  margin:      0;
  p {
    margin-bottom: 2px;
  }
  &:before {
    content:        '\2018 \2018';
    font-family:    Garamond, Baskerville, "Baskerville Old Face", "Hoefler Text", "Times New Roman", serif;
    position:       absolute;
    color:          #9098a3;
    top:            10px;
    left:           -5px;
    font-size:      70px;
    letter-spacing: -10px;
  }
  &:after {
    content:        '\2019 \2019';
    font-family:    Garamond, Baskerville, "Baskerville Old Face", "Hoefler Text", "Times New Roman", serif;
    position:       absolute;
    color:          #9098a3;
    bottom:         0;
    right:          -5px;
    font-size:      70px;
    letter-spacing: -10px;
  }
  p > small {
    margin-top: 1px;
  }
  small {
    font-size:  13px;
    font-style: italic;
    display:    block;
    color:      $color-ci-1;
    a {
      color: $color-ci-1;
      &:hover {
        color: #93959d;
      }
    }
  }
}

/********** 12.11. Element: UL /**********/

.tcvpb_shortcode_ul {
  list-style:  none !important;
  margin-left: 0;
  li {
    list-style:    none !important;
    line-height:   14px;
    display:       block;
    position:      relative;
    padding-left:  30px;
    text-align:    left;
    margin-bottom: 16px;
    p {
      margin-bottom: 0;
    }
    i {
      font-size: 15px;
      position:  absolute;
      top:       0;
      left:      0;
    }
  }
}

.ul_li_icons_color {
  color: #9098a3;
}

/********** 12.12. Element: Follow Us Links /**********/

.tcvpb_follow_us {
  font-size: 16px;
  a {
    margin-right:    10px;
    text-decoration: none;
    display:         inline-block;
    width:           30px;
    height:          30px;
    line-height:     30px;
    text-align:      center;
    margin-bottom:   10px;
    font-size:       13px;
    color:           #878e99;
    border:          1px solid #e1e3e6;
    &:hover i {
      color: $color-ci-1;
    }
  }
}

/********** 12.13. Element: Stats Excerpt /**********/

.tcvpb_stats_excerpt {
  text-align: center;
  position:   relative;
  margin:     20px 0;
  i {
    font-size:     38px;
    line-height:   38px;
    display:       block;
    margin-bottom: 10px;
  }
  .tcvpb_stats_number, .tcvpb_stats_number_sign {
    color:       #18191a;
    font-weight: 600;
    line-height: 50px;
    display:     block;
    font-size:   16px;
    font-weight: 700;
  }
  .tcvpb_stats_number {
    font-size:   50px;
    font-weight: 400;
  }
  &.with_after .tcvpb_stats_number {
    margin-bottom: 30px;
    position:      relative;
    &:after {
      content:     "";
      height:      1px;
      width:       44px;
      background:  #bec0c2;
      position:    absolute;
      bottom:      -15px;
      left:        50%;
      margin-left: -22px;
    }
  }
  p {
    color:          #18191a;
    font-size:      12px;
    font-weight:    600;
    line-height:    20px;
    text-align:     center;
    text-transform: uppercase;
    margin-bottom:  0;
  }
}

.pattern_overlayed .tcvpb_stats_excerpt {
  color: $color-white;
  i {
    color: $color-white;
  }
}

.tcvpb-inversed_text .tcvpb_stats_excerpt .tcvpb_stats_number_sign {
  color: $color-white;
}

.tcvpb_section_tc.tcvpb-parallax .tcvpb_stats_number {
  margin-bottom: 32px;
  &:after {
    display:     block;
    content:     '';
    width:       45px;
    height:      1px;
    background:  #bcbdbf;
    position:    absolute;
    bottom:      40px;
    left:        50%;
    margin-left: -23px;
  }
}

.stats_excerpt_light_blue {
  color: $color-ci-1 !important;
}

.stats_excerpt_dark_blue {
  color: #50c5de !important;
}

.stats_excerpt_light_green {
  color: #5fcbcd !important;
}

.stats_excerpt_dark_green {
  color: #5bd4b6 !important;
}

/********** 12.14. Element: Knob /**********/

.tcvpb_knob_wrapper {
  text-align:    center;
  margin-bottom: 30px;
}

.tcvpb_knob_inner_wrap {
  position:      relative;
  text-align:    center;
  margin-bottom: 17px;
}

.tcvpb_knob_wrapper {
  input {
    display: none;
  }
  h3 {
    font-size:     16px;
    margin-bottom: 0;
    line-height:   23px;
  }
  .tcvpb_knob_number_sign {
    position:    absolute;
    top:         0;
    left:        0;
    width:       100%;
    height:      100%;
    text-align:  center;
    visibility:  hidden;
    font-size:   28px;
    font-weight: 700;
    z-index:     2;
    i {
      display:     block;
      top:         50px;
      position:    absolute;
      left:        50%;
      margin-left: -18px;
      color:       $color-ci-1;
      font-size:   36px;
      line-height: 22px;
    }
  }
  .span {
    text-align: center;
  }
  .tcvpb_knob_number, .tcvpb_knob_percentage {
    color:       #18191a;
    font-size:   28px;
    font-weight: 400;
    text-align:  center;
    display:     inline-block;
  }
}

/********** 12.15. Element: Team /**********/

.tcvpb_team_member {
  text-align: center;
  position:   relative;
  box-shadow: inset 0 0 0 1px #e6e8eb;
  img {
    display: block;
    width:   100%;
  }
  .tcvpb_team_member_link {
    display: block;
    padding: 17px 0 24px 0;
  }
  .tcvpb_overlayed {
    position:    relative;
    line-height: 1;
    overflow:    hidden;
    display:     inline-block;
    .tcvpb_overlay {
      width:              100%;
      height:             100%;
      position:           absolute;
      overflow:           hidden;
      top:                0;
      left:               0;
      -ms-filter:         "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
      opacity:            0;
      -webkit-transform:  scale(10);
      -moz-transform:     scale(10);
      -o-transform:       scale(10);
      -ms-transform:      scale(10);
      transform:          scale(10);
      -webkit-transition: all 0.3s ease-in-out 0.2s;
      -moz-transition:    all 0.3s ease-in-out 0.2s;
      -o-transition:      all 0.3s ease-in-out 0.2s;
      -ms-transition:     all 0.3s ease-in-out 0.2s;
      transition:         all 0.3s ease-in-out 0.2s;
    }
  }
  &:hover .tcvpb_overlayed .tcvpb_overlay {
    background:               rgba(0, 208, 245, 0.85);
    -ms-filter:               "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity:                  1;
    -webkit-transform:        scale(1);
    -moz-transform:           scale(1);
    -o-transform:             scale(1);
    -ms-transform:            scale(1);
    transform:                scale(1);
    -webkit-transition-delay: 0.2s;
    -moz-transition-delay:    0.2s;
    -o-transition-delay:      0.2s;
    -ms-transition-delay:     0.2s;
    transition-delay:         0.2s;
  }
  .tcvpb_overlayed .tcvpb_overlay p {
    position:      absolute;
    bottom:        25px;
    left:          20px;
    margin-bottom: 0;
    a {
      color:           $color-white;
      text-decoration: none;
      width:           30px;
      height:          30px;
      background:      rgb(0, 0, 0);
      background:      rgba(0, 0, 0, 0.3);
      line-height:     30px;
      margin:          0 5px 5px 0;
      display:         inline-block;
    }
  }
  .team_content {
    position:    absolute;
    top:         0;
    left:        0 !important;
    padding:     30px 20px;
    text-align:  left;
    color:       $color-white;
    font-size:   14px;
    line-height: 22px;
  }
  .tcvpb_overlayed .tcvpb_overlay {
    p a {
      &:hover {
        color:              $color-white;
        -webkit-transition: all 180ms ease-in;
        -moz-transition:    all 180ms ease-in;
        -ms-transition:     all 180ms ease-in;
        -o-transition:      all 180ms ease-in;
        transition:         all 180ms ease-in;
      }
      i {
        -webkit-transition: all 180ms ease-in;
        -moz-transition:    all 180ms ease-in;
        -ms-transition:     all 180ms ease-in;
        -o-transition:      all 180ms ease-in;
        transition:         all 180ms ease-in;
      }
      &:hover {
        i {
          -webkit-transition: all 180ms ease-in;
          -moz-transition:    all 180ms ease-in;
          -ms-transition:     all 180ms ease-in;
          -o-transition:      all 180ms ease-in;
          transition:         all 180ms ease-in;
        }
        .ci_icon-facebook {
          color: #3b5998;
        }
        .ci_icon-twitter {
          color: #4099ff;
        }
        .ci_icon-googleplus {
          color: #d34836;
        }
        .ci_icon-linkedin {
          color: #0e76a8;
        }
        .ci_icon-youtube {
          color: #c4302b;
        }
        .ci_icon-pinterest {
          color: #c8232c;
        }
        .ci_icon-github {
          color: #171515;
        }
        .ci_icon-rss {
          color: #ee802f;
        }
        .ci_icon-behance {
          color: #1769ff;
        }
        .ci_icon-blogger {
          color: #f57d00;
        }
        .ci_icon-delicious {
          color: #39f;
        }
        .ci_icon-designcontest {
          color: #5faad2;
        }
        .ci_icon-deviantart {
          color: #4e6252;
        }
        .ci_icon-digg {
          color: #000;
        }
        .ci_icon-dribbble {
          color: #ea4c89;
        }
        .ci_icon-dropbox {
          color: #007ee5;
        }
        .ci_icon-envelope {
          color: #ed1c16;
        }
        .ci_icon-flickr {
          color: #ff0084;
        }
        .ci_icon-forrst {
          color: #5b9a68;
        }
        .ci_icon-instagram {
          color: #3f729b;
        }
        .ci_icon-lastfm {
          color: #c3000d;
        }
        .ci_icon-myspace {
          color: #313131;
        }
        .ci_icon-picassa {
          color: #9a45a0;
        }
        .ci_icon-skype {
          color: #00aff0;
        }
        .ci_icon-stumbleupon {
          color: #f74425;
        }
        .ci_icon-vimeo {
          color: #4bf;
        }
        .ci_icon-zerply {
          color: #9dcc7a;
        }
      }
    }
    i {
      font-size:   16px;
      margin:      0 7px;
      line-height: 30px;
    }
  }
  .tcvpb_team_member_name {
    display:            block;
    font-style:         normal;
    text-transform:     uppercase;
    line-height:        29px;
    margin-bottom:      3px;
    -webkit-transition: all 180ms ease-in;
    -moz-transition:    all 180ms ease-in;
    -ms-transition:     all 180ms ease-in;
    -o-transition:      all 180ms ease-in;
    transition:         all 180ms ease-in;
    color:              #18191a;
    font-size:          16px;
    font-weight:        700;
    text-align:         center;
  }
  .tcvpb_team_member_position {
    display:            block;
    font-style:         italic;
    font-size:          14px;
    font-weight:        400;
    line-height:        12px;
    color:              #99a1ae;
    text-align:         center;
    -webkit-transition: all 180ms ease-in;
    -moz-transition:    all 180ms ease-in;
    -ms-transition:     all 180ms ease-in;
    -o-transition:      all 180ms ease-in;
    transition:         all 180ms ease-in;
  }
  &:hover {
    .tcvpb_team_member_name, .tcvpb_team_member_position {
      color:              $color-ci-1;
      -webkit-transition: all 180ms ease-in;
      -moz-transition:    all 180ms ease-in;
      -ms-transition:     all 180ms ease-in;
      -o-transition:      all 180ms ease-in;
      transition:         all 180ms ease-in;
    }
  }
}

/*.tcvpb_team_member:hover .tcvpb_team_member_name,
.tcvpb_team_member:hover .tcvpb_team_member_position{
    color: #00D8FF;
    -webkit-transition:all 180ms ease-in;
	-moz-transition:all 180ms ease-in;
	-ms-transition:all 180ms ease-in;
	-o-transition:all 180ms ease-in;
	transition:all 180ms ease-in;
}*/

.tcvpb_team_member_modal {
  display:     none;
  position:    fixed;
  top:         0;
  left:        0;
  height:      100%;
  background:  $color-white;
  z-index:     99999;
  overflow-y:  auto;
  padding-top: 60px;
  text-align:  left;
  width:       100%;
  img {
    width: 100%;
  }
  h4 {
    text-align:    center;
    margin-top:    0;
    margin-bottom: 0;
  }
  .tcvpb_team_member_position {
    text-align:    center;
    margin-bottom: 40px;
  }
  .tcvpb_container {
    max-width:    960px;
    width:        90%;
    margin-left:  auto;
    margin-right: auto;
  }
}

.tcvpb_team_member_modal_close {
  width:              32px;
  height:             32px;
  font-size:          32px;
  line-height:        32px;
  position:           absolute;
  top:                60px;
  right:              60px;
  cursor:             pointer;
  -webkit-transition: all 180ms ease-in-out;
  -moz-transition:    all 180ms ease-in-out;
  -o-transition:      all 180ms ease-in-out;
  transition:         all 180ms ease-in-out;
  &:hover {
    color: $color-ci-1;
  }
}

.tcvpb_team_member_social_under {
  border-top:  1px solid #e6e8eb;
  border-left: none;
  padding-top: 9px;
  a {
    color:              $color-white;
    text-decoration:    none;
    width:              30px;
    height:             30px;
    line-height:        30px;
    margin:             0 5px 5px 0;
    display:            inline-block;
    -webkit-transition: all 180ms ease-in;
    -moz-transition:    all 180ms ease-in;
    -ms-transition:     all 180ms ease-in;
    -o-transition:      all 180ms ease-in;
    transition:         all 180ms ease-in;
    margin-bottom:      9px;
  }
}

.tcvpb_team_member .tcvpb_team_member_social_under a {
  i {
    -webkit-transition: all 180ms ease-in;
    -moz-transition:    all 180ms ease-in;
    -ms-transition:     all 180ms ease-in;
    -o-transition:      all 180ms ease-in;
    transition:         all 180ms ease-in;
    color:              #878e99;
    font-size:          15px;
    font-weight:        400;
    line-height:        22px;
    text-align:         center;
  }
  &:hover {
    i {
      -webkit-transition: all 180ms ease-in;
      -moz-transition:    all 180ms ease-in;
      -ms-transition:     all 180ms ease-in;
      -o-transition:      all 180ms ease-in;
      transition:         all 180ms ease-in;
    }
    .ci_icon-facebook {
      color: #3b5998;
    }
    .ci_icon-twitter {
      color: #4099ff;
    }
    .ci_icon-googleplus {
      color: #d34836;
    }
    .ci_icon-linkedin {
      color: #0e76a8;
    }
    .ci_icon-youtube {
      color: #c4302b;
    }
    .ci_icon-pinterest {
      color: #c8232c;
    }
    .ci_icon-github {
      color: #171515;
    }
    .ci_icon-rss {
      color: #ee802f;
    }
    .ci_icon-behance {
      color: #1769ff;
    }
    .ci_icon-blogger {
      color: #f57d00;
    }
    .ci_icon-delicious {
      color: #39f;
    }
    .ci_icon-designcontest {
      color: #5faad2;
    }
    .ci_icon-deviantart {
      color: #4e6252;
    }
    .ci_icon-digg {
      color: #000;
    }
    .ci_icon-dribbble {
      color: #ea4c89;
    }
    .ci_icon-dropbox {
      color: #007ee5;
    }
    .ci_icon-envelope {
      color: #ed1c16;
    }
    .ci_icon-flickr {
      color: #ff0084;
    }
    .ci_icon-forrst {
      color: #5b9a68;
    }
    .ci_icon-instagram {
      color: #3f729b;
    }
    .ci_icon-lastfm {
      color: #c3000d;
    }
    .ci_icon-myspace {
      color: #313131;
    }
    .ci_icon-picassa {
      color: #9a45a0;
    }
    .ci_icon-skype {
      color: #00aff0;
    }
    .ci_icon-stumbleupon {
      color: #f74425;
    }
    .ci_icon-vimeo {
      color: #4bf;
    }
    .ci_icon-zerply {
      color: #9dcc7a;
    }
  }
}

/********** 12.16. Element: Posts /**********/

.tcvpb_posts_shortcode {
  margin-bottom: 13px;
  position:      relative;
}

.tcvpb_latest_news_shortcode_thumb {
  margin-right: 0;
  height:       190px;
  float:        left;
  img {
    width: 100%;
  }
}

.tcvpb_latest_news_shortcode_content {
  background: $color-white;
  h5 {
    margin-top: 10px;
  }
}

.tcvpb_latest_news_time {
  display:               block;
  position:              absolute;
  top:                   0;
  left:                  0;
  width:                 57px;
  height:                57px;
  text-align:            center;
  color:                 $color-white;
  background:            #056ab2;
  -webkit-border-radius: 5px;
  -moz-border-radius:    5px;
  border-radius:         5px;
  .day {
    display:   block;
    font-size: 21px;
  }
  .month {
    display:        block;
    text-transform: lowercase;
    font-size:      13px;
  }
  .year {
    display: none;
  }
}

.tcvpb_posts_shortcode {
  &.tcvpb_posts_shortcode-1 {
    margin-bottom: 9px;
    .tcvpb_latest_news_shortcode_thumb {
      margin-right: 0;
      height:       130px;
      width:        170px;
      float:        left;
      img {
        height: 130px;
        width:  170px;
      }
    }
    .tcvpb_latest_news_shortcode_content {
      background:   $color-white;
      padding-left: 170px;
      h5 {
        font-size:     14px;
        line-height:   20px;
        padding:       1px 0 0 30px;
        margin-top:    0;
        margin-bottom: 6px;
        a {
          color: #3b4550;
          &:hover {
            color: #50a2de;
          }
        }
      }
      p {
        padding-left: 30px;
      }
      .date_container {
        padding:       0 20px 0 30px;
        display:       inline-block;
        margin-bottom: 10px;
        .tcvpb_posts_date {
          display:      inline-block;
          float:        left;
          position:     relative;
          margin-right: 20px;
        }
        .tcvpb_posts_comments {
          display:  inline-block;
          float:    left;
          position: relative;
          i {
            position: relative;
            top:      2px;
          }
        }
      }
    }
  }
  &.tcvpb_posts_shortcode-2 {
    .tcvpb_latest_news_shortcode_thumb {
      margin-right: 0;
      height:       190px;
      float:        none;
    }
    .tcvpb_latest_news_shortcode_content {
      background: $color-white;
      border:     1px solid #e9eaec;
      h5 {
        font-size:   14px;
        line-height: 20px;
        padding:     15px 30px 0 30px;
        a {
          color: #3b4550;
          &:hover {
            color: #50a2de;
          }
        }
      }
      p {
        padding: 0 30px 0 30px;
      }
      .date_container {
        border-top: 1px solid #e9eaec;
        padding:    10px 20px 34px 20px;
        .tcvpb_posts_date {
          display:  inline-block;
          float:    left;
          position: relative;
          i {
            position: relative;
            top:      1px;
          }
        }
        .tcvpb_posts_comments {
          display:  inline-block;
          float:    right;
          position: relative;
          i {
            position: relative;
            top:      2px;
          }
        }
      }
    }
  }
}

/********** 12.17. Element: Priceboxes /**********/

.tcvpb_pricing-table-1 {
  margin: 10px 0;
  .tcvpb_pricebox_header {
    text-align:    center;
    padding:       14px 0 0 0;
    margin-bottom: 0;
    position:      relative;
  }
  .tcvpb_pricebox_name {
    font-size:      20px;
    font-weight:    400;
    text-transform: uppercase;
    display:        block;
    padding-bottom: 0;
    padding-top:    6px;
    color:          #18191a;
    padding-bottom: 0;
    padding-top:    8px;
    padding-left:   4px;
    color:          #18191a;
  }
  .tcvpb_popular-plan .tcvpb_pricebox_name {
    padding-top:  8px;
    padding-left: 4px;
  }
  .tcvpb_plan .tcvpb-button, .tcvpb_plan2 .tcvpb-button, .tcvpb_popular-plan .tcvpb-button {
    margin-right: 0;
  }
  .tcvpb_pricebox_info {
    padding-top:    11px;
    padding-bottom: 20px;
    position:       relative;
    z-index:        2;
  }
  .tcvpb_pricebox_currency {
    font-size:   60px;
    font-weight: 700;
    color:       #18191a;
  }
  .tcvpb_pricebox_price {
    font-size:   38px;
    line-height: 42px;
    display:     inline-block;
    font-weight: 400;
    font-style:  italic;
    color:       #18191a;
  }
  .tcvpb_pricebox_currency {
    color:       #18191a;
    font-size:   38px;
    line-height: 42px;
    display:     inline-block;
    font-weight: 400;
    font-style:  italic;
  }
  &.tcvpb_popular-plan {
    .tcvpb_pricebox_currency {
      color:       $color-white;
      font-size:   38px;
      line-height: 42px;
      display:     inline-block;
      font-weight: 400;
      font-style:  italic;
    }
    .tcvpb_pricebox_price, .tcvpb_pricebox_monthly {
      color: $color-white;
    }
  }
  .tcvpb_pricebox_monthly {
    font-size:     15px;
    display:       block;
    font-weight:   300;
    margin-top:    2px;
    color:         #18191a;
    margin-bottom: 3px;
  }
  .tcvpb_plan2 .tcvpb_pricebox_name {
    color:   $color-white;
    padding: 8px 0 0 0;
  }
  .tcvpb_pricebox_feature {
    border-bottom: 1px solid #e1e3e6;
    border-left:   1px solid #e1e3e6;
    border-right:  1px solid #e1e3e6;
    display:       block;
    padding:       12px 40px 13px 40px;
    text-align:    center;
  }
}

.tcvpb_pricebox_feature.tcvpb_pricebox_feature_button {
  padding: 32px 40px 32px 40px;
}

.tcvpb_pricing-table-1 {
  .tcvpb_pricebox_featured_text {
    margin-bottom: 3px;
    min-height:    30px;
    text-align:    center;
    color:         $color-white;
    font-size:     14px;
    line-height:   30px;
  }
  .tcvpb_pricebox_decsription {
    display:    block;
    font-style: italic;
  }
  .tcvpb_popular-plan {
    .tcvpb_pricebox_name {
      color: $color-white;
    }
    .tcvpb_pricebox_featured_text {
      background: #434342;
    }
  }
  &.tcvpb_pricing-table-light .tcvpb_pricebox_header {
    color:      #3b4550;
    background: #edeef0;
  }
  &.tcvpb_pricing-table-dark .tcvpb_pricebox_header {
    background: #3b4500;
    color:      $color-white;
  }
  &.tcvpb_pricing-table-yellow .tcvpb_pricebox_header {
    background: #f3ae36;
    color:      $color-white;
  }
  &.tcvpb_pricing-table-green .tcvpb_pricebox_header {
    background: #5bd4b6;
    color:      $color-white;
  }
  &.tcvpb_pricing-table-red .tcvpb_pricebox_header {
    background: #f05347;
    color:      $color-white;
  }
  &.tcvpb_pricing-table-blue .tcvpb_pricebox_header {
    background: $color-ci-1;
    color:      $color-white;
  }
  &.tcvpb_pricing-table-gray .tcvpb_pricebox_header {
    background: #edeef0;
    color:      $color-white;
  }
  &.tcvpb_pricing-table-cyan .tcvpb_pricebox_header {
    background: #50c5de;
    color:      $color-white;
  }
  &.tcvpb_pricing-table-aquamarine .tcvpb_pricebox_header {
    background: #5fcbcd;
    color:      $color-white;
  }
}

.section_no_column_margin {
  .tcvpb_pricing-table-1 {
    border-radius: 0;
    .tcvpb_pricebox_header {
      border-radius: 0;
    }
  }
  .pricebox_first {
    border-top-left-radius:    3px !important;
    border-bottom-left-radius: 3px !important;
  }
  .pricebox_last {
    border-top-right-radius:    3px !important;
    border-bottom-right-radius: 3px !important;
  }
}

.grey_background {
  background-color: #edeef0;
}

.bg_icon {
  height: 170px;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 50%;
  &--single {
    background-image: url("../../images/interieur/icon-single.png");
  }

  &--twin {
    background-image: url("../../images/interieur/icon-twin.png");
  }
}

.pricebox_padding {
  padding-top:    30px;
  padding-bottom: 60px;
}

/********** 12.18. Element: Progress bar /**********/

.tcvpb_progress_bar {
  position: relative;
}

.tcvpb_progress_bar_default .tcvpb_meter {
  margin-bottom:      22px;
  background:         #f5f6f8;
  height:             14px;
  position:           relative;
  -moz-box-shadow:    inset 0 2px 2px #e9eaec;
  -webkit-box-shadow: inset 0 2px 2px #e9eaec;
  box-shadow:         inset 0 2px 2px #e9eaec;
  top:                1px;
}

.white_bar_background .tcvpb_progress_bar_default .tcvpb_meter {
  background:         $color-white;
  -moz-box-shadow:    inset 0 2px 2px #e9eaec;
  -webkit-box-shadow: inset 0 2px 2px #e9eaec;
  box-shadow:         inset 0 2px 2px #e9eaec;
}

.tcvpb_progress_bar_default {
  .tcvpb_meter_label {
    margin-bottom:  7px;
    display:        inline-block;
    line-height:    16px;
    color:          #18191a;
    font-family:    Lato, sans-serif;
    font-weight:    700;
    font-size:      14px;
    text-transform: uppercase;
  }
  .tcvpb_meter .tcvpb_meter_percentage {
    display:  block;
    height:   14px;
    position: relative;
    overflow: visible !important;
    span {
      line-height: 24px;
      position:    absolute;
      display:     none;
      width:       42px;
      text-align:  right;
      top:         -30px;
      right:       0;
      color:       #18191a;
      font-family: Lato, sans-serif;
      font-weight: 400;
      font-size:   15px;
      z-index:     100;
    }
  }
  .tcvpb_meter_percentage span {
    display: none;
  }
  .tcvpb_meter {
    .tcvpb_meter_above30 span, .tcvpb_meter_above40 span, .tcvpb_meter_above50 span, .tcvpb_meter_above60 span, .tcvpb_meter_above70 span, .tcvpb_meter_above80 span, .tcvpb_meter_above90 span {
      display: block;
    }
  }
}

.tcvpb_progress_bar_thick {
  .tcvpb_meter {
    margin-bottom:         20px;
    background:            #f5f6f8;
    height:                28px;
    -webkit-border-radius: 20px;
    -moz-border-radius:    20px;
    border-radius:         20px;
    position:              relative;
    -moz-box-shadow:       inset 0 0 6px #e9eaec;
    -webkit-box-shadow:    inset 0 0 6px #e9eaec;
    box-shadow:            inset 0 0 6px #e9eaec;
  }
  .tcvpb_meter_label {
    margin-bottom:  0;
    position:       absolute;
    top:            6px;
    left:           10px;
    z-index:        100;
    line-height:    16px;
    color:          $color-white;
    font-family:    Lato, sans-serif;
    font-weight:    700;
    font-size:      14px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
  .tcvpb_meter .tcvpb_meter_percentage {
    display:               block;
    height:                28px;
    -webkit-border-radius: 20px;
    -moz-border-radius:    20px;
    border-radius:         20px;
    span {
      line-height: 14px;
      position:    absolute;
      display:     block;
      width:       42px;
      text-align:  right;
      top:         6px;
      right:       10px;
      color:       #18191a;
      font-family: Lato, sans-serif;
      font-weight: 400;
      font-size:   15px;
    }
  }
}

.tcvpb_progress_bar_thin {
  margin-bottom: 21px;
  .tcvpb_meter_label {
    margin-bottom: 8px;
    display:       inline-block;
    line-height:   16px;
    color:         #18191a;
    font-family:   Lato, sans-serif;
    font-weight:   700;
    font-size:     14px;
  }
  .tcvpb_meter {
    margin-bottom: 21px;
    background:    #fbfcfd;
    height:        14px;
    border:        1px solid #eef0f2;
    position:      relative;
  }
  .tcvpb_meter_percentage {
    height:   10px;
    display:  block;
    border:   3px solid #fbfcfd;
    position: relative;
    top:      1px;
    overflow: visible !important;
  }
  .tcvpb_meter .tcvpb_meter_percentage span {
    line-height: 32px;
    position:    absolute;
    display:     none;
    width:       42px;
    text-align:  right;
    top:         -38px;
    right:       6px;
    color:       #18191a;
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-size:   15px;
    z-index:     100;
  }
  .tcvpb_meter_percentage span {
    display: none;
  }
  .tcvpb_meter {
    .tcvpb_meter_above30 span, .tcvpb_meter_above40 span, .tcvpb_meter_above50 span, .tcvpb_meter_above60 span, .tcvpb_meter_above70 span, .tcvpb_meter_above80 span, .tcvpb_meter_above90 span {
      display: block;
    }
  }
}

.progress_percentage_width_58 {
  width:      58%;
  background: $color-ci-1;
}

.progress_percentage_width_70 {
  width:      70%;
  background: linear-gradient(to right, $color-ci-1, $color-ci-1);
}

.progress_percentage_width_73 {
  width:      73%;
  background: #b1dd40;
}

.progress_percentage_width_75 {
  width:      75%;
  background: linear-gradient(to right, $color-ci-1, $color-ci-1);
}

.progress_percentage_width_77 {
  width:      77%;
  background: $color-ci-1;
}

.progress_percentage_width_85 {
  width:      85%;
  background: linear-gradient(to right, $color-ci-1, $color-ci-1);
}

.progress_percentage_width_86 {
  width:      86%;
  background: #42a5f5;
}

.progress_percentage_width_87 {
  width:      87%;
  background: $color-ci-1;
}

.progress_percentage_width_90 {
  width:      90%;
  background: linear-gradient(to right, $color-ci-1, $color-ci-1);
}

.progress_percentage_width_99 {
  width:      99%;
  background: $color-ci-1;
}

.progress_percentage_width_100 {
  width:      100%;
  background: linear-gradient(to right, $color-ci-1, $color-ci-1);
}

.progress_percentage_width_100_yellow {
  width:      100%;
  background: #edd153;
}

/********** 12.19. Element: Search Field /**********/

.tcvpb_search {
  position: relative;
  input {
    &[type="text"] {
      border:        1px solid #e1e3e6;
      background:    #f5f6f8;
      border-radius: 2px;
      font-weight:   400;
    }
    outline: none;
  }
  .submit {
    position: absolute;
    top:      9px;
    right:    25px;
    i {
      font-size:          20px;
      color:              #c5c9d2;
      cursor:             pointer;
      top:                3px;
      position:           relative;
      -webkit-transition: all 180ms ease-in-out;
      -moz-transition:    all 180ms ease-in-out;
      -ms-transition:     all 180ms ease-in-out;
      -o-transition:      all 180ms ease-in-out;
      transition:         all 180ms ease-in-out;
      &:hover {
        color: $color-ci-1;
      }
    }
  }
  input[name="s"] {
    width:       99%;
    margin:      1px;
    color:       #9098a3;
    font-size:   14px;
    font-style:  italic;
    line-height: 20px;
    text-align:  left;
  }
}

/********** 12.20. Element: Service Boxes /**********/

.tcvpb_service_box {
  text-align:    center;
  margin-bottom: 36px;
}

.service_box_border {
  border: 1px solid rgb(233, 234, 236);
}

.service_box_font_size {
  font-size: 10pt;
  color:     #808080;
}

/*Inline*/

.tcvpb_service_box {
  &.tcvpb_service_box_inline {
    .tcvpb_service_box_header {
      text-align:    left;
      margin-bottom: 15px;
    }
    .tcvpb_icon_boxed {
      float:        left;
      margin-right: 10px;
      i {
        font-size:          31px;
        line-height:        27px;
        -webkit-transition: all 180ms ease-in-out;
        -moz-transition:    all 180ms ease-in-out;
        -ms-transition:     all 180ms ease-in-out;
        -o-transition:      all 180ms ease-in-out;
        transition:         all 180ms ease-in-out;
        &:hover {
          color:              #18191a !important;
          -webkit-transition: all 180ms ease-in-out;
          -moz-transition:    all 180ms ease-in-out;
          -ms-transition:     all 180ms ease-in-out;
          -o-transition:      all 180ms ease-in-out;
          transition:         all 180ms ease-in-out;
        }
      }
    }
    h3 {
      display:     inline-block;
      color:       #18191a;
      font-size:   16px;
      font-weight: 700;
      line-height: 27px;
      text-align:  left;
    }
    > p {
      text-align: left;
    }
  }
  &.tcvpb_service_box_icon_aside_left {
    padding-left: 80px;
    position:     relative;
    .tcvpb_icon_boxed {
      position:   absolute;
      top:        0;
      left:       0;
      width:      55px;
      height:     55px;
      text-align: center;
      i {
        line-height:        54px;
        font-size:          25px;
        -webkit-transition: all 180ms ease-in-out;
        -moz-transition:    all 180ms ease-in-out;
        -ms-transition:     all 180ms ease-in-out;
        -o-transition:      all 180ms ease-in-out;
        transition:         all 180ms ease-in-out;
      }
    }
    &:hover .tcvpb_icon_boxed i {
      color:              #18191a !important;
      -webkit-transition: all 180ms ease-in-out;
      -moz-transition:    all 180ms ease-in-out;
      -ms-transition:     all 180ms ease-in-out;
      -o-transition:      all 180ms ease-in-out;
      transition:         all 180ms ease-in-out;
    }
    h3 {
      color:       #18191a;
      font-size:   16px;
      font-weight: 700;
      line-height: 20px;
      text-align:  left;
    }
    > p {
      color:       #9098a3;
      font-size:   14px;
      font-weight: 400;
      line-height: 23px;
      text-align:  left;
      margin-top:  10px;
    }
  }
  &.tcvpb_service_box_icon_aside_right {
    padding-right: 80px;
    position:      relative;
    .tcvpb_icon_boxed {
      position:   absolute;
      top:        0;
      right:      0;
      width:      55px;
      height:     55px;
      text-align: center;
      i {
        line-height:        54px;
        font-size:          25px;
        -webkit-transition: all 180ms ease-in-out;
        -moz-transition:    all 180ms ease-in-out;
        -ms-transition:     all 180ms ease-in-out;
        -o-transition:      all 180ms ease-in-out;
        transition:         all 180ms ease-in-out;
      }
    }
    &:hover .tcvpb_icon_boxed i {
      color:              #18191a !important;
      -webkit-transition: all 180ms ease-in-out;
      -moz-transition:    all 180ms ease-in-out;
      -ms-transition:     all 180ms ease-in-out;
      -o-transition:      all 180ms ease-in-out;
      transition:         all 180ms ease-in-out;
    }
    h3 {
      color:       #18191a;
      font-size:   16px;
      font-weight: 700;
      line-height: 20px;
      text-align:  right;
    }
    > p {
      color:       #9098a3;
      font-size:   14px;
      font-weight: 400;
      line-height: 23px;
      text-align:  right;
      margin-top:  10px;
    }
  }
  &.tcvpb_service_box_big {
    padding: 37px 0 22px 0;
    .tcvpb_icon_boxed {
      text-align:    center;
      position:      relative;
      margin-bottom: 7px;
      display:       block;
      i {
        font-size:  38px;
        text-align: center;
      }
    }
    h3 {
      font-size:   16px;
      font-weight: 700;
      line-height: 20px;
      text-align:  center;
      margin-top:  0;
    }
    p {
    }
  }
}

/*Left Aside*/

/*Right Aside*/

/* Big */

.tcvpb-inversed_text .tcvpb_service_box {
  color: $color-white !important;
  h3, p {
    color: $color-white !important;
  }
}

.tcvpb_service_box {
  a {
    h3 {
      -webkit-transition: all 180ms ease-in-out;
      -moz-transition:    all 180ms ease-in-out;
      -ms-transition:     all 180ms ease-in-out;
      -o-transition:      all 180ms ease-in-out;
      transition:         all 180ms ease-in-out;
    }
    &:hover h3 {
      color:              $color-ci-1;
      -webkit-transition: all 180ms ease-in-out;
      -moz-transition:    all 180ms ease-in-out;
      -ms-transition:     all 180ms ease-in-out;
      -o-transition:      all 180ms ease-in-out;
      transition:         all 180ms ease-in-out;
    }
  }
  &.tcvpb_service_box_big_rounded {
    padding:  60px 0 22px 0;
    position: relative;
    .tcvpb_icon_boxed i {
      font-size:     38px;
      text-align:    center;
      border:        1px solid #565656;
      border-radius: 50%;
      padding:       50px;
    }
    h3 {
      font-size:      14px;
      font-weight:    400;
      text-transform: uppercase;
      line-height:    20px;
      text-align:     center;
      margin-top:     65px;
    }
  }
}

/* Big Rounded */

/********** 12.21. Element: Shop Service boxes /**********/

.tcvpb-shop-service-box {
  text-align: right;
  position:   relative;
  z-index:    1;
  &:before {
    content:          ' ';
    position:         absolute;
    left:             0;
    right:            0;
    top:              0;
    bottom:           0;
    background-color: rgb(151, 219, 233);
    background-color: rgba(151, 219, 233, 0.5);
    z-index:          -1;
  }
  &:after {
    content:       '';
    position:      absolute;
    bottom:        0;
    left:          0;
    border-bottom: 239px solid white;
    border-left:   280px solid rgb(0, 0, 0);
    border-left:   280px solid rgba(0, 0, 0, 0);
    border-right:  0 solid rgb(0, 0, 0);
    border-right:  0 solid rgba(0, 0, 0, 0);
    width:         30%;
    z-index:       -1;
  }
}

.tcvpb-shop-service-box-wrapper {
  h3 {
    color:          #252728;
    font-size:      24px;
    font-weight:    700;
    line-height:    20px;
    text-transform: uppercase;
    display:        block;
    white-space:    nowrap;
    top:            41px;
    position:       relative;
  }
  .tcvpb-shop-service-box-subtitle {
    color:          #252728;
    font-size:      16px;
    font-weight:    300;
    line-height:    20px;
    text-align:     right;
    text-transform: uppercase;
    position:       relative;
    top:            45px;
  }
  p {
    color:        #9098a3;
    font-size:    14px;
    font-weight:  400;
    line-height:  23px;
    text-align:   right;
    padding-left: 169px;
    top:          65px;
    position:     relative;
  }
  i {
    color:       $color-ci-1;
    font-size:   34px;
    line-height: 23px;
    text-align:  right;
  }
}

.tcvpb-shop-service-box-icon {
  position:       relative;
  top:            79px;
  width:          100%;
  display:        inline-block;
  border-right:   1px solid #e6e8eb;
  padding-right:  32px;
  padding-bottom: 55px;
  height:         46px;
  &:after {
    width:      80%;
    height:     1px;
    background: #eee;
    content:    ' ';
    position:   absolute;
    right:      0;
    bottom:     0;
  }
}

/********** 12.22. Element: Metro Boxes /**********/

/********** Shortcode: Metro Boxes /**********/

.tcvpb_metro_box {
  display:             block;
  text-align:          center;
  padding-top:         0;
  margin:              0 0;
  perspective:         1000px;
  -webkit-perspective: 1000px;
  -moz-perspective:    1000px;
  -o-perspective:      1000px;
  -ms-perspective:     1000px;
  position:            relative;
}

.tcvpb_metro_box_header {
  margin-top: 45px;
}

.metro_custom .tcvpb_metro_box_header {
  margin-top: 90px;
}

.tcvpb_metro_box {
  &:hover .flipper {
    -webkit-transform: rotateY(180deg);
    -moz-transform:    rotateY(180deg);
    -o-transform:      rotateY(180deg);
    -ms-transform:     rotateY(180deg);
    transform:         rotateY(180deg);
  }
  .flipper {
    transition:              0.6s ease-out;
    -webkit-transition:      0.6s ease-out;
    -moz-transition:         0.6s ease-out;
    -o-transition:           0.6s ease-out;
    -ms-transition:          0.6s ease-out;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style:    preserve-3d;
    -o-transform-style:      preserve-3d;
    transform-style:         preserve-3d;
    position:                relative;
  }
  min-height: 180px;
  .front {
    height: 180px;
  }
  .back {
    height: 230px;
  }
  .flipper {
    .front, .back {
      backface-visibility:         hidden;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility:    hidden;
      -o-backface-visibility:      hidden;
      -ms-backface-visibility:     visible;
      position:                    absolute;
      top:                         0;
      left:                        0;
    }

    .front {
      z-index:           2;
      -webkit-transform: rotateY(0deg);
      -moz-transform:    rotateY(0deg);
      -o-transform:      rotateY(0deg);
      -ms-transform:     rotateY(0deg);
      transform:         rotateY(0deg);
      width:             100%;
      box-shadow:        0 1px 0 0 rgba(0, 0, 0, 0.08);
      border-bottom:     1px solid #f5f5f5;
    }
    .back {
      -webkit-transform: rotateY(-180deg);
      -moz-transform:    rotateY(-180deg);
      -o-transform:      rotateY(-180deg);
      -ms-transform:     rotateY(-180deg);
      transform:         rotateY(-180deg);
      background:        $color-ci-1;
      cursor:            pointer;
    }
  }
  .back h3 {
    color:          $color-white;
    font-size:      16px;
    font-weight:    700;
    line-height:    20px;
    text-transform: uppercase;
    padding-top:    30px;
    margin-bottom:  -55px;
  }
  .flipper .back h3:after {
    content:          "";
    width:            45px;
    height:           1px;
    position:         absolute;
    bottom:           -17px;
    left:             50%;
    background-color: $color-white;
    margin-left:      -22px;
  }
  h3 {
    color:          #18191a;
    font-size:      16px;
    font-weight:    700;
    line-height:    20px;
    text-transform: uppercase;
    text-align:     center;
  }
  p {
    color:       #9098a3;
    font-size:   14px;
    font-weight: 400;
    line-height: 23px;
    text-align:  center;
    padding:     13px 22px 0;
  }
  .back p {
    padding:     90px 23px 30px;
    color:       $color-white;
    font-size:   12px;
    font-weight: 400;
    line-height: 16px;
    text-align:  center;
  }
  .tcvpb_icon_boxed {
    background:    transparent;
    position:      relative;
    display:       inline-block;
    margin-bottom: 15px;
    i {
      color:     $color-white;
      font-size: 46px;
    }
  }
  &.boxed {
    height:     301px;
    box-shadow: 0 0 0 1px #eef0f2 inset;
    position:   relative;
    .tcvpb_metro_box_header {
      position:    absolute;
      top:         40px;
      left:        50%;
      margin-left: -70px;
      .tcvpb_icon_boxed {
        border: 0;
        i {
          color:     #285fdb;
          font-size: 40px;
          margin:    0;
        }
      }
    }
    &:hover .tcvpb_metro_box_header {
      .tcvpb_icon_boxed i, h3 {
        color: $color-white;
      }
    }
    .tcvpb_metro_box_header h3 {
      color: #3c414c;
    }
  }
  .flipper .front {
    animation:         stayvisible 0.5s both;
    -webkit-animation: stayvisible 0.5s both;
    -moz-animation:    stayvisible 0.5s both;
    -o-animation:      stayvisible 0.5s both;
    -ms-animation:     donothing 0.5s;
    -ms-transition:    visibility 0s linear 0.17s;
    visibility:        visible;
  }
  &:hover .flipper {
    .back {
      animation:         stayvisible 0.5s both;
      -webkit-animation: stayvisible 0.5s both;
      -moz-animation:    stayvisible 0.5s both;
      -o-animation:      stayvisible 0.5s both;
      -ms-animation:     donothing 0.5s;
      -ms-transition:    visibility 0s linear 0.17s;
      visibility:        visible;
    }
    .front {
      animation:         stayvisible 0.5s both;
      -webkit-animation: stayvisible 0.5s both;
      -moz-animation:    stayvisible 0.5s both;
      -o-animation:      stayvisible 0.5s both;
      -ms-animation:     donothing 0.5s;
      -ms-transition:    visibility 0s linear 0.17s;
      visibility:        hidden;
    }
  }
  .flipper .back {
    animation:         stayvisible 0.5s both;
    -webkit-animation: stayvisible 0.5s both;
    -moz-animation:    stayvisible 0.5s both;
    -o-animation:      stayvisible 0.5s both;
    -ms-animation:     donothing 0.5s;
    -ms-transition:    visibility 0s linear 0.17s;
    visibility:        hidden;
  }
}

@keyframes stayvisible {
  from {
    visibility: visible;
  }

  to {
    visibility: visible;
  }
}

@-webkit-keyframes stayvisible {
  from {
    visibility: visible;
  }

  to {
    visibility: visible;
  }
}

@-moz-keyframes stayvisible {
  from {
    visibility: visible;
  }

  to {
    visibility: visible;
  }
}

@-o-keyframes stayvisible {
  from {
    visibility: visible;
  }

  to {
    visibility: visible;
  }
}

@-ms-keyframes donothing {
  0% {
  }

  100% {
  }
}

.metro_box_icon_color {
  color: $color-ci-1 !important;
}

.metro_box_bg {
  background-color: rgb(82, 78, 78);
}

.metro_background_on_left {
  background-color: #f5f5f5;
}

/********** 12.23. Element: Divider /**********/

.tcvpb_divider {
  clear:       both;
  text-align:  right;
  line-height: 20px;
  border-top:  1px solid #e1e3e6;
  margin:      10px;
  a {
    text-decoration: none;
    display:         inline-block;
    &:hover {
      text-decoration: none;
    }
  }
  i {
    position: relative;
    top:      2px;
  }
}

.tcvpb_divider_dashed {
  border-top: 1px dashed #e1e3e6;
}

.tcvpb_divider_dotted {
  border-top: 1px dotted #e1e3e6;
}

/********** 12.24. Element: Pullquote /**********/

.tcvpb_pullquote {
  padding:       20px 20px 20px 15px;
  margin:        15px 15px 15px 0;
  float:         left;
  background:    #f5f5f5;
  border-radius: 0;
  border-left:   4px solid #18191a;
  font-weight:   400;
  font-style:    italic;
  font-size:     17px;
  line-height:   26px;
  color:         #99a1ad;
}

.tcvpb_pullquote_right {
  padding:       20px 15px 20px 20px;
  float:         right;
  background:    #f5f5f5;
  border-radius: 0;
  border-right:  4px solid #18191a;
}

/********** 12.25. Element: Tooltip /**********/

.tcvpb_tooltip {
  cursor: pointer;
  color:  $color-ci-1;
  &:hover {
    cursor: pointer;
    color:  #222;
  }
}

.tipsy {
  font-size: 10px;
  position:  absolute;
  padding:   5px;
  z-index:   100000;
}

.tipsy-inner {
  background-color:      #000;
  color:                 $color-white;
  max-width:             200px;
  padding:               5px 8px 4px 8px;
  text-align:            center;
  border-radius:         3px;
  -moz-border-radius:    3px;
  -webkit-border-radius: 3px;
}

/*.tipsy-inner { box-shadow: 0 0 5px #000000; -webkit-box-shadow: 0 0 5px #000000; -moz-box-shadow: 0 0 5px #000000; }*/

.tipsy-arrow {
  position:    absolute;
  width:       0;
  height:      0;
  line-height: 0;
  border:      5px dashed #000;
}

.tipsy-arrow-n {
  border-bottom-color: #000;
}

.tipsy-arrow-s {
  border-top-color: #000;
}

.tipsy-arrow-e {
  border-left-color: #000;
}

.tipsy-arrow-w {
  border-right-color: #000;
}

.tipsy-n .tipsy-arrow {
  top:                 0;
  left:                50%;
  margin-left:         -5px;
  border-bottom-style: solid;
  border-top:          none;
  border-left-color:   transparent;
  border-right-color:  transparent;
}

.tipsy-nw .tipsy-arrow {
  top:                 0;
  left:                10px;
  border-bottom-style: solid;
  border-top:          none;
  border-left-color:   transparent;
  border-right-color:  transparent;
}

.tipsy-ne .tipsy-arrow {
  top:                 0;
  right:               10px;
  border-bottom-style: solid;
  border-top:          none;
  border-left-color:   transparent;
  border-right-color:  transparent;
}

.tipsy-s .tipsy-arrow {
  bottom:             0;
  left:               50%;
  margin-left:        -5px;
  border-top-style:   solid;
  border-bottom:      none;
  border-left-color:  transparent;
  border-right-color: transparent;
}

.tipsy-sw .tipsy-arrow {
  bottom:             0;
  left:               10px;
  border-top-style:   solid;
  border-bottom:      none;
  border-left-color:  transparent;
  border-right-color: transparent;
}

.tipsy-se .tipsy-arrow {
  bottom:             0;
  right:              10px;
  border-top-style:   solid;
  border-bottom:      none;
  border-left-color:  transparent;
  border-right-color: transparent;
}

.tipsy-e .tipsy-arrow {
  right:               0;
  top:                 50%;
  margin-top:          -5px;
  border-left-style:   solid;
  border-right:        none;
  border-top-color:    transparent;
  border-bottom-color: transparent;
}

.tipsy-w .tipsy-arrow {
  left:                0;
  top:                 50%;
  margin-top:          -5px;
  border-right-style:  solid;
  border-left:         none;
  border-top-color:    transparent;
  border-bottom-color: transparent;
}

/********** 12.26. Element: Source Code /**********/

.tcvpb_prettyprint {
  padding: 24px !important;
  ol {
    margin:     0 !important;
    padding:    0 !important;
    list-style: decimal outside !important;
  }
  li {
    margin-left: 20px !important;
    list-style:  decimal outside !important;
  }
}

/********** 12.27. Element: RSS Feed /**********/

.tcvpb_rss, .tcvpb_children {
  list-style:  none !important;
  margin-left: 0 !important;
  li {
    list-style:  none !important;
    margin-left: 0 !important;
  }
}

/********** 12.28. Element: Children /**********/

/********** 12.29. Element: Sitemap /**********/

.tcvpb_sitemap {
  list-style:    none !important;
  li {
    list-style: none !important;
  }
  margin-bottom: 30px;
}

/********** 12.30. Element: Google Map /**********/

.tcvpb_google_map img {
  max-width:     none !important;
  border:        none !important;
  border-radius: 0 !important;
  box-shadow:    none !important;
}

.tcvpb_google_map_marker {
  display: none;
}

.google_map_style {
  height: 580px;
  width:  100%;
}

.google_map_style_2 {
  height: 400px;
  width:  100%;
}

.google_map_style_3 {
  height: 370px;
  width:  100%;
}

/********** 12.31. Element: Dropcap /**********/

.tcvpb_dropcap {
  display:      block;
  width:        32px;
  height:       32px;
  float:        left;
  margin-right: 3px;
  color:        #18191a;
}

/** Style 1 **/

.tcvpb_dropcap_style1 {
  font-size:   45px;
  line-height: 22px;
  color:       #3b4550;
  margin-top:  11px;
  margin-left: -6px;
}

/** Style 2 **/

.tcvpb_dropcap_style2 {
  font-size:    24px;
  text-align:   center;
  line-height:  32px;
  background:   $color-ci-1;
  width:        28px;
  height:       33px;
  color:        $color-white;
  margin-top:   5px;
  margin-right: 10px;
}

/********** 12.32. Element: Image  ********************************************/

.tcvpb-image {
  a {
    position:      relative;
    display:       block;
    outline:       none;
    border-bottom: 0 !important;
    img {
      display: block;
    }
    &.lightbox {
      position: relative;
    }
    span {
      position:           absolute;
      width:              100%;
      top:                50%;
      left:               0;
      margin-top:         -50px;
      line-height:        22px;
      text-align:         center;
      color:              $color-white;
      opacity:            0;
      -webkit-transition: all 300ms ease-in;
      -moz-transition:    all 300ms ease-in;
      -o-transition:      all 300ms ease-in;
      -ms-transition:     all 300ms ease-in;
      transition:         all 300ms ease-in;
    }
    &:hover span {
      opacity: 1;
    }
    i {
      position:           absolute;
      width:              32px;
      height:             32px;
      opacity:            0;
      left:               50%;
      margin-left:        -16px;
      top:                50%;
      margin-top:         -16px;
      color:              $color-white;
      -webkit-transition: all 300ms ease-in;
      -moz-transition:    all 300ms ease-in;
      -o-transition:      all 300ms ease-in;
      -ms-transition:     all 300ms ease-in;
      transition:         all 300ms ease-in;
    }
    &:hover i {
      opacity: 1;
    }
  }
  canvas {
    position:           absolute;
    left:               0;
    top:                0;
    max-width:          100%;
    width:              100% !important;
    height:             100% !important;
    opacity:            0;
    -webkit-transition: all 300ms ease-in;
    -moz-transition:    all 300ms ease-in;
    -o-transition:      all 300ms ease-in;
    -ms-transition:     all 300ms ease-in;
    transition:         all 300ms ease-in;
  }
  a:hover canvas {
    opacity: 1;
    &.grey-effect {
      background: rgb(0, 0, 0);
      background: rgba(0, 0, 0, 0.5);
    }
  }
}

/********** 12.33. Element: Videos /**********/

.tcvpb-videoWrapper-youtube {
  position:       relative;
  padding-bottom: 56.25%;
  padding-top:    0;
  height:         0;
  overflow:       hidden;
  iframe, object, embed {
    position: absolute;
    top:      0;
    left:     0;
    width:    100%;
    height:   100%;
  }
}

.tcvpb-videoWrapper-vimeo {
  position:       relative;
  padding-bottom: 53%;
  padding-top:    30px;
  height:         0;
  overflow:       hidden;
  iframe, object, embed {
    position: absolute;
    top:      0;
    left:     0;
    width:    100%;
    height:   100%;
  }
}

/********** 12.34. Element: Clear /**********/

.tcvpb_clear {
  clear: both;
}

/********** 12.35. Element: Callout Boxes *************************************************************/

.tcvpb-callout_box {
  margin-bottom: 30px;
  padding:       50px 0 96px 0;
}

.tcvpb-callout_box_title {
  font-size:     32px;
  font-family:   Lato;
  font-weight:   300;
  margin-bottom: 13px;
  color:         #505558;
  display:       block;
}

.tcvpb-callout_box {
  p {
    margin-bottom: 0;
  }
  .tcvpb_column_tc_span3 {
    text-align:  center;
    padding-top: 3px;
  }
  .tcvpb-button {
    margin: 0;
  }
}

.tcvpb_button_1 {
  margin-left: 10px;
}

.tcvpb-button1 i, .tcvpb-button2 i {
  margin: 0 5px;
}

.tcvpb-callout_box {
  &.white_text {
    .tcvpb-callout_box_title, p {
      color: $color-white !important;
    }
  }
  .tcvpb-button_transparent {
    border-color: $color-white;
  }
}

.callout_box_background_image {
  background-image: url(../images/background-image11.jpg);
}


/**** Style 1 ****/
.tcvpb-callout_box_style_1 {
  margin-bottom:  53px;
  margin-left:    100px;
  margin-right:   100px;
  padding-bottom: 3px;
  position:       relative;
  display:        block;
  font-size:      30px;
  line-height:    36px;
  color:          $color-white;
  font-weight:    300;
  .tcvpb-callout_box_title {
    font-family:   Lato, sans-serif;
    font-size:     36px;
    line-height:   43px;
    color:         $color-white;
    display:       block;
    margin-bottom: 10px;
    margin-top:    0px;
    font-weight:   400;
    padding-right: 250px;
  }
  .tcvpb-button1 {
    float:    right;
    position: absolute;
    right:    0;
    top:      50%;
  }
}

.tcvpb-button1:hover, .tcvpb-button2:hover {
  background: #ccc;
}

.tcvpb-callout_box_style_1 p {
  display:     block;
  font-size:   30px;
  line-height: 36px;
  color:       $color-white;
  font-weight: 300;
}

/**** Style 2 ****/

.tcvpb-callout_box_style_2 {
  margin-bottom:  35px;
  margin-left:    155px;
  margin-right:   155px;
  text-align:     center;
  margin-top:     19px;
  padding-bottom: 45px;
  .tcvpb-callout_box_title {
    font-size:     28px;
    color:         #3b4550;
    margin-bottom: 0;
    margin-top:    10px;
    text-align:    center;
    font-weight:   700;
    line-height:   28px;
  }
  p {
    color:      #848994;
    display:    block;
    padding:    15px 30px 23px;
    text-align: center;
    font-size:  16px;
  }
  .tcvpb-button1, .tcvpb-button2 {
    display:    inline-block;
    text-align: center;
  }
}

.tcvpb-button2 {
  margin-left: 10px;
}

/**** Style 3 ****/

.tcvpb-callout_box_style_3 {
  margin-bottom: 0px;
  padding-left:  130px;
  padding-right: 130px;
  background:    $color-ci-1;
  .tcvpb-callout_box_title {
    font-family:   Lato, sans-serif;
    color:         #3b4550;
    float:         left;
    margin-bottom: 0px;
    margin-top:    10px;
    font-weight:   400;
    font-size:     18px;
    line-height:   26px;
  }
  .tcvpb-button1 {
    float: right;
  }
  .tcvpb-icon-button {
    display:    inline-block;
    float:      right;
    margin-top: 6px;
  }
  &.white_text .tcvpb-icon-button i {
    font-size: 32px;
    color:     $color-white;
  }
  .tcvpb-icon-button i .tcvpb-callout_box.tcvpb-callout_box_style_4 {
    margin-bottom: 0;
    padding:       50px 0;
  }
}

.tcvpb-callout_box_style_4 {
  margin-bottom: 0;
  padding:       50px 0;
  .tcvpb-callout_box_title {
    font-family:   Lato, sans-serif;
    font-size:     18px;
    color:         #3b4550;
    display:       block;
    margin-bottom: 10px;
    font-weight:   400;
  }
  p {
    width:      70%;
    display:    block;
    text-align: left;
  }
  .tcvpb-button1 {
    float:      right;
    margin-top: -45px;
  }
}

/**** Style 5 ****/

.tcvpb-callout_box_style_5 {
  margin-bottom:  50px;
  padding-bottom: 87px;
  padding-top:    13px;
  margin-left:    130px;
  margin-right:   130px;
  .tcvpb-callout_box_title {
    font-family:   Lato, sans-serif;
    font-size:     28px;
    float:         left;
    line-height:   32px;
    margin-bottom: 10px;
    margin-top:    50px;
    font-weight:   400;
  }
}

.white_text.tcvpb-callout_box_style_5 .tcvpb-callout_box_title, .tcvpb-inversed_text.tcvpb-callout_box_style_5 .tcvpb-callout_box_title {
  color: $color-white;
}

.tcvpb-callout_box_style_5 .tcvpb-icon-button {
  float:      right;
  margin-top: 50px;
  i {
    font-size:          32px;
    color:              $color-white;
    transition:         all 180ms ease-in-out;
    -webkit-transition: all 180ms ease-in-out;
    &:hover {
      color: #e4664d;
    }
  }
}

/********** 12.36. Element: Image Callout Boxes ********************************************/

.tcvpb-image-callout-box {
  text-align:    center;
  overflow:      hidden;
  border-radius: 2px;
  margin-bottom: 30px;
  .tcvpb-image-callout-box-wrapper {
    padding: 83px 10px 14px 10px;
    .tcvpb-image-callout-box-title {
      font-weight: 100;
      font-size:   33px;
    }
  }
  &.tcvpb-inversed_text .tcvpb-image-callout-box-wrapper .tcvpb-image-callout-box-title {
    color: $color-white;
  }
}

.pattern_overlayed .tcvpb-image-callout-box-wrapper {
  z-index:  1;
  position: relative;
}

.tcvpb-image-callout-box .tcvpb-image-callout-box-wrapper {
  p {
    text-align:  center;
    font-weight: 100;
    font-size:   20px;
  }
  a i {
    margin: 0 10px;
  }
  .button {
    -webkit-transition: all 180ms ease-in;
    -moz-transition:    all 180ms ease-in;
    -ms-transition:     all 180ms ease-in;
    -o-transition:      all 180ms ease-in;
    transition:         all 180ms ease-in;
    &:hover {
      color:              #b2b2b2;
      -webkit-transition: all 180ms ease-in;
      -moz-transition:    all 180ms ease-in;
      -ms-transition:     all 180ms ease-in;
      -o-transition:      all 180ms ease-in;
      transition:         all 180ms ease-in;
    }
  }
  .button_light {
    color: $color-white;
  }
  .button_dark {
    color: #3b4550;
  }
  .button_yellow {
    color: #f3ae36;
  }
  .button_green {
    color: #5bd4b6;
  }
  .button_red {
    color: #18191a;
  }
  .button_blue {
    color: #50a2de;
  }
  .button_gray {
    color: #edeef0;
  }
  .button_cyan {
    color: #50c5de;
  }
  .button_aquamarine {
    color: #5fcbcd;
  }
}

/* exclusive */

.tcvpb-image-callout-box-wrapper.exclusive .tcvpb-image-callout-box-title {
  color:          $color-white;
  font-size:      40px;
  font-weight:    400;
  line-height:    22px;
  text-align:     center;
  text-transform: uppercase;
  border:         1px solid #a9aaa9;
  padding:        26px 37px 29px 37px;
  display:        inline-block;
  margin-bottom:  12px;
}

.tcvpb-image-callout-box {
  .exclusive {
    p {
      font-family:   'Georgia', serif;
      color:         $color-white;
      font-size:     18px;
      font-weight:   400;
      font-style:    italic;
      text-align:    center;
      margin-bottom: 52px;
    }
    .button {
      color:       $color-white !important;
      font-size:   18px !important;
      font-weight: 400 !important;
      text-align:  center !important;
      font-style:  normal !important;
      background:  none !important;
      border:      none !important;
      padding:     0;
    }
  }
  .look_book .button, .elegant .button {
    color:       $color-white !important;
    font-size:   18px !important;
    font-weight: 400 !important;
    text-align:  center !important;
    font-style:  normal !important;
    background:  none !important;
    border:      none !important;
    padding:     0;
  }
  .exclusive .button i, .look_book .button i, .elegant .button i {
    color:       $color-white;
    font-size:   11px;
    font-weight: 300;
    line-height: 22px;
    top:         -2px;
    position:    relative;
    margin-left: 6px;
  }
}

/* look book */

.tcvpb-image-callout-box-wrapper.look_book {
  .tcvpb-image-callout-box-title {
    color:          $color-white;
    font-size:      34px;
    font-weight:    400;
    line-height:    42px;
    text-align:     center;
    text-transform: uppercase;
    display:        inline-block;
    margin-bottom:  8px;
  }
  .tcvpb-image-callout-box-subtitle {
    color:         $color-white;
    font-size:     16px;
    font-weight:   400;
    text-align:    center;
    display:       block;
    position:      relative;
    margin-bottom: 11px;
    &:before {
      content:    '';
      width:      72px;
      height:     1px;
      background: #909294;
      position:   absolute;
      left:       65px;
      top:        10px;
    }
    &:after {
      content:    '';
      width:      72px;
      height:     1px;
      background: #909294;
      position:   absolute;
      right:      65px;
      top:        10px;
    }
  }
}

.tcvpb-image-callout-box .look_book {
  p {
    font-family:   'Georgia', serif;
    color:         $color-white;
    font-size:     17px;
    font-style:    italic;
    line-height:   18px;
    text-align:    center;
    margin-bottom: 68px;
  }
  .button {
    margin-bottom: 2px;
  }
}

/* elegant */

.tcvpb-image-callout-box-wrapper.elegant {
  padding: 91px 10px 14px 10px;
}

.tcvpb-image-callout-box .elegant {
  .tcvpb-image-callout-box-title {
    color:         $color-white;
    font-size:     18px;
    font-weight:   400;
    line-height:   22px;
    text-align:    center;
    display:       inline-block;
    margin-bottom: 19px;
  }
  .tcvpb-image-callout-box-subtitle {
    color:          $color-white;
    font-size:      32px;
    font-weight:    400;
    line-height:    22px;
    text-align:     center;
    display:        inline-block;
    text-transform: uppercase;
    margin-bottom:  19px;
  }
  p {
    font-family:   'Georgia', serif;
    color:         $color-white;
    font-size:     18px;
    font-style:    italic;
    text-align:    center;
    margin-bottom: 58px;
  }
}

/********** 12.37. Element: Buttons *************************************************************/

.tcvpb-button {
  display:         inline-block;
  white-space:     nowrap;
  text-decoration: none;
  color:           $color-white;
  font-size:       14px;
  font-weight:     700;
  line-height:     20px;
  text-align:      center;
  i {
    margin-left: 10px;
    font-size:   14px;
    position:    relative;
    top:         2px;
  }
}

.tcvpb-button_transparent {
  background: transparent;
  border:     1px solid #bdc3c7;
  color:      $color-white !important;
}

.tcvpb-button_light {
  background: $color-white;
  border:     1px solid #e1e3e6;
  color:      $color-ci-1 !important;
}

.tcvpb-button_light_blue {
  background: $color-ci-1;
  border:     1px solid $color-ci-1;
  color:      $color-white !important;
}

.tcvpb-button_dark_blue {
  background: #42a5f5;
  border:     1px solid #42a5f5;
  color:      $color-white !important;
}

.tcvpb-button_yellow {
  background: #edd153;
  border:     1px solid #edd153;
  color:      $color-white !important;
}

.tcvpb-button_green {
  background: #b1dd40;
  border:     1px solid #b1dd40;
  color:      $color-white !important;
}

.tcvpb-button_red {
  background: #ff7455;
  border:     1px solid #ff7455;
  color:      $color-white !important;
}

.tcvpb-button_gray {
  background: #edeef0;
  border:     1px solid #edeef0;
  color:      #18191a !important;
}

.tcvpb-button_purple {
  background: #8c9eff;
  border:     1px solid #8c9eff;
  color:      $color-white !important;
}

.tcvpb-button:hover {
  background: #ccc;
  border:     1px solid transparent;
  color:      $color-ci-1 !important;
}

.tcvpb-button_light:hover {
  background: $color-ci-1;
  color:      $color-white !important;
}

.tcvpb-button_light_blue:hover, .tcvpb-button_dark_blue:hover, .tcvpb-button_gray:hover, .tcvpb-button_yellow:hover, .tcvpb-button_green:hover, .tcvpb-button_red:hover, .tcvpb-button_purple:hover {
  background: #ccc;
  color:      $color-white !important;
}

.tcvpb-button_small {
  padding: 14px 23px;
}

.tcvpb-button_medium {
  padding: 17px 23px;
}

.tcvpb-button_large {
  padding: 22px 68px;
}

.tcvpb-button_xlarge {
  padding: 20px 25px;
}

/********** 12.38. Element: Countdown /**********/

.tcvpb_countdown {
  text-align:                  center;
  margin-bottom:               60px;
  -webkit-perspective:         1000;
  -webkit-backface-visibility: hidden;
  -webkit-transform:           translateZ(0);
  -moz-transform:              translateZ(0);
  -ms-transform:               translateZ(0);
  -o-transform:                translateZ(0);
  transform:                   translateZ(0);
  -webkit-transform:           translate3d(0, 0, 0);
  * {
    -webkit-perspective:         1000;
    -webkit-backface-visibility: hidden;
    -webkit-transform:           translateZ(0);
    -moz-transform:              translateZ(0);
    -ms-transform:               translateZ(0);
    -o-transform:                translateZ(0);
    transform:                   translateZ(0);
    -webkit-transform:           translate3d(0, 0, 0);
  }
  .tcvpb_countdown_inner {
    display:       inline-block;
    text-align:    center;
    margin-bottom: 20px !important;
  }
}

.countdown {
  font-weight:   100;
  font-size:     42px;
  line-height:   100px;
  color:         $color-white;
  background:    #18191a;
  width:         141px;
  height:        100px;
  border-radius: 2px;
  margin:        0 0 3px 26px;
}

.tcvpb_countdown_inner {
  &:first-of-type > {
    .countdown, span {
      margin-left: 0;
    }
  }
  &:last-of-type > {
    .countdown, span {
      margin-right: 0;
    }
  }
  span {
    font-weight:   300;
    font-size:     20px;
    line-height:   50px;
    color:         #222;
    width:         141px;
    height:        50px;
    border-radius: 5px;
    margin:        0 0 3px 26px;
    display:       inline-block;
  }
}

/*Flip style*/

.tcvpb_countdown.flip_style {
  span {
    font-weight:    700;
    font-size:      17px;
    line-height:    50px;
    color:          #3b4550;
    margin:         0;
    text-transform: uppercase;
    display:        block;
    position:       absolute;
    bottom:         -51px;
    left:           0;
    width:          100%;
    text-align:     center;
  }
  .time {
    border-radius:       5px;
    display:             inline-block;
    text-align:          center;
    position:            relative;
    height:              105px;
    width:               75px;
    margin:              5px 5px 40px 5px;
    -webkit-perspective: 500px;
    -moz-perspective:    500px;
    -ms-perspective:     500px;
    -o-perspective:      500px;
    perspective:         500px;
  }
  .count {
    background:              #3b4550;
    color:                   #f8f8f8;
    font-weight:             700;
    display:                 block;
    font-size:               42px;
    line-height:             105px;
    overflow:                hidden;
    position:                absolute;
    text-align:              center;
    text-shadow:             0 0 10px rgba(0, 0, 0, 0.8);
    top:                     0;
    width:                   100%;
    -webkit-transform:       translateZ(0);
    -moz-transform:          translateZ(0);
    -ms-transform:           translateZ(0);
    -o-transform:            translateZ(0);
    transform:               translateZ(0);
    -webkit-transform-style: flat;
    -moz-transform-style:    flat;
    -ms-transform-style:     flat;
    -o-transform-style:      flat;
    transform-style:         flat;
    &.top {
      border-top:                 1px solid rgb(255, 255, 255);
      border-top:                 1px solid rgba(255, 255, 255, 0.2);
      border-bottom:              1px solid rgb(255, 255, 255);
      border-bottom:              1px solid rgba(255, 255, 255, 0.1);
      border-radius:              5px 5px 0 0;
      height:                     50%;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius:  8px;
      -webkit-transform-origin:   50% 100%;
      -moz-transform-origin:      50% 100%;
      -ms-transform-origin:       50% 100%;
      -o-transform-origin:        50% 100%;
      transform-origin:           50% 100%;
    }
    &.bottom {
      background-image:         linear-gradient(rgba(255, 255, 255, 0.1), transparent);
      background-image:         -webkit-linear-gradient(rgba(255, 255, 255, 0.1), transparent);
      background-image:         -moz-linear-gradient(rgba(255, 255, 255, 0.1), transparent);
      background-image:         -ms-linear-gradient(rgba(255, 255, 255, 0.1), transparent);
      background-image:         -o-linear-gradient(rgba(255, 255, 255, 0.1), transparent);
      border-radius:            0 0 5px 5px;
      line-height:              0;
      height:                   50%;
      top:                      50%;
      border-top-left-radius:   8px;
      border-top-right-radius:  8px;
      -webkit-transform-origin: 50% 0;
      -moz-transform-origin:    50% 0;
      -ms-transform-origin:     50% 0;
      -o-transform-origin:      50% 0;
      transform-origin:         50% 0;
    }
  }
  .label {
    font-size:  normal;
    margin-top: 5px;
    display:    block;
    position:   absolute;
    top:        95px;
    width:      100%;
  }
  .count {
    &.curr.top {
      -webkit-transform: rotateX(0deg);
      -moz-transform:    rotateX(0deg);
      -ms-transform:     rotateX(0deg);
      -o-transform:      rotateX(0deg);
      transform:         rotateX(0deg);
      z-index:           3;
    }
    &.next.bottom {
      -webkit-transform: rotateX(90deg);
      -moz-transform:    rotateX(90deg);
      -ms-transform:     rotateX(90deg);
      -o-transform:      rotateX(90deg);
      transform:         rotateX(90deg);
      z-index:           2;
    }
  }
  .flip .count {
    &.curr.top {
      -webkit-transition: all 250ms ease-in-out;
      -moz-transition:    all 250ms ease-in-out;
      -ms-transition:     all 250ms ease-in-out;
      -o-transition:      all 250ms ease-in-out;
      transition:         all 250ms ease-in-out;
      -webkit-transform:  rotateX(-90deg);
      -moz-transform:     rotateX(-90deg);
      -ms-transform:      rotateX(-90deg);
      -o-transform:       rotateX(-90deg);
      transform:          rotateX(-90deg);
    }
    &.next.bottom {
      -webkit-transition: all 250ms ease-in-out 250ms;
      -moz-transition:    all 250ms ease-in-out 250ms;
      -ms-transition:     all 250ms ease-in-out 250ms;
      -o-transition:      all 250ms ease-in-out 250ms;
      transition:         all 250ms ease-in-out 250ms;
      -webkit-transform:  rotateX(0deg);
      -moz-transform:     rotateX(0deg);
      -ms-transform:      rotateX(0deg);
      -o-transform:       rotateX(0deg);
      transform:          rotateX(0deg);
    }
  }
}

/* Animation start */

/* Animation end */

.hide_expired {
  display: none !important;
}

/******* 12.39. Image Carousel ********/

.caroufredsel_wrapper {
  margin: 0 !important;
}

.tcvpb-carousel {
  padding:    0;
  list-style: none;
  display:    block;
  ul {
    margin-left: 7px !important;
  }
  li {
    float:         left;
    display:       block;
    border-radius: 0;
    overflow:      hidden;
    text-align:    center;
    margin-right:  15px;
  }
}

.carousel_navigation {
  text-align: center;
  position:   relative;
  display:    block;
  a {
    color:      #9fa4af;
    width:      36px;
    height:     36px;
    text-align: center;
    &:hover {
      color: #9fa4af;
    }
    i {
      line-height:        34px;
      font-size:          32px;
      color:              #18191a;
      background:         $color-white;
      border-radius:      50%;
      border:             1px solid #e1e3e6;
      -webkit-transition: all 180ms ease-in-out;
      -moz-transition:    all 180ms ease-in-out;
      -ms-transition:     all 180ms ease-in-out;
      -o-transition:      all 180ms ease-in-out;
      transition:         all 180ms ease-in-out;
    }
  }
  &.transparent a i {
    background: transparent;
    color:      $color-white;
  }
  a:hover i {
    color:  $color-ci-1;
    border: 1px solid $color-ci-1;
  }
}

.carousel_next {
  position: absolute;
  top:      -79px;
}

.carousel_prev {
  position: absolute;
  top:      -79px;
  left:     -6px;
}

.carousel_next {
  right: -6px;
}

.transparent {
  .carousel_next {
    position: absolute;
    top:      -108px;
  }
  .carousel_prev {
    position: absolute;
    top:      -108px;
    left:     -45px;
  }
  .carousel_next {
    right: -45px;
  }
}

.section_body_fullwidth {
  .carousel_prev {
    left: 0;
  }
  .carousel_next {
    right: 0;
  }
}

.image_carousel_position {
  background-color:    rgb(255, 255, 255);
  background-image:    url(../images/background-image1.jpg);
  background-repeat:   no-repeat;
  background-size:     cover;
  background-position: center top;
}

/* transparent carousel*/

#transparent_carousel {
  .carousel_navigation {
    text-align: center;
    position:   relative;
    margin:     20px auto 35px;
    display:    block;
    a {
      color:      #9fa4af;
      width:      36px;
      height:     36px;
      text-align: center;
      &.carousel_prev i {
        position: absolute;
        left:     55px;
        bottom:   25px;
      }
      &.carousel_next i {
        position: absolute;
        right:    56px;
        bottom:   25px;
      }
      &.carousel_prev i:hover, &.carousel_next i:hover {
        color: $color-ci-1;
      }
      i {
        line-height:        34px;
        font-size:          32px;
        color:              $color-white;
        -webkit-transition: all 180ms ease-in-out;
        -moz-transition:    all 180ms ease-in-out;
        -ms-transition:     all 180ms ease-in-out;
        -o-transition:      all 180ms ease-in-out;
        transition:         all 180ms ease-in-out;
        background:         transparent;
        border-radius:      50%;
        border:             1px solid #e1e3e6;
      }
    }
  }
  li {
    border:         1px solid #696b6e;
    padding-top:    70px;
    padding-bottom: 68px;
    margin:         0 11px 0 10px;
  }
}

.image_carousel_postition {
  background-image:    url(../images/background-image1.jpg);
  background-repeat:   no-repeat;
  background-size:     cover;
  background-position: center top;
}

/*-------- 12.40. Element: Image Gallery --------*/

.tcvpb-image-gallery {
  .tcvpb-gallery {
    display:        inline-block;
    vertical-align: top;
    margin-bottom:  4px;
    a {
      position:      relative;
      display:       block;
      border-bottom: 0;
    }
  }
  a {
    i {
      position:           absolute;
      width:              32px;
      height:             32px;
      opacity:            0;
      left:               50%;
      margin-left:        -16px;
      top:                50%;
      margin-top:         -16px;
      color:              $color-white;
      -webkit-transition: all 300ms ease-in;
      -moz-transition:    all 300ms ease-in;
      -o-transition:      all 300ms ease-in;
      -ms-transition:     all 300ms ease-in;
      transition:         all 300ms ease-in;
    }
    &:hover i {
      opacity: 1;
    }
  }
  canvas {
    position:           absolute;
    left:               0;
    top:                0;
    max-width:          100%;
    width:              100% !important;
    height:             100% !important;
    opacity:            0;
    -webkit-transition: all 300ms ease-in;
    -moz-transition:    all 300ms ease-in;
    -o-transition:      all 300ms ease-in;
    -ms-transition:     all 300ms ease-in;
    transition:         all 300ms ease-in;
  }
  a:hover canvas {
    opacity: 1;
    &.grey-effect {
      background: rgb(0, 0, 0);
      background: rgba(0, 0, 0, 0.5);
    }
  }
  &.two_columns .tcvpb-gallery {
    width: 49.65%;
  }
  &.three_columns .tcvpb-gallery {
    width: 33%;
  }
  &.four_columns .tcvpb-gallery {
    width: 24.4%;
  }
  &.five_columns .tcvpb-gallery {
    width: 19.56%;
  }
  &.six_columns .tcvpb-gallery {
    width: 16.25%;
  }
  &.seven_columns .tcvpb-gallery {
    width: 13.91%;
  }
  &.eight_columns .tcvpb-gallery {
    width: 12.11%;
  }
  &.nine_columns .tcvpb-gallery {
    width: 10.72%;
  }
}

/******* 12.41. Element: Image Switch ********/

.tcvpb-image-combo {
  display:  inline-block;
  position: relative;
  a {
    display: inline-block;
    outline: 0;
  }
  .tcvpb_image_first_image {
    z-index:            1;
    opacity:            1;
    -webkit-transition: opacity 800ms ease-in-out;
    -o-transition:      opacity 800ms ease-in-out;
    transition:         opacity 800ms ease-in-out;
  }
  .tcvpb_image_second_image {
    z-index:            2;
    position:           absolute;
    top:                0;
    left:               0;
    opacity:            0;
    -webkit-transition: opacity 800ms ease-in-out;
    -o-transition:      opacity 800ms ease-in-out;
    transition:         opacity 800ms ease-in-out;
  }
  &:hover .tcvpb_image_second_image {
    opacity:            1;
    -webkit-transition: opacity 800ms ease-in-out;
    -o-transition:      opacity 800ms ease-in-out;
    transition:         opacity 800ms ease-in-out;
  }
}

/*-------- 12.42. Element: Timeline --------*/

.tcvpb-tabs {
  .tcvpb-tabs-timeline {
    list-style-type: none;
  }
  &.tcvpb-tabs-timeline ul li {
    list-style:  none;
    font-family: Lato, sans-serif;
    float:       left;
    white-space: nowrap;
    background:  transparent;
    margin:      0;
    padding:     0;
    border:      0;
    font-size:   17px;
    line-height: 18px;
    position:    relative;
    font-weight: 400;
    color:       #18191a;
    a {
      padding: 15px 0;
    }
    &:hover a {
      color:      $color-ci-1;
      background: transparent;
    }
  }
  .nav-tabs li.active:hover a {
    color: $color-ci-1;
  }
  &.tcvpb-tabs-timeline {
    .nav-tabs {
      li.active {
        border-top: none;
        background: none;
        &:hover a {
          background: none;
        }
        &:before {
          content:       "";
          display:       block;
          width:         12px;
          height:        12px;
          border-radius: 50%;
          border:        1px solid $color-ci-1;
          background:    $color-white;
          position:      absolute;
          bottom:        -9px;
          left:          50%;
          margin-left:   -6px;
          z-index:       1;
        }
        &:after {
          content:           " ";
          width:             15px;
          height:            15px;
          border-top:        1px solid #e9eaec;
          border-right:      1px solid #e9eaec;
          -webkit-transform: rotate(-45deg);
          -moz-transform:    rotate(-45deg);
          -ms-transform:     rotate(-45deg);
          -o-transform:      rotate(-45deg);
          transform:         rotate(-45deg);
          background:        $color-white;
          position:          absolute;
          bottom:            -43px;
          left:              50%;
          margin-left:       -7px;
          z-index:           1;
        }
      }
      margin-bottom: 37px !important;
      width:         100%;
      position:      relative;
    }
    ul {
      li:last-child {
        border-right: 0;
      }
      &.nav-tabs:after {
        content:            " ";
        width:              100%;
        height:             4px;
        background:         #e4e7e8;
        -moz-box-shadow:    inset 0 0 3px #b9bfca;
        -webkit-box-shadow: inset 0 0 3px #b9bfca;
        box-shadow:         inset 0 0 3px #b9bfca;
        position:           absolute;
        bottom:             -4px;
        left:               0;
      }
    }
    .tab-content {
      border:        1px solid #e9eaec;
      border-radius: 5px;
      position:      relative;
      text-align:    center;
      p {
        text-align: left;
      }
    }
    .timeline_title {
      font-size:   14px;
      color:       #3b4550;
      font-weight: 700;
    }
    .ui-tabs-anchor {
      margin-bottom: 8px;
    }
    .tab-pane {
      background: $color-white;
    }
  }
}

.tab_par_1 {
  width: 100%;
}

.tab_par_2 {
  width: 50%;
}

.tab_par_3 {
  width: 33.33%;
}

.tab_par_4 {
  width: 25%;
}

.tab_par_5 {
  width: 20%;
}

.tab_par_6 {
  width: 16.66%;
}

.tab_par_7 {
  width: 14.28%;
}

.tab_par_8 {
  width: 12.5%;
}

.tab_par_9 {
  width: 11.11%;
}

.tab_par_10 {
  width: 10%;
}

.tab_par_11 {
  width: 9.09%;
}

.tab_par_12 {
  width: 8.33%;
}

.tab_par_13 {
  width: 7.69%;
}

.tab_par_14 {
  width: 7.14%;
}

.tab_par_15 {
  width: 6.66%;
}

.tcvpb-tabs.tcvpb-tabs-timeline.tcvpb-tabs-fullwidthtabs {
  ul li {
    border:        1px solid #e6ebed;
    border-bottom: none;
  }
  .nav-tabs li {
    &.active {
      &:after {
        display: none;
      }
      border-top: 1px solid #e6ebed;
    }
    &:last-child {
      margin-bottom: 5px;
    }
  }
  ul.nav-tabs:after {
    display: none;
  }
  .nav-tabs {
    margin-bottom: 0 !important;
  }
}

/********** Shop Tabs **********************/

.tcvpb-tabs-shop {
  text-align: center;
  h3 {
    display:    inline-block;
    text-align: center;
  }
}

.tcvpb-tabs.tcvpb-tabs-shop .nav-tabs li {
  a {
    text-align:      center;
    clear:           both;
    box-sizing:      border-box;
    display:         inline-block;
    padding:         12px 18px;
    width:           100%;
    height:          100%;
    cursor:          pointer;
    outline:         none;
    color:           #8d9299;
    text-decoration: none;
    font-style:      normal;
    font-size:       19px;
    font-weight:     700;
    line-height:     22px;
  }
  display:      inline-block;
  text-align:   center;
  float:        none;
  margin-right: 20px;
  &:last-of-type {
    margin-right: 0;
    border:       0;
  }
  a {
    &:hover {
      color:  #18191a;
      cursor: pointer;
    }
    padding:  13px 0;
    position: relative;
  }
  &.active a:after {
    content:    "";
    display:    block;
    width:      100%;
    height:     2px;
    background: #18191a;
    position:   absolute;
    bottom:     3px;
    left:       0;
  }
}

/*-------- 12.43. Element: Modal --------*/

.tcvpb-modal {
  display: block;
  .tcvpb-modal-button {
    cursor:  pointer;
    display: inline-block;
  }
}

.tcvpb-modal-content-wrapper {
  position:           fixed;
  top:                0;
  left:               0;
  width:              100%;
  height:             100%;
  background:         rgb(34, 34, 34);
  background:         rgba(34, 34, 34, 0.85);
  z-index:            99999;
  opacity:            0;
  visibility:         hidden;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition:    all 300ms ease-in-out;
  -ms-transition:     all 300ms ease-in-out;
  -o-transition:      all 300ms ease-in-out;
  transition:         all 300ms ease-in-out;
  &.opened {
    opacity:            1;
    visibility:         visible;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition:    all 300ms ease-in-out;
    -ms-transition:     all 300ms ease-in-out;
    -o-transition:      all 300ms ease-in-out;
    transition:         all 300ms ease-in-out;
  }
  .tcvpb-modal-content {
    position:   fixed;
    height:     auto;
    right:      25%;
    left:       25%;
    background: $color-white;
    padding:    20px;
    text-align: center;
    iframe {
      max-width: 100%;
    }
    .tcvpb-modal-close {
      width:       52px;
      height:      52px;
      line-height: 50px;
      position:    absolute;
      top:         0;
      right:       -60px;
      cursor:      pointer;
      background:  #222;
      text-align:  center;
      &:after {
        font-family:        'stroke7';
        content:            "\e680";
        line-height:        50px;
        color:              #fefefe;
        font-size:          16px;
        -webkit-transition: all 250ms ease-in-out;
        -moz-transition:    all 250ms ease-in-out;
        -ms-transition:     all 250ms ease-in-out;
        -o-transition:      all 250ms ease-in-out;
        transition:         all 250ms ease-in-out;
      }
    }
  }
}
