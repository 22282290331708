@import "../includes/colors";
@import "../includes/variables";

/*-----------------------------------------------------------------*/
/* 2. Global Styles
/*-----------------------------------------------------------------*/

body {
  font-family:    $font-family--main;
  font-weight:    400;
  font-size:      14px;
  line-height:    23px;
  color:          $color-steel-grey;
  text-align:     left;
  margin:         0;
  padding:        0;
  text-rendering: optimizeLegibility;
}

.preloader {
  visibility: hidden;
}

.boxed_body_wrapper {
  width:      1290px;
  margin:     0 auto;
  background: $color-white;
}

#jpreOverlay {
  background-color: #000;
}

#jpreSlide {
  display: none;
}

#jpreLoader {
  width:  50%;
  height: 25px;
}

#jpreBar {
  border-top: 1px solid $color-ci-1;
}

#jprePercentage {
  color:      #aaa;
  text-align: center;
  font-size:  40px;
}

a {
  color:              #a4adba;
  text-decoration:    none;
  -webkit-transition: all 180ms ease-in;
  -moz-transition:    all 180ms ease-in;
  -ms-transition:     all 180ms ease-in;
  -o-transition:      all 180ms ease-in;
  transition:         all 180ms ease-in;
  &:hover {
    color: $color-ci-1;
  }
}

p a {
  color: $color-steel-grey-darker;
}

:focus {
  outline-color: $color-ci-1;
}

p {
  margin-bottom: 10px;
  margin-top:    0;
}

.bigger_text p {
  color:       #5e6266;
  font-size:   20px;
  font-weight: 300;
  line-height: 30px;
  text-align:  left;
}

.with_lines p {
  font-family: Georgia;
  color:       #909294;
  font-size:   21px;
  font-style:  italic;
  line-height: 23px;
  position:    relative;
  &:before, &:after {
    content:    "";
    width:      83px;
    height:     1px;
    background: #5e6266;
    position:   absolute;
    top:        10px;
  }
  &:before {
    left:        50%;
    margin-left: -230px;
  }
  &:after {
    right:        50%;
    margin-right: -230px;
  }
}

hr {
  background-color: #e1e9e9;
  display:          block;
  height:           1px;
  border:           0 #e1e9e9;
  padding:          0;
  margin-bottom:    11px;
}

section {
  padding: 100px 0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight:    700;
  color:          #18191a;
  letter-spacing: 0;
  position:       relative;
  margin-bottom:  15px;
}

h1 {
  font-size:   31px;
  line-height: 39px;
}

h2 {
  font-size:   28px;
  line-height: 38px;
}

h3 {
  font-size:   25px;
  line-height: 38px;
}

h4 {
  font-size:   22px;
  line-height: 35px;
}

h5 {
  font-size:   19px;
  line-height: 30px;
}

h6 {
  font-size:   16px;
  line-height: 28px;
}

.tcvpb-inversed_text {
  h1, h2, h3, h4, h5, h6 {
    color: $color-white;
  }
}

.title_with_after {
  position:      relative;
  margin-bottom: 30px;
  &:after {
    display:    block;
    content:    '';
    width:      84px;
    height:     1px;
    background: $color-ci-1;
    position:   relative;
    bottom:     -10px;
  }
}

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap:   break-word;
}

input, select, textarea {
  border:      1px solid $color-grey-d9;
  color:       #939fa6;
  font-size:   14px;
  line-height: 26px;
  background:  #f7f9fa;
  font-family: Lato;
  box-sizing:  border-box;
}

input, select {
  padding:       11px 0 11px 20px;
  margin-bottom: 12px;
}

textarea {
  padding:       8px 0 8px 20px;
  margin-bottom: 36px;
}

button,
input[type="submit"] {
  padding:            10px 20px;
  display:            inline-block;
  cursor:             pointer;
  border:             1px solid $color-ci-1;
  background:         $color-ci-1;
  color:              $color-white;
  margin-bottom:      20px;
  font-weight:        300;
  outline:            none;
  -webkit-transition: all 180ms ease-in;
  -moz-transition:    all 180ms ease-in;
  -ms-transition:     all 180ms ease-in;
  -o-transition:      all 180ms ease-in;
  transition:         all 180ms ease-in;

  &:hover {
    background:         #e8e8e8;
    outline:            none;
    -webkit-transition: all 180ms ease-in;
    -moz-transition:    all 180ms ease-in;
    -ms-transition:     all 180ms ease-in;
    -o-transition:      all 180ms ease-in;
    transition:         all 180ms ease-in;
  }
}

.placeholder {
  color: #929292;
}

strong, .strong {
  font-weight: bold;
}

input:focus {
  outline-width:  1px !important;
  outline-offset: 0 !important;
  outline-color:  $color-ci-1 !important;
  outline-style:  solid;
}

blockquote {
  padding:       20px 15px 11px 20px;
  margin:        30px 0 0 0;
  border-radius: 0;
  color:         #9098a3;
  font-size:     15px;
  font-style:    italic;
  line-height:   24px;
  text-align:    left;
  position:      relative;
  &:before {
    content:        '\2018 \2018';
    font-family:    Garamond, Baskerville, "Baskerville Old Face", "Hoefler Text", "Times New Roman", serif;
    position:       absolute;
    color:          #9098a3;
    top:            10px;
    left:           -15px;
    font-size:      75px;
    letter-spacing: -5px;
  }
  &:after {
    content:        '\2019 \2019';
    font-family:    Garamond, Baskerville, "Baskerville Old Face", "Hoefler Text", "Times New Roman", serif;
    position:       absolute;
    color:          #9098a3;
    bottom:         10px;
    right:          0;
    font-size:      75px;
    letter-spacing: -5px;
  }
}

.underlined {
  display:         inline-block;
  text-decoration: underline;
}

strong, .strong {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.highlighted, .search-highlight {
  background: $color-ci-1;
  display:    inline-block;
  padding:    0 3px;
}

.alignleft {
  float:        left;
  margin-right: 30px;
}

img {
  &.alignleft {
    float: left;
  }
  &.alignright {
    float: right;
  }
}

.alignright {
  float:       right;
  margin-left: 30px;
}

.color_highlight {
  color: $color-ci-1 !important;
}

.grey_color {
  color: #999;
}

.darkest_grey_color {
  color: #666;
}

.light_black_color {
  color: #333;
}

.black_color {
  color: #000;
}

.color_dark_grey {
  color: #3b4550;
}

.white_color {
  color: $color-white !important;
}

.white_background {
  background-color: rgb(255, 255, 255);
}

.font_size_small {
  font-size: small;
}

.font_size_10 {
  font-size: 10pt;
}

.font_size_13 {
  font-size:   13.3333px !important;
  line-height: 20px !important;
}

.font_size_24 {
  font-size: 24pt;
}

.bold {
  font-weight: 700;
}

.bigtext {
  color:       $color-white;
  font-size:   48px;
  font-weight: 700;
  line-height: 48px;
  text-align:  center;
}

.list_right {
  float:        right;
  margin-right: 100px;
}

.list_left {
  float: left;
}

.last_child {
  margin-right: 0 !important;
}

.left_aligned {
  text-align: left;
}

.right_aligned {
  text-align: right;
}

.center_aligned,
.aligncenter,
.tcvpb-centered {
  text-align: center;
}

/*Padding classes*/

.no_padding {
  padding: 0 !important;
}

.no_padding_top_and_bottom {
  padding-top:    0px !important;
  padding-bottom: 0px !important;
}

.no_padding_top_and_bottom66 {
  padding-top:    66px !important;
  padding-bottom: 66px !important;
}

/*Padding Bottom*/

.no_padding_bottom {
  padding-bottom: 0 !important;
}

.padding_bottom_10 {
  padding-bottom: 10px !important;
}

.padding_bottom_23 {
  padding-bottom: 23px !important;
}

.padding_bottom_25 {
  padding-bottom: 25px !important;
}

.padding_bottom_30 {
  padding-bottom: 30px !important;
}

.padding_bottom_38 {
  padding-bottom: 38px !important;
}

.padding_bottom_45 {
  padding-bottom: 45px !important;
}

.padding_bottom_48 {
  padding-bottom: 48px !important;
}

.padding_bottom_64 {
  padding-bottom: 64px !important;
}

.padding_bottom_65 {
  padding-bottom: 65px !important;
}

.padding_bottom_70 {
  padding-bottom: 70px !important;
}

.padding_bottom_155 {
  padding-bottom: 155px !important;
}

/* Padding Top*/

.no_padding_top {
  padding-top: 0 !important;
}

.padding_top_25 {
  padding-top: 25px !important;
}

.padding_top_29 {
  padding-top: 29px !important;
}

.padding_top_45 {
  padding-top: 45px !important;
}

.padding_top_70 {
  padding-top: 70px !important;
}

.padding_top_90 {
  padding-top: 90px !important;
}

.padding_top_157 {
  padding-top: 157px !important;
}

.no_margin_bottom {
  margin-bottom: 0 !important;
}

.margin_right_20 {
  margin-right: 20px;
}

.blue_box_background {
  background-color: $color-ci-1-light;
}

.icon_color {
  color: $color-ci-1 !important;
}

.transparent_background {
  background: transparent;
}

.light_grey_background_color {
  background-color: rgb(245, 245, 245);
}

.parallax_font_36 {
  color:     $color-white;
  font-size: 36pt;
}

.parallax_font_14 {
  font-size: 14pt;
}

/* Spacer classes*/

.spacer_7 {
  height:  7px;
  display: block;
}

.spacer_10 {
  height:  10px;
  display: block;
}

.spacer_15 {
  height:  15px;
  display: block;
}

.spacer_19 {
  height:  19px;
  display: block;
}

.spacer_20 {
  height:  20px;
  display: block;
}

.spacer_21 {
  height:  21px;
  display: block;
}

.spacer_26 {
  height:  26px;
  display: block;
}

.spacer_28 {
  height:  28px;
  display: block;
}

.spacer_30 {
  height:  30px;
  display: block;
}

.spacer_35 {
  height:  35px;
  display: block;
}

.spacer_40 {
  height:  40px;
  display: block;
}

.spacer_45 {
  height:  45px;
  display: block;
}

.spacer_48 {
  height:  48px;
  display: block;
}

.spacer_54 {
  height:  54px;
  display: block;
}

.spacer_60 {
  height:  60px;
  display: block;
}

.spacer_70 {
  height:  70px;
  display: block;
}

.spacer_90 {
  height:  90px;
  display: block;
}

.spacer_110 {
  height:  110px;
  display: block;
}

.spacer_113 {
  height:  113px;
  display: block;
}

.spacer_114 {
  height:  114px;
  display: block;
}

.no_border {
  border: 0px;
}

.border_solid {
  border: 1px solid rgb(232, 234, 237);
}

.border_solid_color {
  border: 1px solid rgb(233, 234, 236);
}

.no_border_radius {
  border-radius: 0px;
}

.border_radius_5 {
  border-radius: 5px;
}

.no_border_bottom {
  border-bottom: 0px;
}

.section_bg {
  background-color: #f2f4f5;
}

.section_bg_grey {
  background-color: #f7f9fa;
}

.background_color_light_grey {
  background-color: #f8f9fb;
}

.background_color_lighter_grey {
  background-color: #f5f6f8;
}

.background_color_normal_grey {
  background-color: #f6f7f9;
}

.style_centered_title_about_us {
  padding-top:      64px !important;
  padding-bottom:   43px !important;
  background-color: #f2f4f5;
}

.about_us_stats_excerpt_section_style {
  border-bottom:    0px;
  padding-top:      33px !important;
  padding-bottom:   27px !important;
  background-color: #f2f4f5;
}

.number_color {
  color: #18191a;
}

.video_position {
  position:   absolute !important;
  top:        0;
  left:       0;
  min-height: 100%;
  min-width:  100%;
  z-index:    0 !important;
}

.video_image {
  padding-bottom:   20px !important;
  background-image: url(images/milano.jpg);
}

.section_content_2_columns {
  margin-top:       0px;
  margin-bottom:    0px;
  border-top:       1px;
  border-bottom:    1px;
  padding-top:      0px !important;
  padding-bottom:   0px !important;
  background-color: rgb(247, 249, 250);
  border-style:     solid;
  border-color:     #e1e3e6;
}

.section_content_2_columns_first {
  border-right:   1px;
  padding-top:    93px;
  padding-bottom: 100px;
  border-style:   solid;
  border-color:   #e1e3e6;
}

.section_content_2_columns_second {
  padding-top:    93px;
  padding-bottom: 100px;
}

sub {
  vertical-align: sub;
  font-size:      smaller;
}

sup {
  vertical-align: super;
  font-size:      smaller;
}

table {
  line-height: 2;
  margin:      0 0 40px;
  width:       100%;
}

th {
  font-weight: 700;
}

em {
  font-style: italic;
}

cite {
  display: inline-block;
}

dt {
  font-weight: 700;
}

dl {
  margin-bottom: 20px;
}

dd {
  padding-left: 50px;
}

tt, code, kbd, samp {
  font-family: monospace;
}

.pattern_overlayed, .pattern_overlayed_dotted, .pattern_overlayed_dotted_2, .pattern_overlayed_lined {
  position: relative;
  color:    $color-white !important;
}

.pattern_overlayed:before, .pattern_overlayed_dotted:before, .pattern_overlayed_dotted_2:before, .pattern_overlayed_lined:before, .pattern_overlayed_lined_h:before {
  content:  "";
  display:  block;
  width:    100%;
  height:   100%;
  position: absolute;
  top:      0;
  left:     0;
}

.tcvpb-video-bg {
  &.pattern_overlayed:before, &.pattern_overlayed_dotted:before, &.pattern_overlayed_dotted_2:before, &.pattern_overlayed_lined:before, &.pattern_overlayed_lined_h:before {
    z-index: 1;
  }
}

.pattern_overlayed .tcvpb_container, .pattern_overlayed_dotted .tcvpb_container, .pattern_overlayed_dotted_2 .tcvpb_container, .pattern_overlayed_lined .tcvpb_container {
  position: relative;
  z-index:  1;
}

.pattern_overlayed:before {
  background: rgb(5, 11, 15);
  background: rgba(5, 11, 15, 0);
}

.pattern_overlayed_dotted:before {
  background:       rgb(0, 0, 0);
  background:       rgba(0, 0, 0, 0.65);
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTMzRERGREYwOEZCMTFFNEI3OTRGMzQ4NzY5NkE0NzQiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTMzRERGREUwOEZCMTFFNEI3OTRGMzQ4NzY5NkE0NzQiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NDMxRTgyODcwOENBMTFFNEExQzNCNTExOEU2Mjk2NUUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NDMxRTgyODgwOENBMTFFNEExQzNCNTExOEU2Mjk2NUUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4jXJ0mAAAAF0lEQVR42mJmYGD4D8TnmEEEEDMABBgAGmcCoh16VmUAAAAASUVORK5CYII=');
}

.pattern_overlayed_dotted_2:before {
  opacity:          0.15;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAB5JREFUeNpiZGBg+A8EjECagQnK+A/mMEAAWAYgwADf9ggBN+tpvAAAAABJRU5ErkJggg==');
}

.pattern_overlayed_lined:before {
  background:       rgb(0, 0, 0);
  background:       rgba(0, 0, 0, 0.3);
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAABCAYAAAD0In+KAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NzI0MDNENzY2QTUyMTFFM0FDMUJDNjY2MkRCNjlDMzMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NzI0MDNENzc2QTUyMTFFM0FDMUJDNjY2MkRCNjlDMzMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo3MjQwM0Q3NDZBNTIxMUUzQUMxQkM2NjYyREI2OUMzMyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo3MjQwM0Q3NTZBNTIxMUUzQUMxQkM2NjYyREI2OUMzMyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PrPT1y0AAAASSURBVHjaYmZgYPgPxHoAAQYABU0BMT/72gwAAAAASUVORK5CYII=');
}

.pattern_overlayed_lined_h:before {
  background:       rgb(0, 0, 0);
  background:       rgba(0, 0, 0, 0.3);
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAYAAACZgbYnAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAAYSURBVHjaYmZgYPjPzMDAoAcAAAD//wMABl8BNN6Jh64AAAAASUVORK5CYII=');
}

.color_overlayed {
  position: relative;
  &:before {
    content:    "";
    background: #161411;
    opacity:    0.8;
    display:    block;
    width:      100%;
    height:     100%;
    position:   absolute;
    top:        0;
    left:       0;
    z-index:    1;
  }
  .tcvpb_container {
    z-index:  1;
    position: relative;
  }
}

.sticky {
  background:    #eee;
  margin-bottom: 40px;
  padding:       40px 20px 0 20px;
}

.wp-caption, .wp-caption-text, .gallery-caption, .bypostauthor, .alignright, .alignleft, .aligncenter {
}

::selection {
  color:      $color-white;
  background: $color-ci-1;
}

.image_overlap {
  position:    absolute;
  top:         -112px;
  left:        50%;
  margin-left: -470px;
}

/*-------- 2.1. WordPress Core --------*/

.alignnone {
  margin: 5px 20px 20px 0;
}

a img {
  &.alignright {
    float:  right;
    margin: 5px 0 20px 20px;
  }
  &.alignnone {
    margin: 5px 20px 20px 0;
  }
  &.alignleft {
    float:  left;
    margin: 5px 20px 20px 0;
  }
  &.aligncenter {
    display:      block;
    margin-left:  auto;
    margin-right: auto;
  }
}

.wp-caption {
  &.aligncenter, &.tcvpb-centered {
    width: 100% !important;
  }
  background: $color-white;
  border:     1px solid #f0f0f0;
  max-width:  100%;
  padding:    5px 3px 10px;
  text-align: center;
  &.alignnone {
    margin:        0;
    margin-bottom: 15px;
  }
  &.alignleft {
    margin: 5px 20px 0 0;
  }
  &.alignright {
    margin: 5px 0 0 20px;
  }
  img {
    border:    0 none;
    height:    auto;
    margin:    0;
    max-width: 98.5%;
    padding:   0;
    width:     auto;
  }
  p.wp-caption-text {
    font-size:   11px;
    line-height: 17px;
    margin:      0;
    padding:     10px 4px 0px;
  }
}

.gallery-caption {
  padding: 0;
}

.screen-reader-text {
  clip:     rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height:   1px;
  width:    1px;
  overflow: hidden;
  &:focus {
    background-color: #f1f1f1;
    border-radius:    3px;
    box-shadow:       0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip:             auto !important;
    color:            #21759b;
    display:          block;
    font-size:        14px;
    font-size:        0.875rem;
    font-weight:      bold;
    height:           auto;
    left:             5px;
    line-height:      normal;
    padding:          15px 23px 14px;
    text-decoration:  none;
    top:              5px;
    width:            auto;
    z-index:          100000;
  }
}

/*-------- 2.2. Inner Columns --------*/

.container, .tcvpb_container {
  width:  1170px;
  margin: 0 auto;
}

.content .tcvpb_container, .content_with_right_sidebar .tcvpb_container {
  width: 100%;
}

.content_with_left_sidebar {
  .tcvpb_container {
    width: 100%;
  }
  float: right !important;
}

.row {
  width: 100%;
  &:before {
    display:     table;
    line-height: 0;
    content:     "";
  }
  &:after {
    display:     table;
    line-height: 0;
    content:     "";
    clear:       both;
  }
  > [class*="span"] {
    display:            block;
    float:              left;
    width:              100%;
    min-height:         20px;
    margin-left:        2.564102564102564%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing:    border-box;
    box-sizing:         border-box;
    &:first-child {
      margin-left: 0;
    }
  }
  .span12 {
    width: 100%;
  }
  .span11 {
    width: 91.45299145299145%;
  }
  .span10 {
    width: 82.90598290598291%;
  }
  .span9 {
    width: 74.35897435897436%;
  }
  .span8 {
    width: 65.81196581196581%;
  }
  .span7 {
    width: 57.26495726495726%;
  }
  .span6 {
    width: 48.71794871794872%;
  }
  .span5 {
    width: 40.17094017094017%;
  }
  .span4 {
    width: 31.62393162393162%;
  }
  .span3 {
    width: 23.07692307692308%;
  }
  .span2 {
    width: 14.52991452991453%;
  }
  .span1 {
    width: 5.982905982905983%;
  }
}

/*-------- 2.3. Clearfix --------*/

.clearfix {
  &:before {
    content:    '\0020';
    display:    block;
    overflow:   hidden;
    visibility: hidden;
    width:      0;
    height:     0;
  }
  &:after {
    content:    '\0020';
    display:    block;
    overflow:   hidden;
    visibility: hidden;
    width:      0;
    height:     0;
    clear:      both;
  }
}

.icon {
  width:  48px;
  height: 48px;
  &:before {
    width:   48px;
    height:  48px;
    content: "";
    display: block;
  }

  &--ci-1 {
    fill: $color-ci-1;
  }
}

.btn {
  transition: all 0.25s !important;
  &--cta {
    padding:          10px 20px;
    background-color: $color-ci-2;
    color:            $color-white;
    &:hover {
      background-color: $color-ci-2-darker;
      color:            $color-white;
    }
  }
  &--shadow {
    border: 1px solid $color-beige-1;
    box-shadow: 0px 0px 30px 10px rgba(0,0,0,0.3);
  }
}