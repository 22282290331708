@import "../includes/colors";

/*-----------------------------------------------------------------*/
/* 11. Other
/*-----------------------------------------------------------------*/
/*-------- 11.1. AB Testimonials --------*/

.ABt_testimonials_slide .testimonial_small .source a {
  font-weight: 400;
  &:hover {
    color: $color-ci-1;
  }
}

.ABt_testimonials_wrapper .caroufredsel_wrapper {
  margin: 0 !important;
  width:  auto !important;
}

.ABt_testimonials_slide {
  padding:    0;
  list-style: none;
  display:    block;
  width:      auto !important;
  li {
    display:    block;
    margin:     0;
    padding:    0;
    width:      auto !important;
    background: $color-white;
  }
}

.ABt_testimonials_wrapper .ABt_navigation {
  text-align: right;
  position:   relative;
  font-size:  20px;
  a {
    text-decoration: none;
  }
}

.ABt_testimonials_slide {
  .testimonial_big {
    padding-left:  40px;
    border-left:   1px solid $color-grey-d9;
    margin-bottom: 70px;
    display:       block;
    p {
      font-family:   Ubuntu;
      font-weight:   300;
      font-style:    italic;
      font-size:     24px;
      line-height:   27px;
      color:         #656560;
      margin-bottom: 27px;
    }
    .source {
      color: #929292;
      a {
        font-weight: 700;
      }
    }
  }
  .testimonial_small {
    display:       block;
    margin-bottom: 70px;
    p {
      font-style:    italic;
      border-bottom: 5px solid #eee;
      margin-bottom: 26px;
      &:after {
        display:       block;
        content:       "";
        width:         0;
        height:        0;
        border-left:   16px solid #eee;
        border-bottom: 22px solid $color-white;
        position:      relative;
        top:           27px;
        left:          20px;
      }
    }
    .source {
      color: #929292;
      a {
        font-weight: 700;
      }
    }
    &:after {
      display: block;
      content: "";
      clear:   both;
    }
    img {
      width:        50px;
      float:        left;
      margin-right: 20px;
      margin-top:   5px;
    }
  }
}

.ABt_form_wrapper {
  input {
    &[type="text"], &[type="file"] {
      margin-bottom: 20px;
    }
  }
  textarea {
    margin-bottom: 20px;
  }
  input[type="file"] {
    width: 100%;
  }
  .ABt_client, .ABt_client_url, .ABt_company, .ABt_company_url {
    width: 48%;
  }
  .ABt_client_url, .ABt_company_url {
    float: right;
  }
  .ABt_text {
    width:  100%;
    height: 90px;
  }
  .ABt_field_error {
    background: #ffe9e9;
  }
  .ABt_success_message {
    display: none;
  }
  .ABt_form {
    .placeholder, label {
      color: #aaa;
    }
  }
}

.ABt_pagination {
  text-align: center;
}

/*-------- 11.1.1. AB Testimonials - Picture Middle --------*/

.ABt_testimonials_wrapper.picture_middle, .ABt_testimonials_wrapper_static.picture_middle {
  margin: 10px 0 0;
}

.ABt_testimonials_wrapper.picture_middle .ABt_pagination, .ABt_testimonials_wrapper_static.picture_middle .ABt_pagination {
  margin-top: 0;
}

.ABt_testimonials_wrapper.picture_middle .ABt_pagination a, .ABt_testimonials_wrapper_static.picture_middle .ABt_pagination a {
  margin-right:  13px;
  width:         14px;
  height:        14px;
  border-radius: 50%;
  border:        1px solid #bbbfc6;
  display:       inline-block;
  background:    $color-white;
}

.ABt_testimonials_wrapper.picture_middle .ABt_pagination a:last-child, .ABt_testimonials_wrapper_static.picture_middle .ABt_pagination a:last-child {
  margin-right: 0;
}

.ABt_testimonials_wrapper.picture_middle .ABt_pagination a.selected, .ABt_testimonials_wrapper_static.picture_middle .ABt_pagination a.selected {
  width:         14px;
  height:        14px;
  border-radius: 50%;
  background:    $color-ci-1;
  border:        0;
  display:       inline-block;
}

.ABt_testimonials_wrapper.picture_middle .ABt_pagination a:hover, .ABt_testimonials_wrapper_static.picture_middle .ABt_pagination a:hover {
  width:         14px;
  height:        14px;
  border-radius: 50%;
  background:    $color-ci-1;
  border:        0;
}

.ABt_testimonials_wrapper.picture_middle .ABt_pagination a span, .ABt_testimonials_wrapper_static.picture_middle .ABt_pagination a span {
  display: none;
}

.ABt_testimonials_wrapper.picture_middle .testimonial_small, .ABt_testimonials_wrapper_static.picture_middle .testimonial_small {
  padding:  0 100px 25px 100px;
  position: relative;
}

.ABt_testimonials_wrapper.picture_middle .testimonial_small .source, .ABt_testimonials_wrapper_static.picture_middle .testimonial_small .source {
  position:    absolute;
  width:       200px;
  bottom:      -90px;
  left:        50%;
  margin-left: -100px;
  text-align:  center;
}

.ABt_testimonials_wrapper.picture_middle .ABt_author, .ABt_testimonials_wrapper_static.picture_middle .ABt_author {
  color:       #181819;
  font-size:   16px;
  font-weight: 400;
}

.ABt_testimonials_wrapper.picture_middle .ABt_company, .ABt_testimonials_wrapper_static.picture_middle .ABt_company {
  color:       #9098a3;
  font-size:   13px;
  font-style:  italic;
  font-weight: 400;
}

.ABt_testimonials_wrapper.picture_middle .testimonial_small p, .ABt_testimonials_wrapper_static.picture_middle .testimonial_small p {
  color:         #5e6266;
  font-size:     22px;
  font-weight:   300;
  line-height:   26px;
  text-align:    center;
  font-style:    normal;
  border-bottom: 0;
  margin-bottom: 60px;
}

.ABt_testimonials_wrapper.picture_middle .testimonial_small p:after, .ABt_testimonials_wrapper_static.picture_middle .testimonial_small p:after, .ABt_testimonials_wrapper.picture_middle .testimonial_small p:before, .ABt_testimonials_wrapper_static.picture_middle .testimonial_small p:before {
  font-family: 'Georgia', serif;
  color:       $color-ci-1;
  font-size:   43px;
  font-weight: 400;
  display:     inline-block;
  position:    relative;
}

.ABt_testimonials_wrapper.picture_middle .testimonial_small p:before, .ABt_testimonials_wrapper_static.picture_middle .testimonial_small p:before {
  content: '“';
  top:     12px;
  left:    -5px;
}

.ABt_testimonials_wrapper.picture_middle .testimonial_small p:after, .ABt_testimonials_wrapper_static.picture_middle .testimonial_small p:after {
  content:       '”';
  border-left:   none;
  border-bottom: none;
  top:           15px;
  right:         -8px;
  left:          auto;
}

.ABt_testimonials_wrapper.picture_middle .ABt_testimonials_slide li, .ABt_testimonials_wrapper_static.picture_middle .ABt_testimonials_slide li {
  border-radius:  5px;
  padding-bottom: 54px;
  background:     transparent;
}

.ABt_testimonials_wrapper.picture_middle .testimonial_small img, .ABt_testimonials_wrapper_static.picture_middle .testimonial_small img {
  width:         90px;
  height:        90px;
  border-radius: 50%;
  position:      absolute;
  bottom:        -40px;
  left:          50%;
  margin-left:   -45px;
}

/*-------- 11.1.2. AB Testimonials - Big --------*/

.ABt_testimonials_wrapper.testimonials_big, .ABt_testimonials_wrapper_static.testimonials_big {
  margin-top: 13px;
  padding:    0 80px;
  position:   relative;
}

.ABt_testimonials_wrapper.testimonials_big .ABt_testimonials_slide li, .ABt_testimonials_wrapper_static.testimonials_big .ABt_testimonials_slide li {
  background: transparent;
}

.ABt_testimonials_wrapper.testimonials_big .ABt_testimonials_slide .testimonials_item .testimonial_big, .ABt_testimonials_wrapper_static.testimonials_big .ABt_testimonials_slide .testimonials_item .testimonial_big {
  border-left:    0;
  padding-bottom: 29px;
  padding-left:   30px;
  padding-right:  30px;
  position:       relative;
}

.ABt_testimonials_wrapper.testimonials_big .ABt_testimonials_slide .testimonials_item .testimonial_big p, .ABt_testimonials_wrapper_static.testimonials_big .ABt_testimonials_slide .testimonials_item .testimonial_big p {
  color:         #3b4550;
  font-size:     24px;
  font-style:    normal;
  margin-bottom: 17px;
  text-align:    center;
}

.ABt_testimonials_wrapper.testimonials_big .ABt_testimonials_slide .testimonials_item .testimonial_big .source, .ABt_testimonials_wrapper_static.testimonials_big .ABt_testimonials_slide .testimonials_item .testimonial_big .source {
  text-align:  center;
  width:       350px;
  position:    absolute;
  bottom:      8px;
  left:        50%;
  margin-left: -175px;
  z-index:     1;
}

.ABt_testimonials_wrapper.testimonials_big .ABt_testimonials_slide .testimonials_item .testimonial_big .source .ABt_author, .ABt_testimonials_wrapper_static.testimonials_big .ABt_testimonials_slide .testimonials_item .testimonial_big .source .ABt_author {
  color: #3b4550;
}

.ABt_testimonials_wrapper.testimonials_big .ABt_pagination, .ABt_testimonials_wrapper_static.testimonials_big .ABt_pagination {
  display:    inline-block;
  text-align: center;
  margin-top: 21px;
  width:      100%;
}

.ABt_testimonials_wrapper.testimonials_big .ABt_pagination a, .ABt_testimonials_wrapper_static.testimonials_big .ABt_pagination a {
  margin-right:  13px;
  width:         16px;
  height:        16px;
  border-radius: 50%;
  border:        2px solid #bbbfc6;
  display:       inline-block;
}

.ABt_testimonials_wrapper.testimonials_big .ABt_pagination a.selected, .ABt_testimonials_wrapper_static.testimonials_big .ABt_pagination a.selected {
  width:         16px;
  height:        16px;
  border-radius: 50%;
  background:    $color-ci-1;
  border:        0;
  display:       inline-block;
}

.ABt_testimonials_wrapper.testimonials_big .ABt_pagination a:hover, .ABt_testimonials_wrapper_static.testimonials_big .ABt_pagination a:hover {
  background: $color-ci-1;
  border:     2px solid $color-ci-1;
}

.ABt_testimonials_wrapper.testimonials_big .ABt_pagination a span, .ABt_testimonials_wrapper_static.testimonials_big .ABt_pagination a span {
  display: none;
}

/*-------- 11.1.3. AB Testimonials - Picture Bottom --------*/

.ABt_testimonials_wrapper.picture_bottom, .ABt_testimonials_wrapper_static.picture_bottom {
  position: relative;
}

.ABt_testimonials_wrapper.picture_bottom .ABt_pagination, .ABt_testimonials_wrapper_static.picture_bottom .ABt_pagination {
  margin-right: -13px;
  margin-top:   -51px;
  z-index:      1;
  position:     absolute;
  right:        0;
  bottom:       30px;
}

.ABt_testimonials_wrapper.picture_bottom .ABt_pagination a, .ABt_testimonials_wrapper_static.picture_bottom .ABt_pagination a {
  margin-right:  13px;
  width:         16px;
  height:        16px;
  border-radius: 50%;
  border:        2px solid #bbbfc6;
  display:       inline-block;
}

.ABt_testimonials_wrapper.picture_bottom .ABt_pagination a.selected, .ABt_testimonials_wrapper_static.picture_bottom .ABt_pagination a.selected {
  width:         16px;
  height:        16px;
  border-radius: 50%;
  background:    $color-ci-1;
  border:        0;
  display:       inline-block;
}

.ABt_testimonials_wrapper.picture_bottom .ABt_pagination a:hover, .ABt_testimonials_wrapper_static.picture_bottom .ABt_pagination a:hover {
  width:         16px;
  height:        16px;
  border-radius: 50%;
  background:    $color-ci-1;
  border:        0;
}

.ABt_testimonials_wrapper.picture_bottom .ABt_pagination a span, .ABt_testimonials_wrapper_static.picture_bottom .ABt_pagination a span {
  display: none;
}

.ABt_testimonials_wrapper.picture_bottom .testimonial_small, .ABt_testimonials_wrapper_static.picture_bottom .testimonial_small {
  border:        1px solid #e9eaec;
  border-radius: 5px;
  padding:       25px 40px 25px 25px;
  position:      relative;
  background:    $color-white;
  margin-top:    16px;
}

.ABt_testimonials_wrapper.picture_bottom .testimonial_small:after, .ABt_testimonials_wrapper_static.picture_bottom .testimonial_small:after {
  content:           "";
  width:             15px;
  height:            15px;
  border-top:        1px solid #e9eaec;
  border-right:      1px solid #e9eaec;
  -ms-transform:     rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform:         rotate(135deg);
  background:        $color-white;
  position:          absolute;
  bottom:            -9px;
  left:              27px;
}

.ABt_testimonials_wrapper.picture_bottom .testimonial_small .source, .ABt_testimonials_wrapper_static.picture_bottom .testimonial_small .source {
  position:   absolute;
  width:      200px;
  bottom:     -92px;
  left:       92px;
  text-align: left;
}

.ABt_testimonials_wrapper.picture_bottom .ABt_author, .ABt_testimonials_wrapper_static.picture_bottom .ABt_author {
  color: #3b4550;
}

.ABt_testimonials_wrapper.picture_bottom .testimonial_small p, .ABt_testimonials_wrapper_static.picture_bottom .testimonial_small p {
  border-bottom: 0;
  font-size:     16px;
  color:         #848994;
  line-height:   26px;
  margin-bottom: 0;
}

.ABt_testimonials_wrapper.picture_bottom .testimonial_small p:after, .ABt_testimonials_wrapper_static.picture_bottom .testimonial_small p:after {
  display: none;
}

.ABt_testimonials_wrapper.picture_bottom .ABt_testimonials_slide li, .ABt_testimonials_wrapper_static.picture_bottom .ABt_testimonials_slide li {
  border-radius:  5px;
  padding-bottom: 60px;
  background:     transparent;
}

.ABt_testimonials_wrapper.picture_bottom .testimonial_small img, .ABt_testimonials_wrapper_static.picture_bottom .testimonial_small img {
  width:         70px;
  height:        70px;
  border-radius: 50%;
  position:      absolute;
  bottom:        -106px;
  left:          0;
}

/*-------- 11.1.4. AB Testimonials - Picture Top --------*/

.ABt_testimonials_wrapper.picture_top, .ABt_testimonials_wrapper_static.picture_top {
  position: relative;
}

.ABt_testimonials_wrapper.picture_top .ABt_pagination, .ABt_testimonials_wrapper_static.picture_top .ABt_pagination {
  margin-left: -43px;
  z-index:     1;
  position:    absolute;
  left:        50%;
  top:         175px;
}

.ABt_testimonials_wrapper.picture_top .ABt_pagination a, .ABt_testimonials_wrapper_static.picture_top .ABt_pagination a {
  margin-right:  13px;
  width:         16px;
  height:        16px;
  border-radius: 50%;
  border:        2px solid #bbbfc6;
  display:       inline-block;
}

.ABt_testimonials_wrapper.picture_top .ABt_pagination a:last-child, .ABt_testimonials_wrapper_static.picture_top .ABt_pagination a:last-child {
  margin-right: 0;
}

.ABt_testimonials_wrapper.picture_top .ABt_pagination a.selected, .ABt_testimonials_wrapper_static.picture_top .ABt_pagination a.selected {
  width:         16px;
  height:        16px;
  border-radius: 50%;
  background:    $color-ci-1;
  border:        0;
  display:       inline-block;
}

.ABt_testimonials_wrapper.picture_top .ABt_pagination a:hover, .ABt_testimonials_wrapper_static.picture_top .ABt_pagination a:hover {
  width:         16px;
  height:        16px;
  border-radius: 50%;
  background:    $color-ci-1;
  border:        0;
}

.ABt_testimonials_wrapper.picture_top .ABt_pagination a span, .ABt_testimonials_wrapper_static.picture_top .ABt_pagination a span {
  display: none;
}

.ABt_testimonials_wrapper.picture_top .testimonial_small, .ABt_testimonials_wrapper_static.picture_top .testimonial_small {
  position:      relative;
  margin-top:    16px;
  padding:       33px 50px 91px 118px;
  border:        1px solid #e9eaec;
  border-radius: 5px;
  background:    $color-white;
}

.ABt_testimonials_wrapper.picture_top .testimonial_small .source, .ABt_testimonials_wrapper_static.picture_top .testimonial_small .source {
  position:   relative;
  display:    inline;
  text-align: left;
}

.ABt_testimonials_wrapper.picture_top .ABt_author, .ABt_testimonials_wrapper_static.picture_top .ABt_author {
  color:       #202223;
  font-size:   16px;
  font-weight: 400;
}

.ABt_testimonials_wrapper.picture_top .ABt_company, .ABt_testimonials_wrapper_static.picture_top .ABt_company {
  font-family: 'Georgia', serif;
  color:       #a4adba;
  font-size:   14px;
  font-style:  italic;
  font-weight: 300;
}

.ABt_testimonials_wrapper.picture_top .testimonial_small p, .ABt_testimonials_wrapper_static.picture_top .testimonial_small p {
  color:         #9098a3;
  text-align:    left;
  margin-bottom: 10px;
  border-bottom: 0;
}

.ABt_testimonials_wrapper.picture_top .testimonial_small p:after, .ABt_testimonials_wrapper_static.picture_top .testimonial_small p:after {
  display: none;
}

.ABt_testimonials_wrapper.picture_top .ABt_testimonials_slide li, .ABt_testimonials_wrapper_static.picture_top .ABt_testimonials_slide li {
  border-radius:  5px;
  padding-bottom: 44px;
  background:     transparent;
}

.ABt_testimonials_wrapper.picture_top .testimonial_small img, .ABt_testimonials_wrapper_static.picture_top .testimonial_small img {
  position:      absolute;
  top:           30px;
  left:          25px;
  width:         70px;
  height:        70px;
  border-radius: 50%;
}

/*-------- 11.1.5. AB Testimonials - Single Testimonial --------*/

.ABt_testimonials_wrapper.single_testimonial .testimonial_small, .ABt_testimonials_wrapper_static.single_testimonial .testimonial_small {
  margin:        0 !important;
  padding:       0 0 0 93px !important;
  border:        none !important;
  border-radius: 0 !important;
}

.ABt_testimonials_wrapper.single_testimonial .testimonial_small img, .ABt_testimonials_wrapper_static.single_testimonial .testimonial_small img {
  border-radius: 0 !important;
  top:           0;
  left:          0;
}

.ABt_testimonials_wrapper.single_testimonial .testimonial_small p, .ABt_testimonials_wrapper_static.single_testimonial .testimonial_small p {
  position:   relative;
  top:        28px;
  font-style: normal;
}

.ABt_testimonials_wrapper.single_testimonial .testimonial_small .source, .ABt_testimonials_wrapper_static.single_testimonial .testimonial_small .source {
  position: absolute;
  top:      0;
}

.single_testimonial .ABt_testimonials_slide .testimonial_small .source a {
  position:     relative;
  padding-left: 20px;
  &:before {
    position:   absolute;
    content:    "";
    height:     1px;
    width:      10px;
    background: #a4adba;
    top:        8px;
    left:       4px;
  }
}

/*-------- 11.2. AB Tweet Scroller --------*/

.ab-tweet-scroller {
  position: relative;
  border:   0;
  &:before {
    content:     "\e621";
    /*twitter*/
    font-family: 'core_icomoon';
    background:  none;
    width:       auto;
    height:      auto;
    font-style:  normal;
    font-size:   40px;
    color:       $color-white;
    position:    absolute;
    top:         10px;
    left:        50%;
    margin-left: -19px;
  }
}

.ab-tweet-item {
  position:       relative;
  padding-top:    20px;
  padding-bottom: 50px;
}

.ab-tweet-username {
  color:       $color-ci-1;
  font-weight: 400;
  font-size:   16px;
  position:    absolute;
  left:        50%;
  top:         53px;
  margin-left: -61px;
  &:hover {
    color: $color-white;
  }
}

.ab-tweet-text {
  margin-top: 70px;
  font-size:  20px;
  color:      $color-white;
  a {
    font-size:   20px;
    color:       $color-ci-1;
    font-weight: 400;
    &:hover {
      color: $color-white;
    }
  }
}

.ab-tweet-date {
  color:       $color-ci-1;
  font-weight: 400;
  font-size:   14px;
  position:    absolute;
  bottom:      12px;
  left:        50%;
  margin-left: -61px;
  z-index:     10;
  &:hover {
    color: $color-white;
  }
}

.ab-tweet-navigation {
  padding: 0 100px;
}

.ab-tweet-prev, .ab-tweet-next {
  color: transparent;
}

a {
  &.ab-tweet-prev:hover, &.ab-tweet-next:hover {
    color: transparent;
  }
}

.ab-tweet-prev {
  &:after {
    content:            "\e623";
    /*chevron left*/
    font-family:        'core_icomoon';
    color:              $color-white;
    font-size:          20px;
    position:           absolute;
    bottom:             50%;
    left:               0;
    right:              auto;
    margin-bottom:      -24px;
    -webkit-transition: all 180ms ease-in-out;
    -moz-transition:    all 180ms ease-in-out;
    -ms-transition:     all 180ms ease-in-out;
    -o-transition:      all 180ms ease-in-out;
    transition:         all 180ms ease-in-out;
  }
  &:hover:after {
    color:              $color-ci-1;
    -webkit-transition: all 180ms ease-in-out;
    -moz-transition:    all 180ms ease-in-out;
    -ms-transition:     all 180ms ease-in-out;
    -o-transition:      all 180ms ease-in-out;
    transition:         all 180ms ease-in-out;
  }
}

.ab-tweet-next {
  &:hover:after {
    color:              $color-ci-1;
    -webkit-transition: all 180ms ease-in-out;
    -moz-transition:    all 180ms ease-in-out;
    -ms-transition:     all 180ms ease-in-out;
    -o-transition:      all 180ms ease-in-out;
    transition:         all 180ms ease-in-out;
  }
  &:after {
    content:       "\e61b";
    /*chevron right*/
    font-family:   'core_icomoon';
    color:         $color-white;
    font-size:     20px;
    position:      absolute;
    bottom:        50%;
    right:         0;
    left:          auto;
    margin-bottom: -24px;
  }
}

/*-------- 11.3. AB Simple Subscribe --------*/

.ABss_form_wrapper {
  p {
    margin: 0;
  }
  input {
    width:       136px;
    background:  $color-white;
    border:      1px solid #e6e8eb;
    padding:     7px 0 9px 11px;
    color:       #9098a3;
    font-size:   14px;
    font-style:  italic;
    line-height: 22px;
    text-align:  left;
    display:     inline-block;
    &[type="submit"] {
      margin:             0;
      padding:            9px 17px 11px;
      width:              100%;
      cursor:             pointer;
      -webkit-transition: background 180ms ease-in, color 180ms ease-in, border-color 180ms ease-in;
      -moz-transition:    background 180ms ease-in, color 180ms ease-in, border-color 180ms ease-in;
      -ms-transition:     background 180ms ease-in, color 180ms ease-in, border-color 180ms ease-in;
      -o-transition:      background 180ms ease-in, color 180ms ease-in, border-color 180ms ease-in;
      transition:         background 180ms ease-in, color 180ms ease-in, border-color 180ms ease-in;
      white-space:        nowrap;
      text-decoration:    none;
      display:            inline-block;
      background:         $color-ci-1;
      text-transform:     uppercase;
      color:              $color-white;
      font-size:          14px;
      font-weight:        400;
      line-height:        20px;
      font-style:         normal;
      &:hover {
        background: $color-white;
        color:      $color-ci-1;
      }
    }
  }
}

.ABss_field_error {
  background: #ffe9e9;
}

.ABss_inline_form p {
  display:      inline-block;
  margin-right: 10px;
}

.ABss_form label {
  display: block;
}

.ABss_success_message {
  display: none;
}

.ABss_form .placeholder {
  color: #aaa;
}

/*-------- 11.4. Real3D Flipbook --------*/

.flipbook-bg-light {
  background:         transparent !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow:    none;
  -ms-box-shadow:     none;
  -o-box-shadow:      none;
  box-shadow:         none !important;
  border-top:         1px solid rgb(197, 197, 197);
  border-top:         1px solid rgba(197, 197, 197, 0.9);
}

.flipbook-currentPage {
  position:     static !important;
  width:        30px !important;
  margin-right: 4px;
}

.flipbook-currentPageHolder {
  position: absolute;
  top:      6px;
  left:     10px;
  opacity:  .7;
  z-index:  -1;
}

.flipbook-totalPages {
  background:   none;
  box-shadow:   none;
  position:     static !important;
  padding-left: 0 !important;
  border:       none !important;
  width:        3em !important;
  font-size:    1.2em !important;
  font-family:  'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  text-align:   left !important;
  line-height:  30px !important;
  z-index:      9999;
  height:       35px;
  border-top:   0 solid $color-white !important;
}