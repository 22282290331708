@import "../includes/colors";

/*-----------------------------------------------------------------*/
/* 9. Widgets
/*-----------------------------------------------------------------*/

aside {
  &.sidebar_right {
    padding-left: 10px;
  }
  &.sidebar_left {
    padding-right: 10px;
  }
}

.widget input {
  margin-bottom: 0;
}

aside {
  .widget {
    margin-bottom: 54px;
    select {
      width:   100%;
      display: block;
    }
  }
  select {
    margin-top: 15px;
  }
}

.sidebar-widget-heading {
  margin-bottom: 20px;
}

h3.sidebar-widget-heading, .sidebar-widget-heading h3, #comments-title, #reply-title {
  position:       relative;
  margin-bottom:  16px;
  display:        block;
  text-align:     left;
  padding-bottom: 13px;
  letter-spacing: 0.5px;
  color:          #18191a;
  font-size:      16px;
  font-weight:    700;
  line-height:    22px;
  text-transform: uppercase;
  border-bottom:  1px solid #e1e3e6;
}

.sidebar-widget-heading h3:after, #comments-title:after, #reply-title:after {
  display:     none;
  content:     "";
  width:       60px;
  height:      2px;
  background:  #56b9ff;
  position:    absolute;
  bottom:      -2px;
  left:        0;
  margin-left: 0;
}

aside .widget a {
  color:          #9098a3;
  font-size:      12px;
  font-weight:    400;
  text-transform: uppercase;
  font-style:     normal;
  &:hover {
    color: $color-ci-1;
  }
}

.widget ul {
  list-style:  none;
  margin-left: 0;
  &.children {
    padding-left: 20px;
    padding-top:  10px;
  }
}

.textwidget {
  font-size:     13px;
  line-height:   22px;
  color:         #9fa4af;
  padding-right: 0;
}

footer .textwidget {
  color:       #83888f;
  font-size:   14px;
  font-weight: 400;
  line-height: 22px;
  text-align:  left;
  p {
    margin-bottom: 22px;
  }
}

/*-------- 9.1. Search --------*/

.widget_search {
  form {
    position: relative;
  }
  a {
    cursor:             pointer;
    transition:         all 180ms ease-in;
    -webkit-transition: all 180ms ease-in;
    -moz-transition:    all 180ms ease-in;
    -ms-transition:     all 180ms ease-in;
    -o-transition:      all 180ms ease-in;
  }
  i {
    color:     #b2b2b2;
    position:  absolute;
    top:       14px;
    right:     15px;
    font-size: 16px;
  }
  a:hover i {
    color:              $color-ci-1;
    transition:         all 180ms ease-in;
    -webkit-transition: all 180ms ease-in;
    -moz-transition:    all 180ms ease-in;
    -ms-transition:     all 180ms ease-in;
    -o-transition:      all 180ms ease-in;
  }
  input {
    padding:       11px 20px;
    font-size:     14px;
    background:    $color-white;
    border:        1px solid #e1e3e6;
    border-radius: 0;
    color:         #9098a3;
    font-style:    italic;
    line-height:   20px;
    font-weight:   400;
    width:         100%;
    box-sizing:    border-box;
  }
}

.logo_search_bar input {
  color:       #99a1ae;
  font-size:   14px;
  font-style:  italic;
  line-height: 20px;
  text-align:  left;
}

.widget_search input:focus {
  outline-width:  1px !important;
  outline-offset: 0 !important;
  outline-color:  $color-ci-1 !important;
  outline-style:  solid;
}

/*-------- 9.2. Categories --------*/

.widget ul {
  list-style:     none;
  margin-left:    0;
  padding-left:   0;
  text-transform: lowercase;
  li {
    position:    relative;
    color:       #9098a3;
    font-size:   12px;
    font-weight: 400;
  }
  &.children li {
    word-break: break-all;
  }
  li a {
    padding-left: 3px;
  }
}

.widget_pages li, .widget_recent_entries li, .widget_archive li, .widget_nav_menu li, .widget_meta li, .widget_categories li {
  position:      relative;
  padding:       0;
  line-height:   36px;
  border-bottom: 1px solid #e1e3e6;
}

.widget_pages ul.children, .widget_recent_entries ul.children, .widget_archive ul.children, .widget_nav_menu ul.sub-menu, .widget_meta ul.children, .widget_categories ul.children {
  position:   relative;
  padding:    0;
  border-top: 1px solid #e1e3e6;
}

.widget_pages li:last-child, .widget_archive li:last-child, .widget_nav_menu li:last-child, .widget_meta li:last-child, .widget_categories li:last-child {
  border-bottom: none;
}

.widget_pages ul.children li a, .widget_recent_entries ul.children li a, .widget_archive ul.children li a, .widget_nav_menu ul.sub-menu li a, .widget_meta ul.children li a, .widget_categories ul.children li a {
  margin-left: 15px;
}

.widget_pages ul.children li li a, .widget_recent_entries ul.children li li a, .widget_archive ul.children li li a, .widget_nav_menu ul.sub-menu li li a, .widget_meta ul.children li li a, .widget_categories ul.children li li a {
  margin-left: 25px;
}

.widget_pages ul.children li li li a, .widget_recent_entries ul.children li li li a, .widget_archive ul.children li li li a, .widget_nav_menu ul.sub-menu li li li a, .widget_meta ul.children li li li a, .widget_categories ul.children li li li a {
  margin-left: 35px;
}

.widget_pages ul.children li li li li a, .widget_recent_entries ul.children li li li li a, .widget_archive ul.children li li li li a, .widget_nav_menu ul.sub-menu li li li li a, .widget_meta ul.children li li li li a, .widget_categories ul.children li li li li a {
  margin-left: 45px;
}

.widget_pages li:after, .widget_recent_entries li:after, .widget_archive li:after, .widget_nav_menu li:after, .widget_meta li:after, .widget_categories li:after {
  display:     block;
  font-family: 'core_icomoon';
  font-size:   16px;
  position:    absolute;
  right:       0;
  top:         0;
  content:     "\e637";
               /*chevron-right*/
  color:       #8a8f99;
}

.widget_nav_menu li {
  &.menu-item-has-children {
    padding-bottom: 0;
  }
  ul {
    margin-bottom: 0;
    li {
      padding-left: 0;
    }
  }
}

.widget_rss {
  li {
    margin-bottom: 20px;
    padding-left:  0 !important;
  }
  .rsswidget {
    color:        #505558;
    font-size:    18px;
    padding-left: 0;
  }
  .rss-date {
    display:       block;
    color:         #222;
    font-weight:   400;
    font-size:     12px;
    margin-bottom: 5px;
  }
  .rssSummary {
    font-size: 14px;
  }
  cite {
    font-weight: 700;
    color:       #222;
  }
}

.widget_recent_comments .recentcomments {
  margin-bottom:  10px;
  border-bottom:  1px solid #eee;
  padding-bottom: 10px;
  font-weight:    700;
  color:          #222;
  padding-left:   0;
  .url {
    font-weight:  700;
    color:        #222;
    padding-left: 0;
    display:      inline-block;
    &:hover {
      color: $color-ci-1;
    }
  }
  a {
    color:       #939fa6;
    font-weight: 300;
  }
  > a {
    display:      block !important;
    padding-left: 0;
  }
}

/*-------- 9.3. Tag Cloud --------*/

.tagcloud {
  padding-top:   5px;
  margin-bottom: 20px;
  a {
    margin:             0 4px 4px 0;
    padding:            10px 9px 7px 9px;
    float:              left;
    color:              #9098a3 !important;
    border-radius:      0;
    font-size:          13px !important;
    line-height:        22px !important;
    background:         $color-white;
    border:             1px solid #e1e3e6;
    text-transform:     lowercase !important;
    font-weight:        400;
    transition:         all 180ms ease-in;
    -webkit-transition: all 180ms ease-in;
    -moz-transition:    all 180ms ease-in;
    -ms-transition:     all 180ms ease-in;
    -o-transition:      all 180ms ease-in;
    &:hover {
      background: $color-ci-1;
      color:      $color-white !important;
    }
  }
  &:after {
    display: block;
    clear:   both;
    content: "";
    height:  0;
  }
}

#ABdev_main_footer .tagcloud a:hover {
  color: $color-white;
}

/*-------- 9.4. Flickr stream --------*/

.flickr_stream {
  a {
    float:    left;
    margin:   0 9px 9px 0;
    position: relative;
    &:hover:after {
      content:    "";
      position:   absolute;
      top:        0;
      left:       0;
      width:      100%;
      height:     100%;
      background: rgba(0, 0, 0, 0.2);
      img {

      }
    }
    img {
      -webkit-filter: grayscale(80%);
      filter: grayscale(80%);
    }

    @media (max-width: 1190px) {
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    @media (min-width: 1191px) {
      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    img {
      width:  82px;
      height: 82px;
    }
  }
}

aside.sidebar {
  .flickr_stream a {
    margin: 0 5px 5px 0;
  }
  .flickr_stream a {
    &:nth-child(3n+0),
    &:nth-child(4n+0) {
      margin-right: 5px;
    }
  }

  .flickr_stream img {
    width:  80px;
    height: 80px;
  }
}

/*-------- 9.5. Recent Posts Extended Widget --------*/

.rpwe-block {
  li {
    position:      relative;
    min-height:    50px;
    margin-bottom: 40px;
  }
  a {
    padding-left: 0 !important;
    display:      inline-block;
  }
}

.rpwe-title {
  margin-bottom: 0 !important;
  line-height:   18px;
  font-weight:   400;
  margin-top:    0;
}

aside .widget .rpwe-title a {
  transition:         all 180ms ease-in;
  -webkit-transition: all 180ms ease-in;
  -moz-transition:    all 180ms ease-in;
  -ms-transition:     all 180ms ease-in;
  -o-transition:      all 180ms ease-in;
  color:              #83888f !important;
  font-size:          14px;
  font-weight:        400;
  line-height:        22px;
  text-align:         left;
}

.rpwe-title a:hover {
  color:              $color-ci-1 !important;
  transition:         all 180ms ease-in;
  -webkit-transition: all 180ms ease-in;
  -moz-transition:    all 180ms ease-in;
  -ms-transition:     all 180ms ease-in;
  -o-transition:      all 180ms ease-in;
}

.rpwe-time {
  display: inline-block;
}

.rpwe-block h3 {
  font-size:      13px !important;
  letter-spacing: 0;
}

/*-------- 9.6. Footer Widgets --------*/

#ABdev_main_footer {
  .widget {
    margin-bottom: 30px;
  }
  .footer-widget-heading {
    margin-bottom: 35px;
  }
  .rpwe-title {
    font-size:   14px;
    font-weight: 400;
    line-height: 22px;
    text-align:  left;
    a {
      color: #83888f;
    }
  }
  .rpwe-time {
    color:       #505357;
    font-size:   14px;
    font-style:  italic;
    line-height: 20px;
    text-align:  left;
  }
  .widget ul li {
    padding-left:   0;
    padding-bottom: 9px;
    margin-bottom:  10px;
    border-bottom:  1px solid #32373b;
    overflow: hidden;
    &:last-child {
      border-bottom: none;
    }
  }
  .screen-reader-text {
    color: #83888f;
  }
  .widget select {
    margin-top: 15px;
    width:      100%;
  }
  .widget_pages li:after,
  .widget_archive li:after,
  .widget_nav_menu li:after,
  .widget_meta li:after,
  .widget_categories li:after {
    display:     block;
    font-family: 'core_icomoon';
    font-size:   16px;
    position:    absolute;
    right:       0;
    top:         14px;
    content:     "\e637";
    color:       #8a8f99;
  }
  .widget_archive ul li:after {
    display: none;
  }
}

/*-------- 9.7. Widget archive --------*/

/*-------- 9.8. Widget Search Results --------*/

.search_results_content_item {
  padding-left: 41px;
  position:     relative;
}

.search_resuls_number {
  font-size:   21px;
  font-weight: 300;
  position:    absolute;
  top:         11px;
  left:        0;
  line-height: 19px;
}

.search_results_content_item h4 {
  margin-bottom: 12px;
  a {
    color: #18191a;
    &:hover {
      color: $color-ci-1;
    }
  }
}

#search_results_sum_title {
  margin-bottom: 50px;
}

.search-results #blog_pagination {
  padding-bottom: 50px;
}

/*-------- 9.9. Widget Recent Entries --------*/

#ABdev_main_footer {
  .widget_recent_entries a {
    display: block;
    color:   #83888f;
  }
  .post-date {
    display: block;
    color:   #505357;
  }
  .widget_recent_entries li:after {
    display: none;
  }
}

/*-------- 9.10. Sidebar Widgets --------*/

.rpwe-block li {
  margin-bottom: 16px;
  padding-left:  84px;
}

.rpwe-clearfix > a {
  position: absolute;
  top:      0;
  left:     0;
}

.rpwe-time.published {
  color:       #505357;
  font-size:   14px;
  font-style:  italic;
  line-height: 20px;
  text-align:  left;
}

/*-------- 9.11. Contact Info Widget --------*/

.contact_info_widget {
  margin-bottom: 13px;
  color:         #83888f;
  font-size:     14px;
  font-weight:   400;
  line-height:   23px;
  text-align:    left;
  p {
    padding-left:  47px;
    position:      relative;
    margin-bottom: 10px;
  }
  i {
    position:              absolute;
    left:                  0;
    top:                   -3px;
    display:               inline-block;
    background:            transparent;
    width:                 32px;
    height:                32px;
    font-size:             17px;
    line-height:           32px;
    text-align:            center;
    -webkit-border-radius: 50%;
    -moz-border-radius:    50%;
    border-radius:         50%;
  }
}

.contact-info a {
  color:       #83888f;
  font-size:   14px;
  font-weight: 400;
  line-height: 20px;
  text-align:  left;
}

/*-------- 9.12. Twitter Widget --------*/

.sidebar {
  .ab-tweet-scroller {
    position:       relative;
    padding-bottom: 0;
    .caroufredsel_wrapper {
      padding-bottom: 0;
    }
  }
  .ab-tweet-scroller-inner {
    width:        236px;
    padding-left: 15px;
  }
  .ab-tweet-scroller:before {
    display: none;
    content: "";
  }
  .ab-tweet-item {
    text-align:     left;
    padding:        0 10px 50px;
    position:       relative;
    display:        inline-block;
    vertical-align: top;
  }
  .ab-tweet-scroller-inner:before {
    content:     '\e621';
                 /*twitter*/
    font-family: 'core_icomoon';
    background:  none;
    width:       auto;
    height:      auto;
    font-style:  normal;
    font-size:   14px;
    color:       #9fa4af;
    position:    absolute;
    top:         0;
    left:        0;
  }
  .ab-tweet-item {
    .ab-tweet-username {
      font-size: 13px;
      color:     $color-ci-1;
      position:  relative;
      left:      60px;
      top:       0;
      &:hover {
        color: #b2b2b2;
      }
    }
    .ab-tweet-date {
      position:       absolute;
      bottom:         20px;
      left:           72px;
      color:          #a8b2c0;
      font-size:      13px;
      font-style:     italic;
      line-height:    22px;
      text-transform: lowercase;
      &:hover {
        color: #b2b2b2;
      }
    }
    .ab-tweet-text {
      margin-top:  10px;
      color:       #9098a3;
      font-size:   14px;
      font-weight: 400;
      line-height: 23px;
    }
  }
  .ab-tweet-text a {
    color:       $color-ci-1;
    font-size:   14px;
    line-height: 23px;
    &:hover {
      color: #b2b2b2;
    }
  }
  .ab-tweet-navigation {
    display: inline-block;
    padding: 0 24px;
    a {
      font-size:    20px;
      margin-right: 10px;
      padding:      0;
      font-weight:  bold;
      color:        $color-ci-1;
      &:hover {
        color: #b2b2b2;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .ab-tweet-prev:after, .ab-tweet-next:after {
    display: none;
  }
  .tcvpb_follow_us a {
    border:         1px solid #e1e3e6;
    background:     $color-white;
    font-size:      16px;
    line-height:    30px;
    color:          #aaa;
    margin:         0 9px 9px 0;
    text-align:     center;
    padding-bottom: 0;
  }
}

/*-------- 9.13. Social Media Buttons Widget --------*/

/*-------- 9.14. Footer RSS Widget --------*/

#ABdev_main_footer {
  .widget_rss {
    .rsswidget {
      color: $color-white;
      &:hover {
        color: $color-ci-1;
      }
    }
    cite {
      color:       $color-white;
      font-weight: 300;
    }
    .rss-date {
      color: #9098a3;
    }
  }
  .comment-author-link {
    color: #9098a3;
  }
}

/*-------- 9.15. Footer Recent Comments Widget --------*/