.ABss_field_error {
  background: #ffe9e9;
}

.ABss_inline_form p {
  display: inline-block;
  margin-right: 10px;
}

.ABss_form label {
  display: block;
}

.ABss_success_message {
  display: none;
}

.ABss_form .placeholder {
  color: #aaa;
}